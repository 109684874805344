import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { BackButton, PrimeButton } from "../../../component/PrimeButton";
import * as Yup from "yup";
import { useFormik } from "formik";
import { getAxiosWithToken } from "../../../axios/AxiosObj";
import {
  alertResponseMsgError,
  alertResponseMsgSuccess,
} from "../../../function/utilies";
import InputError from "../../../component/InputError";
import CityName from "../../../component/dropdown/CityName";
import StateName from "../../../component/dropdown/StateName";
import AutoInputField from "../../../component/AutoInputField";
import { useSelector } from "react-redux";

let initialValues = {
  startStation: "",
  endStation: "",
  firstName: "",
  middleName: "",
  lastName: "",
  mobile: "",
  email: "",
  quotationDate: "",
  proposedDeliveryDate: "",
  senderName: "",
  senderLocality: "",
  fromState: "",
  fromCity: "",
  fromPincode: "",
  receiverName: "",
  receiverLocality: "",
  toState: "",
  toCity: "",
  toPincode: "",
  noOfParcel: "",
  toPay: "",
  weight: "",
  amount: "",
  addComment: "",
  sTax: "",
  grandTotal: "",
};

const CustomerQuotationForm = () => {
  const {stationDetails}= useSelector((state)=> state.user);
  const navigate = useNavigate();

  const userCreateSchema = Yup.object({
    // startStation: Yup.string().required("Start Station should not be empty"),
    // endStation: Yup.string().required(
    //   "Destination Station should not be empty"
    // ),
    customerName: Yup.string().required("Name should not be empty"),
    mobile: Yup.number().required("Contact should not be empty"),
    email: Yup.string().required("Email should not be empty"),
    quotationDate: Yup.string().required("Booking Date should not be empty"),
    proposedDeliveryDate: Yup.string().required(
      "Delivery Date should not be empty"
    ),
    firstName: Yup.string().required("First Name Date should not be empty"),
    lastName: Yup.string().required("Last Name Date should not be empty"),
    senderName: Yup.string().required("Sender Name should not be empty"),
    senderLocality: Yup.string().required("Locality should not be empty"),
    fromPincode: Yup.number().required("PinCode should not be empty"),
    fromState: Yup.string().required("State should not be empty"),
    fromCity: Yup.string().required("City should not be empty"),
    receiverName: Yup.string().required("Receiver Name should not be empty"),
    receiverLocality: Yup.string().required("Locality should not be empty"),
    toPincode: Yup.number().required("PinCode should not be empty"),
    toState: Yup.string().required("State should not be empty"),
    toCity: Yup.string().required("City should not be empty"),
    // noOfParcel: Yup.number().required("Receipt No.*"),
    // toPay : Yup.number().required("To Pay/PAid*"),
    // weight: Yup.number().required("weight*"),
    // amount: Yup.number().required("Amount*"),
    sTax: Yup.number().required("sTax*"),
    grandTotal: Yup.number().required("Grand Total*"),
  });

  const { values, errors, touched, handleChange, handleSubmit } = useFormik({
    initialValues: initialValues,
    validationSchema: userCreateSchema,

    onSubmit: async (values, action) => {
      let customerIdApi = customerId;
      if (
        customerIdApi === "none" ||
        customerIdApi === "" ||
        customerIdApi == null ||
        !customerId
      ) {
        customerIdApi = null;
      }
      const data = {
        startStation: startStation,
        endStation: endStation,
        customerId: customerIdApi,
        firstName: values.firstName,
        middleName: values.middleName,
        lastName: values.lastName,
        mobile: values.mobile,
        email: values.email,
        locality: values.locality,
        quotationDate: values.quotationDate,
        proposedDeliveryDate: values.proposedDeliveryDate,
        fromCustomerName: values.senderName,
        fromAddress: values.senderLocality,
        fromCity: values.fromCity,
        fromState: values.fromState,
        fromPincode: values.fromPincode,
        toCustomerName: values.receiverName,
        toAddress: values.receiverLocality,
        toState: values.toState,
        toCity: values.toCity,
        toPincode: values.toPincode,
        additionalCmt: values.addComment,
        sTax: values.sTax,
        grandTotal: values.grandTotal,
        productDetails: products,
      };
      // console.log("quotation :", data);
      // return false;

      try {
        const response = await getAxiosWithToken({
          method: "POST",
          url: "quotation/add", // Adjust the API endpoint as needed
          data: data,
        });
        // API response 200 201
        if (response.data.success) {
          alertResponseMsgSuccess(response.data.msg);
          // action.resetForm();
          navigate("/quotation");
        } else {
          alertResponseMsgError(response.data.msg);
        }
      } catch (error) {
        console.error(error);
      }
    },
  });

  // -------------------------get custtomer detail( firstName, middleNale, lastMane)---------------------//

  const [allCustomerRaw, setAllCustomerRaw] = useState([]);
  const userManageData = async () => {
    const response = await getAxiosWithToken({
      method: "get",
      url: "customer/all",
    });
    if (response && response.data && response.data.data) {
      setAllCustomerRaw(response.data.data);
    }
  };
  useEffect(() => {
    userManageData();
  }, []);
  let [CustomerDetailsApiRaw, setCustomerDetailsApiRaw] = useState({});

  const getCustomerDeatils = async (username) => {
    if (username !== "" || username !== "none") {
      try {
        const response = await getAxiosWithToken({
          method: "get",
          url: `customer/details/${username}`, // Adjust the API endpoint as needed
        });

        if (response && response.data && response.data.data) {
          values.firstName = `${
            response.data.data.firstName ? response.data.data.firstName : ""
          }`;
          values.middleName = `${
            response.data.data.middleName ? response.data.data.middleName : ""
          }`;
          values.lastName = `${
            response.data.data.lastName ? response.data.data.lastName : ""
          }`;
          values.mobile = `${
            response.data.data.mobile ? response.data.data.mobile : ""
          }`;
          values.email = `${
            response.data.data.email ? response.data.data.email : ""
          }`;

          return {
            firstName: response.data.data?.firstName,
            middleName: response.data.data?.middleName,
            lastName: response.data.data?.lastName,
            mobile: response.data.data?.mobile,
            email: response.data.data?.email,
          };
        }
      } catch (e) {
        console.log(e);
      }
    } else {
      setCustomerDetailsApiRaw({});
    }
  };

  const [customerId, setCustomerId] = useState(0);
  const handleChangeCustomer = async (e) => {
    const { name, value } = e.target;
    setCustomerId(value);
    if (name === "customerId" && value !== "none") {
      let CustomerDetailsApi = await getCustomerDeatils(value);
      setCustomerDetailsApiRaw(CustomerDetailsApi);
    } else {
      setCustomerDetailsApiRaw({
        firstName: "",
        middleName: "",
        lastName: "",
      });
    }
  };

  useEffect(() => {
    if (CustomerDetailsApiRaw.firstName) {
      values.firstName = `${
        CustomerDetailsApiRaw.firstName ? CustomerDetailsApiRaw.firstName : ""
      }`;
      values.middleName = `${
        CustomerDetailsApiRaw.middleName ? CustomerDetailsApiRaw.middleName : ""
      }`;
      values.lastName = `${
        CustomerDetailsApiRaw.lastName ? CustomerDetailsApiRaw.lastName : ""
      }`;
      values.mobile = `${
        CustomerDetailsApiRaw.mobile ? CustomerDetailsApiRaw.mobile : ""
      }`;
      values.email = `${
        CustomerDetailsApiRaw.email ? CustomerDetailsApiRaw.email : ""
      }`;
    } else {
      console.log(CustomerDetailsApiRaw);
      values.customerName = " ";
      values.mobile = " ";
      values.email = " ";
    }
  }, [CustomerDetailsApiRaw]);

  const [productCount, setProductCount] = useState(1);
  const [products, setProducts] = useState([{ id: 1 }]);

  const addRowInProduct = () => {
    setProductCount(productCount + 1);
    setProducts([...products, { id: productCount + 1 }]);
  };

  const removeRowInProduct = (productId) => {
    if (productCount > 1) {
      // Filter out the product with the specified id
      // console.log(productId);
      const updatedProducts = products.filter(
        (product) => product.id !== productId
      );
      const updatedProductsWithNewIds = updatedProducts.map(
        (product, index) => ({
          ...product,
          id: index + 1,
        })
      );
      // console.log(updatedProducts);
      // console.log(updatedProductsWithNewIds);
      setProductCount(productCount - 1);
      setProducts(updatedProductsWithNewIds);
    }
  };
  //------------------------------------------------------calculate total amount and taxes--------------------------------------//
  const [allItemAmount, setAllItemAmount] = useState(0);
  const [sTaxTotal, setSTaxTotal] = useState(0);
  const [grandTotal, setGrandTotal] = useState(0);
  const handleProductChange = (e, productId) => {
    const { name, value } = e.target;
    if (name === "amount" && isNaN(value)) {
      return false;
    }
    setProducts((prevProducts) =>
      prevProducts.map((product) =>
        product.id === productId ? { ...product, [name]: value } : product
      )
    );
  };

  useEffect(() => {
    handleGstCalculate();
  }, [products]);
  const handleGstCalculate = async () => {
    let amount = 0;

    await products.map((product) => {
      amount =
        parseInt(amount) + (product.amount ? parseInt(product.amount) : 0);
    });
    setAllItemAmount(amount);
  };
  const staxAmount = values.sTax;
  useEffect(() => {
    const taxFunction = (staxAmount) => {
      return (allItemAmount / 100) * staxAmount;
    };
    if (staxAmount <= 100) {
      const sTex = taxFunction(staxAmount);
      const roundedTax = sTex.toFixed(2);
      setSTaxTotal(roundedTax);
    } else {
      // Handle error or invalid input
      console.error("Invalid GST percentage provided.");
    }
  }, [staxAmount, allItemAmount]);
  useEffect(() => {
    if (sTaxTotal !== null && allItemAmount !== null) {
      const totalAmount = Number(sTaxTotal) + allItemAmount;
      setGrandTotal(totalAmount);
    }
  }, [sTaxTotal, allItemAmount]);
  values.grandTotal = `${grandTotal || null}`;
    //------------------------------------------------------------- --------------------------------------------------//

    const [allStationRaw, setAllStationRaw] = useState([]);
    const [startStation, setStartStation] = useState("");
    const [endStation, setEndStation] = useState("");
  
    // console.log("stationDetaiasdasdasd",stationDetail)
    useEffect(() => {
      if (stationDetails !== null && stationDetails) {
        setAllStationRaw(stationDetails.data);
      }
    }, [stationDetails]);
   
    
    // Function to handle change in startStation
    const handleStartStationChange = (stationId) => {
      setStartStation(stationId);
    };
  
    // Function to handle change in endStation
    const handleEndStationChange = (stationId) => {
      setEndStation(stationId);
    };

  return (
    <div>
      <div className="p-8">
        <BackButton btnName="Customer Quotation" />
        <form onSubmit={handleSubmit}>
          <div className="md:grid grid-cols-6 gap-x-20  text-left">
            {/* <div className="col-span-3 py-2 items-center">
              <label className="block mb-2 text-sm font-medium text-gray-900">
                Start Station
              </label>
              <Dropdown
                label={"Station"}
                options={allStationRaw}
                handleChange={handleChange}
                name={"startStation"}
                value={"" || values.startStation}
              />
              <InputError
                error={errors.startStation}
                touched={touched.startStation}
              />
            </div> */}
            <div className="col-span-3 py-2 items-center">
              <label className="block mb-2 text-sm font-medium text-gray-900">
                Start Station
              </label>
              <AutoInputField
                suggestions={allStationRaw}
                value={"" || values.endStation}
                onChange={handleStartStationChange}
              />
              {/* <InputError
                error={errors.startStation}
                touched={touched.startStation}
              /> */}
            </div>
            <div className="col-span-3 py-2 items-center">
              <label className=" block mb-2 text-sm font-medium text-gray-900">
                Destination Station
              </label>

              <div className="w-full">
                {/* <AutoDropDown
                suggestions={allStationRaw}
                value={endStation} 
                onChange={handleEndStationChange}
                // name="endStation"
              /> */}
                <AutoInputField
                  suggestions={allStationRaw}
                  value={"" || values.endStation}
                  onChange={handleEndStationChange}
                />
                {/* <InputError
                  error={errors.endStation}
                  touched={touched.endStation}
                /> */}
              </div>
            </div>

            {/* <div className="col-span-3 py-2 items-center">
              <label className=" block mb-2 text-sm font-medium text-gray-900">
                Destination Station
              </label>
              <div className="w-full">
                <Dropdown
                  label={"Station"}
                  options={allStationRaw}
                  handleChange={handleChange}
                  name={"endStation"}
                  value={"" || values.endStation}
                />
                <InputError
                  error={errors.endStation}
                  touched={touched.endStation}
                />
              </div>
            </div> */}
            <div className="col-span-3 py-2 items-center">
              <label className=" block mb-2 text-sm font-medium text-gray-900">
                Date of Booking
              </label>
              <div className="w-full">
                <input
                  type="date"
                  name="quotationDate"
                  value={"" || values.quotationDate}
                  onChange={handleChange}
                  className="w-full rounded-inputBRedius bg-inputBgColor p-2"
                  placeholder="Date of Booking"
                />
                <InputError
                  error={errors.quotationDate}
                  touched={touched.quotationDate}
                />
              </div>
            </div>
            <div className="col-span-3 py-2 items-center">
              <label className=" block mb-2 text-sm font-medium text-gray-900">
                Proposed Date of Delivery
              </label>
              <div className="w-full">
                <input
                  type="date"
                  name="proposedDeliveryDate"
                  value={"" || values.proposedDeliveryDate}
                  onChange={handleChange}
                  className="w-full rounded-inputBRedius bg-inputBgColor p-2"
                  placeholder="Proposed Date of Delivery"
                />
                <InputError
                  error={errors.proposedDeliveryDate}
                  touched={touched.proposedDeliveryDate}
                />
              </div>
            </div>
            <label className="col-span-6 block my-2 text-sm font-medium text-gray-900">
              Customer Name
            </label>
            <div className="col-span-6 py-2 items-center">
              <select
                defaultValue={customerId}
                name="customerId"
                onChange={handleChangeCustomer}
                className=" w-full rounded-inputBRedius bg-inputBgColor p-2"
              >
                <option value={0} hidden disabled>
                  Select the customer
                </option>
                <option value={"none"}>None</option>
                {allCustomerRaw.map((option, index) => (
                  <option key={index} value={option.username}>
                    {option.firstName +
                      " " +
                      option.middleName +
                      " " +
                      option.lastName}
                  </option>
                ))}
              </select>
            </div>
            <div className="col-span-2 py-2 items-center">
              <div className="w-full">
                <input
                  type="text"
                  name="firstName"
                  value={"" || values.firstName}
                  onChange={handleChange}
                  className="w-full rounded-inputBRedius bg-inputBgColor p-2 "
                  placeholder="First Name"
                />
                <InputError
                  error={errors.firstName}
                  touched={touched.firstName}
                />
              </div>
            </div>
            <div className="col-span-2 py-2 items-center">
              <div className="w-full">
                <input
                  type="text"
                  name="middleName"
                  value={"" || values.middleName}
                  onChange={handleChange}
                  className="w-full rounded-inputBRedius bg-inputBgColor p-2 "
                  placeholder="Middle Name"
                />
                <InputError
                  error={errors.middleName}
                  touched={touched.middleName}
                />
              </div>
            </div>
            <div className="col-span-2 py-2 items-center">
              <div className="w-full">
                <input
                  type="text"
                  name="lastName"
                  value={"" || values.lastName}
                  onChange={handleChange}
                  className="w-full rounded-inputBRedius bg-inputBgColor p-2 "
                  placeholder="Last Name"
                />
                <InputError
                  error={errors.lastName}
                  touched={touched.lastName}
                />
              </div>
            </div>
            <div className="col-span-3 py-2 items-center">
              <label className=" block mb-2 text-sm font-medium text-gray-900">
                Contact Number
              </label>
              <div className="w-full">
                <input
                  type="text"
                  name="mobile"
                  value={"" || values.mobile}
                  onChange={handleChange}
                  className="w-full rounded-inputBRedius bg-inputBgColor p-2"
                  placeholder="Contact"
                />
                <InputError error={errors.mobile} touched={touched.mobile} />
              </div>
            </div>
            <div className="col-span-3 py-2 items-center">
              <label className=" block mb-2 text-sm font-medium text-gray-900">
                Email Address
              </label>
              <div className="w-full">
                <input
                  type="email"
                  name="email"
                  value={"" || values.email}
                  onChange={handleChange}
                  className="w-full rounded-inputBRedius bg-inputBgColor p-2"
                  placeholder="Email"
                />
                <InputError error={errors.email} touched={touched.email} />
              </div>
            </div>
          </div>

          <div className="md:grid grid-cols-6 gap-x-20  text-left">
            <div className="flex items-center col-span-6 text-lg font-semibold  mb-5 mt-5 ">
              From <p className="text-sm">(Address)</p>
            </div>
            <div className="col-span-3 py-2 items-center">
              <label className=" block mb-2 text-sm font-medium text-gray-900">
                Name
              </label>
              <div className="w-full">
                <input
                  type="text"
                  name="senderName"
                  value={"" || values.senderName}
                  onChange={handleChange}
                  className="w-full rounded-inputBRedius bg-inputBgColor p-2 "
                  placeholder="User Name"
                />
                <InputError
                  error={errors.senderName}
                  touched={touched.senderName}
                />
              </div>
            </div>

            <div className="col-span-3 py-2 items-center">
              <label className=" block mb-2 text-sm font-medium text-gray-900">
                Locality / Street
              </label>
              <div className="w-full">
                <input
                  type="text"
                  name="senderLocality"
                  value={"" || values.senderLocality}
                  onChange={handleChange}
                  className="w-full rounded-inputBRedius bg-inputBgColor p-2"
                  placeholder="Locality / Street"
                />
                <InputError
                  error={errors.senderLocality}
                  touched={touched.senderLocality}
                />
              </div>
            </div>
            <div className="col-span-3 gap-6   py-2 items-center">
              <label className=" block mb-2 text-sm font-medium text-gray-900 ">
                State
              </label>
              <div className="w-full">
                <StateName
                  handleChange={handleChange}
                  name={"fromState"}
                  value={values.fromState}
                />
                <InputError
                  error={errors.fromState}
                  touched={touched.fromState}
                />
              </div>
            </div>
            <div className="col-span-3 gap-6   py-2 items-center">
              <label className=" block mb-2 text-sm font-medium text-gray-900 ">
                City
              </label>
              <div className="w-full">
                <CityName
                  handleChange={handleChange}
                  name={"fromCity"}
                  value={values.fromCity}
                  stateId={values.fromState}
                />
                <InputError
                  error={errors.fromCity}
                  touched={touched.fromCity}
                />
              </div>
            </div>
            <div className="col-span-3 py-2 items-center">
              <label className=" block mb-2 text-sm font-medium text-gray-900">
                Pin Code
              </label>
              <div className="w-full">
                <input
                  type="text"
                  name="fromPincode"
                  value={"" || values.fromPincode}
                  onChange={handleChange}
                  className="w-full rounded-inputBRedius bg-inputBgColor p-2 "
                  placeholder="Pin Code"
                />
                <InputError
                  error={errors.fromPincode}
                  touched={touched.fromPincode}
                />
              </div>
            </div>
          </div>
          <div className="md:grid grid-cols-6 gap-x-20  text-left">
            <div className="flex items-center col-span-6 text-lg font-semibold  mb-5 mt-5 ">
              To <p className="text-sm">(Address)</p>{" "}
            </div>
            <div className="col-span-3 py-2 items-center">
              <label className=" block mb-2 text-sm font-medium text-gray-900">
                Name
              </label>
              <div className="w-full">
                <input
                  type="text"
                  name="receiverName"
                  value={"" || values.receiverName}
                  onChange={handleChange}
                  className="w-full rounded-inputBRedius bg-inputBgColor p-2 "
                  placeholder="Receiver Name"
                />
                <InputError
                  error={errors.receiverName}
                  touched={touched.receiverName}
                />
              </div>
            </div>
            <div className="col-span-3 py-2 items-center">
              <label className=" block mb-2 text-sm font-medium text-gray-900">
                Locality / Street
              </label>
              <div className="w-full">
                <input
                  type="text"
                  name="receiverLocality"
                  value={"" || values.receiverLocality}
                  onChange={handleChange}
                  className="w-full rounded-inputBRedius bg-inputBgColor p-2 "
                  placeholder="Locality / Street"
                />
                <InputError
                  error={errors.receiverLocality}
                  touched={touched.receiverLocality}
                />
              </div>
            </div>

            <div className="col-span-3 gap-6   py-2 items-center">
              <label className="  block mb-2 text-sm font-medium text-gray-900">
                State
              </label>
              <div className="w-full">
                <StateName
                  handleChange={handleChange}
                  name={"toState"}
                  value={values.toState}
                />
                <InputError error={errors.toState} touched={touched.toState} />
              </div>
            </div>
            <div className="col-span-3 gap-6  py-2 items-center">
              <label className=" block mb-2 text-sm font-medium text-gray-900 ">
                City
              </label>
              <div className="w-full">
                <CityName
                  handleChange={handleChange}
                  name={"toCity"}
                  value={values.toCity}
                  stateId={values.toState}
                />
                <InputError error={errors.toCity} touched={touched.toCity} />
              </div>
            </div>
            <div className="col-span-3 py-2 items-center">
              <label className=" block mb-2 text-sm font-medium text-gray-900">
                Pin Code
              </label>
              <div className="w-full">
                <input
                  type="text"
                  name="toPincode"
                  value={"" || values.toPincode}
                  onChange={handleChange}
                  className="w-full rounded-inputBRedius bg-inputBgColor p-2 "
                  placeholder="Pin Code"
                />
                <InputError
                  error={errors.toPincode}
                  touched={touched.toPincode}
                />
              </div>
            </div>
          </div>
          <div className="text-lg font-semibold text-left mb-5 mt-5">
            <p className="text-xl font-bold">Product Details</p>
          </div>
          <div className="w-full">
            {products.map((product) => (
              <div key={product.id}>
                <div
                  id="createDivBox"
                  className=" grid md:grid-flow-col items-end  gap-x-4 "
                >
                  <div className="py-2 items-center">
                    <label className="block mb-2 text-sm font-medium text-gray-900 text-left">
                      S. No.
                    </label>
                    <div className="w-12">
                      <input
                        value={product.id + "."}
                        disabled
                        className="w-full rounded-inputBRedius bg-inputBgColor p-2 "
                      />
                    </div>
                  </div>
                  <div className=" col-span-1 py-2  items-center">
                    <label className=" block mb-2 text-sm font-medium text-gray-900">
                      No. of Parcel
                    </label>
                    <div className="w-full ">
                      <input
                        type="text"
                        name="noOfParcel"
                        value={product.noOfParcel}
                        onChange={(e) => handleProductChange(e, product.id)}
                        className="w-full rounded-inputBRedius bg-inputBgColor p-2 "
                        placeholder="No. of Parcel"
                      />
                      <InputError
                        error={errors.noOfParcel}
                        touched={touched.noOfParcel}
                      />
                    </div>
                  </div>
                  <div className="col-span-1 py-2 items-center">
                    <label className=" block mb-2 text-sm font-medium text-gray-900">
                      To Pay/Paid{" "}
                    </label>
                    <div className="w-full ">
                      <select
                        name="toPay"
                        value={product.toPay}
                        onChange={(e) => handleProductChange(e, product.id)}
                        className="w-full rounded-inputBRedius bg-inputBgColor p-2 "
                      >
                        <option value="">None</option>
                        <option value="Pay">Pay</option>
                        <option value="Paid">Paid</option>
                      </select>
                      <InputError
                        error={errors.toPay}
                        touched={touched.toPay}
                      />
                    </div>
                  </div>
                  <div className="col-span-1 py-2 items-center">
                    <label className=" block mb-2 text-sm font-medium text-gray-900">
                      Weight Kgs.
                    </label>
                    <div className="w-full ">
                      <input
                        type="text"
                        name="weight"
                        value={product.weight}
                        onChange={(e) => handleProductChange(e, product.id)}
                        className="w-full rounded-inputBRedius bg-inputBgColor p-2 "
                        placeholder="Weight Kgs."
                      />
                      <InputError
                        error={errors.weight}
                        touched={touched.weight}
                      />
                    </div>
                  </div>
                  <div className="col-span-2 py-2 items-center">
                    <label className=" block mb-2 text-sm font-medium text-gray-900">
                      Amount
                    </label>
                    <div className="w-full ">
                      <input
                        type="text"
                        name="amount"
                        value={product.amount}
                        onChange={(e) => handleProductChange(e, product.id)}
                        className="w-full rounded-inputBRedius bg-inputBgColor p-2 "
                        placeholder="Amount"
                      />
                      <InputError
                        error={errors.amount}
                        touched={touched.amount}
                      />
                    </div>
                  </div>
                  <div className="col-span-2 flex gap-3 justify-center m-2  ">
                    <div
                      className={product?.id}
                      onClick={() => removeRowInProduct(product?.id)}
                    >
                      <PrimeButton btnName="- Remove" btnType="button" />
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
          <div onClick={addRowInProduct} className="w-full my-4">
            <PrimeButton
              btnName="+ Add Item"
              btnType="button"
              className=" sm:w-full"
            />
          </div>
          <div className="grid md:grid-cols-6 justify-between p-2">
            <div className=" col-span-4 w-full py-2">
            <label className="block mb-2  font-medium text-gray-900 text-left">
                Additional Comments
              </label>
              <textarea
                name="addComment"
                id=""
                cols="40"
                rows="4"
                className="rounded-inputBRedius bg-inputBgColor w-full h-full p-2"
                placeholder="Additional Comments"
                value={"" || values.addComment}
                onChange={handleChange}
              ></textarea>
            </div>
            <div className="col-span-2 items-center">
              <div className="lg:flex gap-6 p-2 items-center ">
                <label className="w-36 text-sm font-medium text-gray-900">
                  S.Tax
                </label>
                <div className="w-full">
                  <input
                    type="text"
                    name="sTax"
                    value={"" || values.sTax}
                    onChange={handleChange}
                    className="w-full rounded-inputBRedius bg-inputBgColor p-2 "
                    placeholder=" S.Tax"
                  />
                  <InputError error={errors.sTax} touched={touched.sTax} />
                </div>
              </div>
              <div className="lg:flex gap-6 p-2 items-center ">
                <label className="w-36 text-sm font-medium text-gray-900">
                  Grand Total
                </label>
                <div className="w-full">
                  <input
                    type="text"
                    name="grandTotal"
                    value={"" || values.grandTotal}
                    onChange={handleChange}
                    className="w-full rounded-inputBRedius bg-inputBgColor p-2 "
                    placeholder="Grand Total"
                  />
                  <InputError
                    error={errors.grandTotal}
                    touched={touched.grandTotal}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="mt-12">
            <PrimeButton btnName="Sumit" />
          </div>
        </form>
      </div>
    </div>
  );
};

export default CustomerQuotationForm;
