import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { PrimeButton, BackButton } from "../../../component/PrimeButton";
import * as Yup from "yup";
import { useFormik } from "formik";
import { getAxiosWithToken } from "../../../axios/AxiosObj";
import {
  alertResponseMsgError,
  alertResponseMsgSuccess,
} from "../../../function/utilies";
import InputError from "../../../component/InputError";

const initialValues = {
  registrationDate: "",
  regExpiryDate: "",
  vehicleModel: "",
  registrationNumber: "",
  manufactureYear: "",
  ownedBy: "",
  currentLocation: "",
  dateofPurchase: "",
  purchasedFrom: "",
  PurchasedUnder: "",
  purchasePrice: " ",
  depreciation: "",
  currentValue: "",
  currentInsuranceProvider: "",
  policyNumber: "",
  policyType: "",
  policyStartDate: "",
  policyEndDate: "",
  policyPremium: "",
  lastFitnessRenewalDate: "",
  currentFitnessValidUpto: "",
  firstRegValidUpto: "",
  renewalDate: "",
  renewalValidUpto: "",
  addcomment: "",
};

const AddVehicle = () => {
  const navigate = useNavigate();
  const [buttonLoader, setButtonLoader] = useState(false);

  const userCreateSchema = Yup.object({
    registrationDate: Yup.string().required(
      "Registration Date should not be empty"
    ),
    regExpiryDate: Yup.string().required(
      "Reg. Expiry Date Station should not be empty"
    ),
    vehicleModel: Yup.string().required("Make/Model should not be empty"),
    registrationNumber: Yup.number().required(
      "Registration Number should not be empty"
    ),
    manufactureYear: Yup.number().required(
      "Year of Manufacture should not be empty"
    ),
    ownedBy: Yup.string().required("Owned By should not be empty"),
    currentLocation: Yup.string().required(
      "Current Location should not be empty"
    ),
    dateofPurchase: Yup.string().required(
      "Date of Purchase should not be empty"
    ),
    purchasedFrom: Yup.string().required("Sender Name should not be empty"),
    PurchasedUnder: Yup.string().required(
      "Purchased under should not be empty"
    ),
    purchasePrice: Yup.number().required("Purchase Price should not be empty"),
    depreciation: Yup.string().required("Depreciation should not be empty"),
    currentValue: Yup.number().required("Current Value should not be empty"),
    currentInsuranceProvider: Yup.string().required(
      "Current Insurance Provider should not be empty"
    ),
    policyNumber: Yup.number().required("Policy No.*"),
    policyType: Yup.string().required("Policy Type*"),
    policyStartDate: Yup.string().required("Policy Start Date No.*"),
    policyEndDate: Yup.string().required("Policy End Date No.*"),
    policyPremium: Yup.string().required("Policy Premium*"),
    lastFitnessRenewalDate: Yup.string().required("Last Fitness Renewal Date*"),
    currentFitnessValidUpto: Yup.string().required(
      "Current Fitness Valid Upto*"
    ),
    firstRegValidUpto: Yup.string().required("First Reg. Valid Upto*"),
    renewalDate: Yup.string().required("Renewal Date*"),
    renewalValidUpto: Yup.string().required("Renewal Valid Upto*"),
  });

  const { values, errors, touched, handleChange, handleSubmit } = useFormik({
    initialValues: initialValues,
    validationSchema: userCreateSchema,

    onSubmit: async (values, action) => {
      setButtonLoader(true);

      const data = {
        registrationNo: values.registrationNumber,
        registrationVaildDate: values.registrationDate,
        vehicleModel: values.vehicleModel,
        registrationExpDate: values.regExpiryDate,
        manufactureYear: values.manufactureYear,
        ownerName: values.ownedBy,
        location: values.currentLocation,
        purchaseDate: values.dateofPurchase,
        purchaseFrom: values.purchasedFrom,
        purchaseUnder: values.PurchasedUnder,
        purchasePrice: values.purchasePrice,
        depreciation: values.depreciation,
        currentValue: values.currentValue,
        insuranceProvider: values.currentInsuranceProvider,
        policyNumber: values.policyNumber,
        policyType: values.policyType,
        policyStartDate: values.policyStartDate,
        policyEndDate: values.policyEndDate,
        policypremium: values.policyPremium,
        lastFitnessDate: values.lastFitnessRenewalDate,
        currentFitnessDate: values.currentFitnessValidUpto,
        firstRegVaildUpto: values.firstRegValidUpto,
        renewalDate: values.renewalDate,
        renewalVaildUpto: values.renewalValidUpto,
        additionalCmt: values.addcomment,
      };

      // console.log("data", data);
      // return false;
      try {
        const response = await getAxiosWithToken({
          method: "POST",
          url: "vehicle/add", // Adjust the API endpoint as needed
          data: data,
        });
        // API response 200 201
        if (response.data.success) {
          alertResponseMsgSuccess(response.data.msg);
          setButtonLoader(false);
          navigate("/vehicle");
        } else {
          alertResponseMsgError(response.data.msg);
        }
      } catch (error) {
        console.error(error);
        alertResponseMsgError(error.response.data.msg);
        setButtonLoader(false);

      }
    },
  });
  //year start for current year
  const currentYear = new Date().getFullYear();
  const endYear = 1995;
  const years = Array.from(
    { length: currentYear - endYear + 1 },
    (_, index) => currentYear - index
  );

  return (
    <div>
      <div className="bg-backgroundColor rounded-xl p-8">
        <BackButton btnName="Add Vehicle" backtoPage="/managevehicle" />
        <form onSubmit={handleSubmit}>
          <div className="md:grid grid-cols-6 gap-4 text-left text-mainTxtColor">
            <div className="div col-span-6 text-lg font-semibold  ">
              Vehicle Details
            </div>
            <div className="col-span-6 gap-6 mb-5 sm:mb-3  lg:flex  items-center">
              <label className="md:flex-none text-sm font-medium ">
                Registration Number
              </label>
              <div className="w-full">
                <input
                  type="text"
                  name="registrationNumber"
                  value={"" || values.registrationNumber}
                  onChange={handleChange}
                  className="w-full rounded-inputBRedius bg-inputBgColor p-2"
                  placeholder="Registration Number"
                />
                <InputError
                  error={errors.registrationNumber}
                  touched={touched.registrationNumber}
                />
              </div>
            </div>
            <div className="col-span-3 gap-6 mb-5 sm:mb-3  lg:flex  items-center">
              <label className=" w-32 md:flex-none text-sm font-medium ">
                Registration Date
              </label>
              <div className="w-full">
                {/* <Datepicker value={"" ||values.registrationDate} onChange={handleChange} /> */}
                <input
                  type="date"
                  name="registrationDate"
                  value={"" || values.registrationDate}
                  onChange={handleChange}
                  className="w-full rounded-inputBRedius bg-inputBgColor p-2"
                  placeholder="Reg. Expiry Date"
                />
                <InputError
                  error={errors.registrationDate}
                  touched={touched.registrationDate}
                />
              </div>
            </div>
            <div className="col-span-3 gap-6 mb-5 sm:mb-3  lg:flex  items-center">
              <label className=" w-32 md:flex-none text-sm font-medium ">
                Reg. Expiry Date
              </label>
              <div className="w-full">
                <input
                  type="date"
                  name="regExpiryDate"
                  value={"" || values.regExpiryDate}
                  onChange={handleChange}
                  className="w-full rounded-inputBRedius bg-inputBgColor p-2"
                  placeholder="Reg. Expiry Date"
                />
                <InputError
                  error={errors.regExpiryDate}
                  touched={touched.regExpiryDate}
                />
              </div>
            </div>
            <div className="col-span-3 gap-6 mb-5 sm:mb-3  lg:flex  items-center">
              <label className=" w-32 md:flex-none text-sm font-medium ">
                Make/Model
              </label>
              <div className="w-full">
                <input
                  type="text"
                  name="vehicleModel"
                  value={"" || values.vehicleModel}
                  onChange={handleChange}
                  className="w-full rounded-inputBRedius bg-inputBgColor p-2"
                  placeholder="Make/Model"
                />
                <InputError
                  error={errors.vehicleModel}
                  touched={touched.vehicleModel}
                />
              </div>
            </div>
            <div className="col-span-3 gap-6 mb-5 sm:mb-3  lg:flex  items-center">
              <label className=" w-32 md:flex-none text-sm font-medium ">
                Year of Manufacture
              </label>
              <div className="w-full">
                <select
                  id="yearDropdown"
                  name="manufactureYear"
                  value={values.manufactureYear}
                  onChange={handleChange}
                  className="w-full rounded-inputBRedius bg-inputBgColor p-2"
                >
                  {years.map((year, index) => (
                    <option key={index} value={year}>
                      {year}
                    </option>
                  ))}
                </select>
                <InputError
                  error={errors.manufactureYear}
                  touched={touched.manufactureYear}
                />
              </div>
            </div>
            <div className="col-span-3 gap-6 mb-5 sm:mb-3  lg:flex  items-center">
              <label className=" w-32 md:flex-none text-sm font-medium ">
                Owned By
              </label>
              <div className="w-full">
                <input
                  type="text"
                  name="ownedBy"
                  value={"" || values.ownedBy}
                  onChange={handleChange}
                  className="w-full rounded-inputBRedius bg-inputBgColor p-2"
                  placeholder="Owned By"
                />
                <InputError error={errors.ownedBy} touched={touched.ownedBy} />
              </div>
            </div>
            <div className="col-span-3 gap-6 mb-5 sm:mb-3  lg:flex  items-center">
              <label className=" w-32 md:flex-none text-sm font-medium ">
                Current Location
              </label>
              <div className="w-full">
                <input
                  type="text"
                  name="currentLocation"
                  value={"" || values.currentLocation}
                  onChange={handleChange}
                  className="w-full rounded-inputBRedius bg-inputBgColor p-2"
                  placeholder="Current Location"
                />
                <InputError
                  error={errors.currentLocation}
                  touched={touched.currentLocation}
                />
              </div>
            </div>
            <div className="col-span-2 gap-6 mb-5 sm:mb-3  lg:flex  items-center">
              <label className=" w-32 md:flex-none text-sm font-medium ">
                Date of Purchase
              </label>
              <div className="w-full">
                <input
                  type="date"
                  name="dateofPurchase"
                  value={"" || values.dateofPurchase}
                  onChange={handleChange}
                  className="w-full rounded-inputBRedius bg-inputBgColor p-2"
                  placeholder="Date of Purchase"
                />
                <InputError
                  error={errors.dateofPurchase}
                  touched={touched.dateofPurchase}
                />
              </div>
            </div>
            <div className="col-span-2 gap-6 mb-5 sm:mb-3  lg:flex  items-center">
              <label className=" w-32 md:flex-none text-sm font-medium ">
                Purchased from
              </label>
              <div className="w-full">
                <input
                  type="text"
                  name="purchasedFrom"
                  value={"" || values.purchasedFrom}
                  onChange={handleChange}
                  className="w-full rounded-inputBRedius bg-inputBgColor p-2"
                  placeholder="Purchased from"
                />
                <InputError
                  error={errors.purchasedFrom}
                  touched={touched.purchasedFrom}
                />
              </div>
            </div>
            <div className="col-span-2 gap-6 mb-5 sm:mb-3  lg:flex  items-center">
              <label className=" w-32 md:flex-none text-sm font-medium ">
                Purchased under
              </label>
              <div className="w-full">
                <input
                  type="text"
                  name="PurchasedUnder"
                  value={"" || values.PurchasedUnder}
                  onChange={handleChange}
                  className="w-full rounded-inputBRedius bg-inputBgColor p-2"
                  placeholder="Purchased under"
                />
                <InputError
                  error={errors.PurchasedUnder}
                  touched={touched.PurchasedUnder}
                />
              </div>
            </div>
            <div className="col-span-2 gap-6 mb-5 sm:mb-3  lg:flex  items-center">
              <label className=" w-32 md:flex-none text-sm font-medium ">
                Purchase Price (in INR)
              </label>
              <div className="w-full">
                <input
                  type="text"
                  name="purchasePrice"
                  value={"" || values.purchasePrice}
                  onChange={handleChange}
                  className="w-full rounded-inputBRedius bg-inputBgColor p-2"
                  placeholder="Purchase Price"
                />
                <InputError
                  error={errors.purchasePrice}
                  touched={touched.purchasePrice}
                />
              </div>
            </div>
            <div className="col-span-2 gap-6 mb-5 sm:mb-3  lg:flex  items-center">
              <label className=" w-32 md:flex-none text-sm font-medium ">
                % of Depreciation
              </label>
              <div className="w-full">
                <input
                  type="text"
                  name="depreciation"
                  value={"" || values.depreciation}
                  onChange={handleChange}
                  className="w-full rounded-inputBRedius bg-inputBgColor p-2"
                  placeholder="Depreciation"
                />
                <InputError
                  error={errors.depreciation}
                  touched={touched.depreciation}
                />
              </div>
            </div>
            <div className="col-span-2 gap-6 mb-5 sm:mb-3  lg:flex  items-center">
              <label className=" w-32 md:flex-none text-sm font-medium ">
                Current Value
              </label>
              <div className="w-full">
                <input
                  type="text"
                  name="currentValue"
                  value={"" || values.currentValue}
                  onChange={handleChange}
                  className="w-full rounded-inputBRedius bg-inputBgColor p-2"
                  placeholder="Current Value"
                />
                <InputError
                  error={errors.currentValue}
                  touched={touched.currentValue}
                />
              </div>
            </div>
          </div>
          <div className="md:grid grid-cols-6 gap-4 mt-6 text-left ">
            <div className="div col-span-6 text-lg font-semibold ">
              Insurance Details
            </div>
            <div className="col-span-2 gap-6 mb-5 sm:mb-3  lg:flex  items-center">
              <label className=" w-32 md:flex-none text-sm font-medium ">
                Current Insurance Provider
              </label>
              <div className="w-full">
                <input
                  type="text"
                  name="currentInsuranceProvider"
                  value={"" || values.currentInsuranceProvider}
                  onChange={handleChange}
                  className="w-full rounded-inputBRedius bg-inputBgColor p-2"
                  placeholder="Current Insurance Provider"
                />
                <InputError
                  error={errors.currentInsuranceProvider}
                  touched={touched.currentInsuranceProvider}
                />
              </div>
            </div>
            <div className="col-span-2 gap-6 mb-5 sm:mb-3  lg:flex  items-center">
              <label className=" w-32 md:flex-none text-sm font-medium ">
                Policy Number
              </label>
              <div className="w-full">
                <input
                  type="text"
                  name="policyNumber"
                  value={"" || values.policyNumber}
                  onChange={handleChange}
                  className="w-full rounded-inputBRedius bg-inputBgColor p-2"
                  placeholder="Policy Number"
                />
                <InputError
                  error={errors.policyNumber}
                  touched={touched.policyNumber}
                />
              </div>
            </div>
            <div className="col-span-2 gap-6 mb-5 sm:mb-3  lg:flex  items-center">
              <label className=" w-32 md:flex-none text-sm font-medium ">
                Policy Type
              </label>
              <div className="w-full">
                <input
                  type="text"
                  name="policyType"
                  value={"" || values.policyType}
                  onChange={handleChange}
                  className="w-full rounded-inputBRedius bg-inputBgColor p-2"
                  placeholder="Policy Type"
                />
                <InputError
                  error={errors.policyType}
                  touched={touched.policyType}
                />
              </div>
            </div>
            <div className="col-span-2 gap-6 mb-5 sm:mb-3  lg:flex  items-center">
              <label className=" w-32 md:flex-none text-sm font-medium ">
                Policy Start Date
              </label>
              <div className="w-full">
                <input
                  type="date"
                  name="policyStartDate"
                  value={"" || values.policyStartDate}
                  onChange={handleChange}
                  className="w-full rounded-inputBRedius bg-inputBgColor p-2"
                  placeholder="Policy Start Date"
                />
                <InputError
                  error={errors.policyStartDate}
                  touched={touched.policyStartDate}
                />
              </div>
            </div>
            <div className="col-span-2 gap-6 mb-5 sm:mb-3  lg:flex  items-center">
              <label className=" w-32 md:flex-none text-sm font-medium ">
                Policy End Date
              </label>
              <div className="w-full">
                <input
                  type="date"
                  name="policyEndDate"
                  value={"" || values.policyEndDate}
                  onChange={handleChange}
                  className="w-full rounded-inputBRedius bg-inputBgColor p-2"
                  placeholder="Policy End Date"
                />
                <InputError
                  error={errors.policyEndDate}
                  touched={touched.policyEndDate}
                />
              </div>
            </div>
            <div className="col-span-2 gap-6 mb-5 sm:mb-3  lg:flex  items-center">
              <label className=" w-32 md:flex-none text-sm font-medium ">
                Policy Premium
              </label>
              <div className="w-full">
                <input
                  type="text"
                  name="policyPremium"
                  value={"" || values.policyPremium}
                  onChange={handleChange}
                  className="w-full rounded-inputBRedius bg-inputBgColor p-2"
                  placeholder="Policy Premium"
                />
                <InputError
                  error={errors.policyPremium}
                  touched={touched.policyPremium}
                />
              </div>
            </div>
          </div>
          <div className="md:grid grid-cols-6 gap-4 mt-6 text-left ">
            <div className="div col-span-6 text-lg font-semibold ">
              Fitness Details
            </div>
            <div className="col-span-3 gap-6 mb-5 sm:mb-3  lg:flex  items-center">
              <label className=" w-34 md:flex-none text-sm font-medium ">
                Last Fitness Renewal Date
              </label>
              <div className="w-full">
                <input
                  type="date"
                  name="lastFitnessRenewalDate"
                  value={"" || values.lastFitnessRenewalDate}
                  onChange={handleChange}
                  className="w-full rounded-inputBRedius bg-inputBgColor p-2"
                  placeholder="Last Fitness Renewal Date"
                />
                <InputError
                  error={errors.lastFitnessRenewalDate}
                  touched={touched.lastFitnessRenewalDate}
                />
              </div>
            </div>
            <div className="col-span-3 gap-6 mb-5 sm:mb-3  lg:flex  items-center">
              <label className=" w-34 md:flex-none text-sm font-medium ">
                Current Fitness Valid Upto
              </label>
              <div className="w-full">
                <input
                  type="date"
                  name="currentFitnessValidUpto"
                  value={"" || values.currentFitnessValidUpto}
                  onChange={handleChange}
                  className="w-full rounded-inputBRedius bg-inputBgColor p-2"
                  placeholder="Current Fitness Valid Upto"
                />
                <InputError
                  error={errors.currentFitnessValidUpto}
                  touched={touched.currentFitnessValidUpto}
                />
              </div>
            </div>
          </div>
          <div className="md:grid grid-cols-6 gap-4 mt-6 text-left ">
            <div className="div col-span-6 text-lg font-semibold ">
              Registration Renewal Details
            </div>
            <div className="col-span-2 gap-6 mb-5 sm:mb-3  lg:flex  items-center">
              <label className=" w-34 md:flex-none text-sm font-medium ">
                First Reg. Valid Upto
              </label>
              <div className="w-full">
                <input
                  type="date"
                  name="firstRegValidUpto"
                  value={"" || values.firstRegValidUpto}
                  onChange={handleChange}
                  className="w-full rounded-inputBRedius bg-inputBgColor p-2"
                  placeholder="First Reg. Valid Upto"
                />
                <InputError
                  error={errors.firstRegValidUpto}
                  touched={touched.firstRegValidUpto}
                />
              </div>
            </div>
            <div className="col-span-2 gap-6 mb-5 sm:mb-3  lg:flex  items-center">
              <label className=" w-34 md:flex-none text-sm font-medium ">
                Renewal Date
              </label>
              <div className="w-full">
                <input
                  type="date"
                  name="renewalDate"
                  value={"" || values.renewalDate}
                  onChange={handleChange}
                  className="w-full rounded-inputBRedius bg-inputBgColor p-2"
                  placeholder="Renewal Date"
                />
                <InputError
                  error={errors.renewalDate}
                  touched={touched.renewalDate}
                />
              </div>
            </div>
            <div className="col-span-2 gap-6 mb-5 sm:mb-3  lg:flex  items-center">
              <label className=" w-34 md:flex-none text-sm font-medium ">
                Renewal Valid Upto
              </label>
              <div className="w-full">
                <input
                  type="date"
                  name="renewalValidUpto"
                  value={"" || values.renewalValidUpto}
                  onChange={handleChange}
                  className="w-full rounded-inputBRedius bg-inputBgColor p-2"
                  placeholder="Renewal Valid Upto"
                />
                <InputError
                  error={errors.renewalValidUpto}
                  touched={touched.renewalValidUpto}
                />
              </div>
            </div>
            <div className="col-span-6 mt-4 items-center">
              <label className=" w-34 md:flex-none text-sm font-medium ">
                Additional Comments
              </label>
              <textarea
                name="addcomment"
                id=""
                cols="30"
                rows="5"
                placeholder="Additional Comments"
                value={"" || values.addcomment}
                onChange={handleChange}
                className="w-full rounded-inputBRedius bg-inputBgColor p-2"
              ></textarea>
            </div>
          </div>
          <div className="mt-12">
            <PrimeButton btnName="Sumit" buttonLoader={buttonLoader} />
          </div>
        </form>
      </div>
    </div>
  );
};

export default AddVehicle;
