import React from "react";
import { useDispatch } from "react-redux";
import { setDeleteDetail} from "../store/features/userSlice";
import {
  Button,
  Dialog,
  DialogHeader,
  DialogBody,
  DialogFooter,
} from "@material-tailwind/react";
import { getAxiosWithToken } from "../axios/AxiosObj";
import {
  alertResponseMsgError,
  alertResponseMsgSuccess,
} from "../function/utilies";
export default function DeleteDialogBox({
  open,
  setOpen,
  // isDeleted,
  // setIsDeleted,
  data,}) {
    const dispatch = useDispatch();
  const handleOpen = () => setOpen(!open);
  const handleDeleteApi = async () => {
    if (open && data && data.deleteUrl) {
      // setDataLoading(true)
      const response = await getAxiosWithToken({
        method: "delete",
        url: data.deleteUrl,
      });
      // console.log(response.data);
      if (response.data && response.data.success && response.data.msg) {
        alertResponseMsgSuccess(response.data.msg);
        dispatch(setDeleteDetail(true));
        // window.location.reload();
        setOpen(!open);
        // if (isDeleted) {
          // window.location.reload();
          // }
        // setIsDeleted(true)
      } else {
        alertResponseMsgError(response.data.msg);
      }
    }
  };

  return (
    <>
      <Dialog open={open} handler={handleOpen} size="xs">
        <DialogHeader>
          {data.headerText ? data.headerText : "Null"}
        </DialogHeader>
        <DialogBody>{data.paraText ? data.paraText : "Null"}</DialogBody>
        <DialogFooter>
          <Button variant="text" color="green" onClick={handleDeleteApi}>
            <span>Confirm</span>
          </Button>
          <Button
            variant="text"
            color="red"
            onClick={handleOpen}
            className="mr-1"
          >
            <span>Cancel</span>
          </Button>
        </DialogFooter>
      </Dialog>
    </>
  );
}
