import * as React from 'react';
import { LineChart } from '@mui/x-charts/LineChart';

const currentYear = [4000, 3000, 2000, 2780, 1890, 2390, 3490, 4000, 3000, 2000, 2780, 1890,];
const lsatYear = [2400, 1398, 9800, 3908, 4800, 3800, 4300, 4000, 3000, 2000, 2780, 1890,];
const xLabels = [
  'Jan',
  'Fab',
  'Mar',
  'Apr',
  'May',
  'Jun',
  'Jul',
  'Aug',
  'Sep',
  'Oct',
  'Nov',
  'Dec'
];

export default function TotalRevenueChart() {
  return (
    <div className='text-left'>
        <h1>Total Revenue</h1>
    <LineChart
    //   width={500}
      height={300}
      series={[
        { data: currentYear, label: '2023' },
        { data: lsatYear, label: '2024', color: '#FF5B5B' },
      ]}
      xAxis={[{ scaleType: 'point', data: xLabels }]}
    />
    </div>
  );
}