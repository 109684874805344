import React from "react";
import arrow from "../img/arrow.svg";

export const Statusbar = (props, { isActive = false }) => {
  return (
    <div>
      <div
        className={`col-span-1 xl:h-32 xl:items-center flex xl:flex-row  lg:flex-col justify-center gap-6 p-4 text-left rounded-primeryBRedius shadow-md hover:bg-primeryBgColor hover:text-white 
        ${props.isActive ? "bg-primeryBgColor text-white shadow-none mt-1" : "bg-white text-mainTxtColor"}`}
      >
        <div className=" shrink">
          <img src={props.src} alt="Icon_Order" />
        </div>
        <div className="shrink ">
          <h1 className="text-2xl font-bold ">{props.count}</h1>
          <p className="text-sm font-semibold">{props.title}</p>
          <div className="flex items-center gap-1 my-1">
            <img src={arrow} alt="" className="bg-blue-200 w-4 p-1 rounded-full" />
            <p className="text-sm">{props.rating}</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export const AvailablityCard = (props) => {
  return (
    <div>
      <div className=" col-span-1 flex justify-center px-2 items-center gap-6  text-left bg-white rounded-primeryBRedius shadow-md  group">
        <div className="w-52 shrink">
          <img
            src={props.src}
            alt="Icon_Order"
            className="h-32"
          />
        </div>
        <div className="bg-white text-mainTxtColor p-1 z-10 rounded-primeryBRedius group-hover:text-primeryBgColor ">
          <h1 className="text-4xl font-semibold">{props.count}</h1>
          <p className="text-sm">{props.title}</p>
          <div className="flex gap-2 my-1">
            <p className="text-sm">{props.rating}</p>
          </div>
        </div>
      </div>
    </div>
  );
};
