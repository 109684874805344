import { Statusbar } from "../../../component/StatusBar";
import Icon_Order from "../../../img/Icon_Order.svg";
import Delivered from "../../../img/Delivered.svg";
import Order from "../../../img/Order.svg";
import Group from "../../../img/Group.svg";
import { RoutePath } from "../../../router/RoutePath";
import VisibilityIcon from "@mui/icons-material/Visibility";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { DynamicTable } from "../../../tables/DynamicTable";
import { TitleFilter } from "../../../component/TitileFilter";
import { Link } from "react-router-dom";
import PageName from "../../../component/PageName";

const TotalRevenue = () => {
  // const Head = [
  //   "S.No.",
  //   "Booking ID",
  //   "Date",
  //   "Name",
  //   "Pick up",
  //   "",
  //   "Name",
  //   "Drop",
  //   "Contact",
  //   "Action",
  //   "GSTInvoice",
  //   "Track"

  // ];
  const Head = [
    "S.No.",
    "Booking ID",
    "Date",
    "Pick up",
    "",
    "Drop",
    "Revenue (in Rupees)",
    "Action",
  ];

  const Data = [
    {
      item01: "#70178KT",
      item02: "23/08/2023",
      item03: "3rd-Floor Bhavani ",
      itemto: "to",
      item06: "3rd-Floor Bhavani ",
      item07: "9887655432",
      img01: <VisibilityIcon />,
      img02: <EditIcon />,
      img03: <DeleteIcon />,
    },
  ];

  return (
    <div className="w-full">
      <PageName pageName="Total Revenue" />
      <div className="grid sm:grid-cols-2 lg:grid-cols-4 justify-items-stretch gap-6 p-4 cursor-pointer">
        <Link to={RoutePath.BookingRequest}>
          <Statusbar
            src={Icon_Order}
            count="75"
            title="Booking Requests"
            rating="4% (30 days)"
          />
        </Link>
        <Link to={RoutePath.ActiveDelivery}>
          <Statusbar
            src={Delivered}
            count="357"
            title="Active Deliveries"
            rating="4% (30 days)"
          />
        </Link>
        <Link to={RoutePath.TotalCanceled}>
          <Statusbar
            src={Order}
            count="65"
            title="Total Cancelled"
            rating="25% (30 days)"
          />
        </Link>
        <Link to={RoutePath.TotalRevenue}>
          <Statusbar
            isActive={true}
            src={Group}
            count="Rs 12800"
            title="Total Revenue"
            rating="25% (30 days)"
          />
        </Link>
      </div>
      <div className="flex justify-center gap-2 sm:justify-end items-center px-4">
        <TitleFilter />
      </div>
      <DynamicTable
        tableHeadRow={Head}
        data={Data}
        eyeButton="/bookingviewdetail"
        editButton="/editbookorder"
      />
    </div>
  );
};

export default TotalRevenue;
