import { PrimeButton, BackButton } from "../../component/PrimeButton";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { useFormik } from "formik";
import { getAxiosWithToken } from "../../axios/AxiosObj";
import {
  alertResponseMsgError,
  alertResponseMsgSuccess,
} from "../../function/utilies";
import InputError from "../../component/InputError";
import { useEffect, useState } from "react";

const initialValues = {
  title: "",
  date: "",
  invoiceNo: "",
  details: "",
  amount: "",
  taxAmount: "",
  totalAmount: "",
  invoice: "",
};

const ExpensesForm = () => {
  const navigate = useNavigate();
  const [buttonLoader, setButtonLoader] = useState(false);

  const userCreateSchema = Yup.object({
    title: Yup.string().required("Title should not be empty"),
    date: Yup.string().required("Date should not be empty"),
    invoiceNo: Yup.number().required("invoiceNo Number should not be empty"),
    details: Yup.string().required("Details should not be empty"),
    amount: Yup.number().required("Amount should not be empty"),
    taxAmount: Yup.number().required("Tex Amount should not be empty"),
    totalAmount: Yup.number().required("Total Amount should not be empty"),
    // invoice: Yup.string().required("Upload invoice"),
  });

  const { values, errors, touched, handleChange, handleSubmit } = useFormik({
    initialValues: initialValues,
    validationSchema: userCreateSchema,

    onSubmit: async (values, action) => {
      // setButtonLoader(true);

      let data = new FormData();
      data.append("title", values.title);
      data.append("date", values.date);
      data.append("invoiceNo", values.invoiceNo);
      data.append("details", values.details);
      data.append("amount", values.amount);
      data.append("taxAmount", values.taxAmount);
      data.append("totalAmount", values.totalAmount);
      if (IdDoc) {
        data.append("docs", IdDoc);
      }
      // console.log("data", data);
      // return false;

      try {
        const response = await getAxiosWithToken({
          method: "POST",
          url: "expense/add", // Adjust the API endpoint as needed
          data: data,
          reqType: "formData",
        });
        // API response 200 201
        if (response.data.success) {
          alertResponseMsgSuccess(response.data.msg);
          setButtonLoader(false);
          navigate("/expense");
        } else {
          alertResponseMsgError(response.data.msg);
        }
      } catch (error) {
        setButtonLoader(false);
        console.error(error);
        alertResponseMsgError(error.response.data.msg);
      }
    },
  });
  const [grandTotal, setGrandTotal] = useState(0);
  const amount = values.amount;
  const taxAmount = values.taxAmount;

  
  useEffect(() => {
    if (taxAmount !== null && amount !== null) {
      const totalAmount = Number(taxAmount) + Number(amount);
      setGrandTotal(totalAmount);
    }
  }, [taxAmount, amount]);
  values.totalAmount = `${grandTotal || null}`;
  //----------------------------------------------- upload invoice -------------------------------------------//
  // const [PhotoErr, setPhotoErr] = useState(null);
  const [IdDoc, setIdDoc] = useState(null);
  // const [PhotoErr, setPhotoErr] = useState(null);
  const [invoicePerview, setInvoicePerview] = useState(null);

  const handleChangeFile = (e) => {
    const { name, files } = e.target;
    if (name === "invoiceDoc") {
      let invoice = files[0];
      if (invoice.size > 2000000) {
        alertResponseMsgError("Image Should be less then 2 mb");
        return false;
      }
      if (
        invoice.type.includes("image/jpeg") ||
        invoice.type.includes("image/jpg") ||
        invoice.type.includes("image/png")
      ) {
        const imageUrl = URL.createObjectURL(invoice);
        setInvoicePerview(imageUrl);
        setIdDoc(invoice);
      } else {
        alertResponseMsgError("Image formate Should be jpeg, jpg, png");
      }
    }
  };
  return (
    <div className="px-8 py-5">
      <BackButton btnName="Expenses Details" />
      <form onSubmit={handleSubmit}>
        <div className="md:grid grid-cols-6 gap-4 text-left text-mainTxtColor">
          <div className="col-span-3 gap-6  lg:flex py-2  items-center">
            <label className=" w-36 md:flex-none text-sm font-medium ">
              Date
            </label>
            <div className="w-full">
              <input
                type="date"
                name="date"
                value={"" || values.date}
                onChange={handleChange}
                className="w-full rounded-inputBRedius bg-inputBgColor p-2"
                placeholder="date"
              />
              <InputError error={errors.date} touched={touched.date} />
            </div>
          </div>
          <div className="col-span-3 gap-6 lg:flex py-2 items-center">
            <label
              for="invoiceNo"
              className=" w-36 md:flex-none text-sm font-medium "
            >
              Invoice No.
            </label>
            <div className="w-full">
              <input
                type="text"
                name="invoiceNo"
                value={"" || values.invoiceNo}
                onChange={handleChange}
                className="w-full rounded-inputBRedius bg-inputBgColor p-2"
                placeholder="invoiceNo Number"
              />
              <InputError
                error={errors.invoiceNo}
                touched={touched.invoiceNo}
              />
            </div>
          </div>
          <div className="col-span-6 gap-6 lg:flex py-2 items-center">
            <label className=" w-36 md:flex-none text-sm font-medium">
              Title
            </label>
            <div className="w-full">
              <input
                type="text"
                name="title"
                value={"" || values.title}
                onChange={handleChange}
                className="w-full rounded-inputBRedius bg-inputBgColor p-2 "
                placeholder="Title"
              />
              <InputError error={errors.title} touched={touched.title} />
            </div>
          </div>
          <div className="col-span-3 gap-6  lg:flex py-2  items-center">
            <label className="w-36 md:flex-none text-sm font-medium ">
              Details
            </label>
            <div className="w-full">
              <input
                type="text"
                name="details"
                value={"" || values.details}
                onChange={handleChange}
                className="w-full rounded-inputBRedius bg-inputBgColor p-2"
                placeholder="Details"
              />
              <InputError error={errors.details} touched={touched.details} />
            </div>
          </div>
          <div className="col-span-3 gap-6  lg:flex py-2  items-center">
            <label className=" w-36 md:flex-none text-sm font-medium ">
              Amount
            </label>
            <div className="w-full">
              <input
                type="text"
                name="amount"
                value={"" || values.amount}
                onChange={handleChange}
                className="w-full rounded-inputBRedius bg-inputBgColor p-2"
                placeholder="Amount"
              />
              <InputError error={errors.amount} touched={touched.amount} />
            </div>
          </div>
          <div className="col-span-3 gap-6  lg:flex py-2  items-center">
            <label className=" w-36 md:flex-none text-sm font-medium ">
              Tex Amount
            </label>
            <div className="w-full">
              <input
                type="text"
                name="taxAmount"
                value={"" || values.taxAmount}
                onChange={handleChange}
                className="w-full rounded-inputBRedius bg-inputBgColor p-2"
                placeholder="Tax Amount"
              />
              <InputError
                error={errors.taxAmount}
                touched={touched.taxAmount}
              />
            </div>
          </div>
          <div className="col-span-3 gap-6  lg:flex py-2  items-center">
            <label className=" w-36 md:flex-none text-sm font-medium ">
              Total Amount
            </label>
            <div className="w-full">
              <input
                type="text"
                name="totalAmount"
                value={"" || values.totalAmount}
                onChange={handleChange}
                className="w-full rounded-inputBRedius bg-inputBgColor p-2"
                placeholder="Total Amount"
              />
              <InputError
                error={errors.totalAmount}
                touched={touched.totalAmount}
              />
            </div>
          </div>
          <div className="col-span-3 gap-6  lg:flex py-2  items-center">
            <label className=" w-36 md:flex-none text-sm font-medium ">
              Upload Invoice
            </label>
            <div className="w-full py-2">
              <div className="w-full p-2 bg-white rounded-inputBRedius hover:bg-blue-100">
                <input
                  type="file"
                  name="invoiceDoc"
                  onChange={handleChangeFile}
                  className="w-full file:bg-white
                file:rounded file:border-0
                 file:font-semibold
                file:text-primeryBgColor"
                  placeholder="Invoice"
                  accept="image/png, image/jpeg, image/jpg"
                />
              </div>
            </div>
          </div>
        </div>
        {invoicePerview ? <img src={invoicePerview} alt="dd" /> : null}
        <div className="mt-12">
          <PrimeButton btnName="Sumit" buttonLoader={buttonLoader} />
        </div>
      </form>
    </div>
  );
};

export default ExpensesForm;
