import { toast } from "react-toastify";

// --------------------------
// alertResponseMsgSuccess for success msg
// alertResponseMsgError for error msg
export function alertResponseMsgError(msg) {
  try {
    if (Array.isArray(msg)) {
      msg.forEach((item) => toast.error(item || "Something went wrong...!"));
    } else {
      toast.error(msg || "Something went wrong...!");
    }
  } catch (error) {
    toast.error(msg || "Something went wrong...!");
  }
}

export function alertResponseMsgSuccess(msg) {
  try {
    if (Array.isArray(msg)) {
      msg.forEach((item) => toast.success(item || "Something went wrong...!"));
    } else {
      toast.success(msg || "Something went wrong...!");
    }
  } catch (error) {
    toast.success(msg || "Something went wrong...!");
  }
}

// compare updated values to update them in database
export function valuesCheck(values, values2) {
  const keysToCompare = Object.keys(values);
  const data = {};

  keysToCompare.forEach((key) => {
    if (values[key] !== values2[key]) {
      data[key] = values[key];
    }
  });
  return data;
}

//to clean previous data from cach 
export function valuesEmpty(values, values2) {
  const keysToCompare = Object.keys(values);
  const data = {};

  keysToCompare.forEach((key) => {
    data[key] = values2;
  });
  return data;
}

export function formateDate(value) {
  const date = new Date(value);

  const day = date.getDate();
  const month = date.getMonth() + 1; // Months are zero-based
  const year = date.getFullYear();
  const formattedDay = day < 10 ? `0${day}` : day;
  const formattedMonth = month < 10 ? `0${month}` : month;

  return `${year}-${formattedMonth}-${formattedDay}`;
}

//data formate 
export function formatDateToISO(formattedDate) {
  const parts = formattedDate.split("-");
  const day = parts[0];
  const month = parts[1];
  const year = parts[2];

  // Format: yyyy-MM-dd
  return `${day}-${month}-${year}`;
}

