import * as React from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import CircularProgress from '@mui/material/CircularProgress';

function sleep(duration) {
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve();
    }, duration);
  });
}

const AutoInputField = ({ suggestions, value, onChange,placeholder }) => {
  console.log("suggestions",suggestions)
  const [open, setOpen] = React.useState(false);
  const [options, setOptions] = React.useState([]);
  const [defaultValue, setDefaultValue] = React.useState(null);

  const loading = open && options.length === 0;

  React.useEffect(() => {
    let active = true;

    if (!loading) {
      return undefined;
    }

    (async () => {
      await sleep(1e3); // Simulate async fetch.

      if (active) {
        setOptions([...suggestions]);
      }
    })();

    return () => {
      active = false;
    };
  }, [loading, suggestions]);

  React.useEffect(() => {
    if (!open) {
      setOptions([]);
    }
  }, [open]);

  React.useEffect(() => {
    if (value) {
      const selectedStation = suggestions.find((station) => station.stationId === value);
      if (selectedStation) {
        setDefaultValue(selectedStation);
      }
    }
  }, [value, suggestions]);

  const handleOnChange = (event, newValue) => {
    if (newValue) {
      onChange(newValue.stationId);
    } else {
      onChange(null);
    }
  };

  return (
    <div className='w-full'>
      <Autocomplete
        id="asynchronous-demo"
        open={open}
        onOpen={() => {
          setOpen(true);
        }}
        onClose={() => {
          setOpen(false);
        }}
        sx={{
          '& .MuiOutlinedInput-root': {
            bgcolor: 'white',
            border: 'none',
            '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
              border: 'none',
            },
            '&:hover .MuiOutlinedInput-notchedOutline': {
              border: 'none',
            },
            '& .MuiOutlinedInput-input': {
              color: (theme) =>
                theme.palette.getContrastText(theme.palette.background.paper),
            },
          },
        }}
        isOptionEqualToValue={(option, value) => option.stationId === value.stationId}
        getOptionLabel={(option) => option.stationName}
        defaultValue={defaultValue}
        options={options}
        loading={loading}
        onChange={handleOnChange}
        renderInput={(params) => (
          <TextField
            {...params}
            placeholder={placeholder}
            size="small"
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <React.Fragment>
                  {loading ? <CircularProgress color="inherit" size={20} /> : null}
                  {params.InputProps.endAdornment}
                </React.Fragment>
              ),
            }}
          />
        )}
      />
    </div>
  );
};

export default AutoInputField;
