import React from "react";
import { RoutePath } from "../../../router/RoutePath";
import { AddFilterButton, TitleFilter } from "../../../component/TitileFilter";
import VisibilityIcon from "@mui/icons-material/Visibility";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { useState, useEffect } from "react";
import { Statusbar } from "../../../component/StatusBar";
import customer from "../../../img/customer.svg";
import totaldriver from "../../../img/totaldriver.svg";
import { DynamicTable } from "../../../tables/DynamicTable";
import { getAxiosWithToken } from "../../../axios/AxiosObj";
import { Link } from "react-router-dom";
import PageName from "../../../component/PageName";


const Admin = () => {
  const tableHead = [
    "S.No",
    "Admin ID",
    "Name",
    "Contact",
    "Action",
  ];

  const [dataLoading, setDataLoading] = useState(false);
  const [adminCount, setAdminCount] = useState(0);
  const [allAdminRaw, setallAdminRaw] = useState([]);

  const [allCustomer, setAllCustomer] = useState([]);

  const ManageData = async () => {
    // setDataLoading(true)
    const response = await getAxiosWithToken({
      method: "get",
      url: "sub/admin/all",
    });
    if (response && response.data && response.data.data) {
      setallAdminRaw(response.data.data);
      setAdminCount(response.data.count || 0)
    }
    // console.log(response);
  };

  useEffect(() => {
    ManageData();
  }, [dataLoading]);

  useEffect(() => {
    const modifiedData = allAdminRaw.map((admin) => {
      return {
        item01: admin.username,
        item02: admin.firstName? `${admin.firstName} ${admin.middleName} ${admin.lastName}`
        : `${admin.firstName} ${admin.lastName}`,
        item03: admin.mobile,
        img01: <VisibilityIcon />,
        img02: <EditIcon />,
        img03: <DeleteIcon/>,
        unqiueKey: admin.username,
      };
    });
    setAllCustomer(modifiedData);
  }, [allAdminRaw]);


  return (
    <div>
      <div>
      <PageName pageName="Manage Admin" href={RoutePath.AddAdmin} />
        <div className="grid sm:grid-cols-3  justify-items-stretch gap-6 p-4 cursor-pointer">
        <div >
        <Link to={RoutePath.Staff} >
          <Statusbar
            src={customer}
            count={"Staff" || 0 }
            title="Total Staff"
            rating="4% (30 days)"
          />
          </Link>
        </div>
        <div >
        <Link to={RoutePath.Admin} >
            <Statusbar
            isActive={true}
              src={totaldriver}
              count={adminCount || 0 }
              title="Sub Admin"
              rating="4% (30 days)"
            />
            </Link>
          </div>
          <div >
          <Link to={RoutePath.SuperAdmin} >
            <Statusbar
              src={customer}
              count={"Admin" || 0 }
              title="Super Admin"
              rating="4% (30 days)"
            />
            </Link>
          </div>
          </div>
        <div>
          {/* <div className="flex justify-center gap-2 sm:justify-end items-center px-4">
            <AddFilterButton href={RoutePath.AddAdmin} />
            <TitleFilter />
          </div> */}
          <DynamicTable
            tableHeadRow={tableHead}
            data={allCustomer}
            DeleteUrl={"sub/admin/delete"}
            eyeButton={RoutePath.EditAdminDetailLink}
            editButton={RoutePath.EditAdminDetailLink}
          />
        </div>
      </div>
    </div>
  );
};

export default Admin;
