import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { RoutePath } from "../router/RoutePath";
import OutboxOutlinedIcon from "@mui/icons-material/OutboxOutlined";
import MenuIcon from "@mui/icons-material/Menu";
import CloseIcon from "@mui/icons-material/Close";
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import TimelineIcon from "@mui/icons-material/Timeline";
import CurrencyExchangeIcon from "@mui/icons-material/CurrencyExchange";
import PeopleOutlineIcon from "@mui/icons-material/PeopleOutline";
import EditNoteOutlinedIcon from "@mui/icons-material/EditNoteOutlined";
import FileOpenOutlinedIcon from "@mui/icons-material/FileOpenOutlined";
import LocalShippingOutlinedIcon from "@mui/icons-material/LocalShippingOutlined";
import GroupsOutlinedIcon from "@mui/icons-material/GroupsOutlined";
import AirlineSeatReclineExtraIcon from "@mui/icons-material/AirlineSeatReclineExtra";
import ContactPageOutlinedIcon from "@mui/icons-material/ContactPageOutlined";
import AssignmentOutlinedIcon from "@mui/icons-material/AssignmentOutlined";
import FilePresentOutlinedIcon from "@mui/icons-material/FilePresentOutlined";
import MyLocationOutlinedIcon from "@mui/icons-material/MyLocationOutlined";
import DomainAddOutlinedIcon from '@mui/icons-material/DomainAddOutlined';
import { useSelector } from "react-redux";

const Sidebar = () => {
  const { isLogged, role } = useSelector((state) => state.user);

  const [isOpen, setIsOpen] = useState(false);
  const openSidebar = () => {
    setIsOpen((prevIsOpen) => !prevIsOpen);
  };
  const [activeType, setActiveType] = useState(null);

  const isActive = (type) => {
    return type === activeType;
  };
  const handleLinkClick = (pathname) => {
    let pageName = pathname;
    pageName = pageName.split("/");
    // pageName = pageName[pageName.length -1]
    if (pageName[1]) {
      pageName = pageName[1];
    } else {
      pageName = "/";
    }
    console.log(pageName);
    setActiveType(pageName);
  };

  useEffect(() => {
    handleLinkClick(window.location.pathname);
  }, [window.location.pathname]);

  return (
    <div className=" ">
      <div
        onClick={openSidebar}
        className={`w-72 h-screen transition-transform ${
          isOpen
            ? "translate-x-0 lg:shadow-none shadow-lg"
            : "-translate-x-full transition-transform duration-300 delay-300 "
        } lg:translate-x-0 lg:block bg-white pl-5 pt-5 pr-5 fixed top-0 z-30  `}
      >
        {/* overflow-auto scroll-my-0 */}
        <div className="relative text-mainTxtColor">
          <div className="absolute -right-[78px] -top-4 py-2 pl-4 pr-2 bg-white rounded-r-full lg:hidden">
            <button className=" p-1 border-2 rounded-full hover:bg-white z-50 ">
              {isOpen ? <CloseIcon /> : <MenuIcon />}
            </button>
          </div>
          {role === 1 || role === 2 ? (
            <Link to="/">
              <h1 className="text-6xl font-bold p-1  text-primeryBgColor">
                BPS
              </h1>
            </Link>
          ) : null}
          {/* {role === 2 ?( <Link to="/">
            <h1 className="text-7xl font-bold p-2  text-primeryBgColor">BPS</h1>
          </Link>) :(null)} */}

          <Link
            to="/"
            className={`flex justify-start gap-4 p-2 hover:bg-blue-200 hover:text-primeryBgColor hover:font-semibold hover:border-l-4 border-primeryBgColor rounded-r-lg  ${
              isActive("/")
                ? "bg-backgroundColor p-1 rounded-l-lg font-semibold text-primeryBgColor border-l-4 border-primeryBgColor"
                : ""
            }`}
          >
            <HomeOutlinedIcon />
            <h1 className="">Dashboard</h1>
          </Link>
          <Link
            to={RoutePath.BookingRequest}
            className={`flex justify-start  gap-4 p-2 hover:bg-blue-200 hover:text-primeryBgColor hover:font-semibold hover:border-l-4 border-primeryBgColor rounded-r-lg  ${
              isActive("booking")
                ? "bg-backgroundColor  p-1 rounded-l-lg font-semibold text-primeryBgColor border-l-4 border-primeryBgColor "
                : ""
            }`}
          >
            <FileOpenOutlinedIcon />
            <h1 className="">Manage Booking</h1>
          </Link>
          <Link
            to={RoutePath.AssignDelivery}
            className={`flex justify-start gap-4 p-2 hover:bg-blue-200 hover:text-primeryBgColor hover:font-semibold hover:border-l-4 border-primeryBgColor rounded-r-lg  ${
              isActive("delivery")
                ? "bg-backgroundColor  p-1 rounded-l-lg font-semibold text-primeryBgColor border-l-4 border-primeryBgColor "
                : ""
            }`}
          >
            <OutboxOutlinedIcon />
            <h1 className="">Manage Delivery</h1>
          </Link>
          {role === 1 ? (
             <Link
             to={RoutePath.Vehicle}
             className={`flex justify-start gap-4 p-2 hover:bg-blue-200 hover:text-primeryBgColor hover:font-semibold hover:border-l-4 border-primeryBgColor rounded-r-lg  ${
               isActive("vehicle")
                 ? "bg-backgroundColor p-1 rounded-l-lg font-semibold text-primeryBgColor border-l-4 border-primeryBgColor"
                 : ""
             }`}
           >
             <LocalShippingOutlinedIcon />
             <h1 className="">Manage Vehicle</h1>
           </Link>
          ) : null}
          {role === 1 ? (
              <Link
              to={RoutePath.Driver}
              className={`flex justify-start gap-4 p-2 hover:bg-blue-200 hover:text-primeryBgColor hover:font-semibold hover:border-l-4 border-primeryBgColor rounded-r-lg  ${
                isActive("driver")
                  ? "bg-backgroundColor p-1 rounded-l-lg font-semibold text-primeryBgColor border-l-4 border-primeryBgColor"
                  : ""
              }`}
            >
              <AirlineSeatReclineExtraIcon />
              <h1 className="">Manage Driver</h1>
            </Link>
          ) : null}
         
          <Link
            to={RoutePath.TrackerMap}
            className={`flex justify-start gap-4 p-2 hover:bg-blue-200 hover:text-primeryBgColor hover:font-semibold hover:border-l-4 border-primeryBgColor rounded-r-lg  ${
              isActive("tracker")
                ? "bg-backgroundColor p-1 rounded-l-lg font-semibold text-primeryBgColor border-l-4 border-primeryBgColor"
                : ""
            }`}
          >
            <MyLocationOutlinedIcon />
            <h1 className="">Tracker</h1>
          </Link>
          <Link
            to={RoutePath.ManageCustomer}
            className={`flex justify-start gap-4 p-2 hover:bg-blue-200 hover:text-primeryBgColor hover:font-semibold hover:border-l-4 border-primeryBgColor rounded-r-lg  ${
              isActive("customer")
                ? "bg-backgroundColor p-1 rounded-l-lg font-semibold text-primeryBgColor border-l-4 border-primeryBgColor"
                : ""
            }`}
          >
            <GroupsOutlinedIcon />
            <h1 className="">Manage Customer</h1>
          </Link>
          <Link
            to={RoutePath.CustomerQuotation}
            className={`flex justify-start gap-4 p-2 hover:bg-blue-200 hover:text-primeryBgColor hover:font-semibold hover:border-l-4 border-primeryBgColor rounded-r-lg  ${
              isActive("quotation")
                ? "bg-backgroundColor p-1 rounded-l-lg font-semibold text-primeryBgColor border-l-4 border-primeryBgColor"
                : ""
            }`}
          >
            <CurrencyExchangeIcon />
            <h1 className="">Customer Quotation</h1>
          </Link>
          <Link
            to={RoutePath.CustomerLedger}
            onClick={() => handleLinkClick("Ledger")}
            className={`flex justify-start gap-4 p-2 hover:bg-blue-200 hover:text-primeryBgColor hover:font-semibold hover:border-l-4 border-primeryBgColor rounded-r-lg  ${
              isActive("ledger")
                ? "bg-backgroundColor p-1 rounded-l-lg font-semibold text-primeryBgColor border-l-4 border-primeryBgColor"
                : ""
            }`}
          >
            <EditNoteOutlinedIcon />
            <h1 className="">Customer Ledger</h1>
          </Link>
          <Link
            to={RoutePath.CustomerInvoice}
            className={`flex justify-start gap-4 p-2 hover:bg-blue-200 hover:text-primeryBgColor hover:font-semibold hover:border-l-4 border-primeryBgColor rounded-r-lg  ${
              isActive("customerinvoice")
                ? "bg-backgroundColor p-1 rounded-l-lg font-semibold text-primeryBgColor border-l-4 border-primeryBgColor"
                : ""
            }`}
          >
            <FilePresentOutlinedIcon />
            <h1 className="">Ledger History</h1>
          </Link>
          {role === 1 ? (
            <Link
              to={RoutePath.Staff}
              className={`flex justify-start gap-4 p-2 hover:bg-blue-200 hover:text-primeryBgColor hover:font-semibold hover:border-l-4 border-primeryBgColor rounded-r-lg  ${
                isActive("staff")
                  ? "bg-backgroundColor p-1 rounded-l-lg font-semibold text-primeryBgColor border-l-4 border-primeryBgColor"
                  : ""
              }`}
            >
              <PeopleOutlineIcon />
              <h1 className="">Manage User</h1>
            </Link>
          ) : null}
          {role === 1  ? (
            <Link
              to={RoutePath.Station}
              className={`flex justify-start gap-4 p-2 hover:bg-blue-200 hover:text-primeryBgColor hover:font-semibold hover:border-l-4 border-primeryBgColor rounded-r-lg  ${
                isActive("station")
                  ? "bg-backgroundColor p-1 rounded-l-lg font-semibold text-primeryBgColor border-l-4 border-primeryBgColor"
                  : ""
              }`}
            >
              <DomainAddOutlinedIcon />
              <h1 className="">Manage Station</h1>
            </Link>
          ) : null}

          <Link
            to={RoutePath.ManageExpense}
            className={`flex justify-start gap-4 p-2 hover:bg-blue-200 hover:text-primeryBgColor hover:font-semibold hover:border-l-4 border-primeryBgColor rounded-r-lg  ${
              isActive("expense")
                ? "bg-backgroundColor p-1 rounded-l-lg font-semibold text-primeryBgColor border-l-4 border-primeryBgColor"
                : ""
            }`}
          >
            <AssignmentOutlinedIcon />
            <h1 className="">Manage Expenses</h1>
          </Link>
          <Link
            to={RoutePath.ContactList}
            className={`flex justify-start gap-4 p-2 hover:bg-blue-200 hover:text-primeryBgColor hover:font-semibold hover:border-l-4 border-primeryBgColor rounded-r-lg  ${
              isActive("contact")
                ? "bg-backgroundColor p-1 rounded-l-lg font-semibold text-primeryBgColor border-l-4 border-primeryBgColor"
                : ""
            }`}
          >
            <ContactPageOutlinedIcon />
            <h1 className="">Contact List</h1>
          </Link>

          <Link
            to={RoutePath.Report}
            className={`flex justify-start gap-4 p-2 hover:bg-blue-200 hover:text-primeryBgColor hover:font-semibold hover:border-l-4 border-primeryBgColor rounded-r-lg  ${
              isActive("report")
                ? "bg-backgroundColor p-1 rounded-l-lg font-semibold text-primeryBgColor border-l-4 border-primeryBgColor"
                : ""
            }`}
          >
            <TimelineIcon />
            <h1 className="">Report</h1>
          </Link>
        </div>
        {/* <div className=" rounded-primeryBRedius my-4 text-sm bg-primeryBgColor p-5 text-left">
          <p className="text-white mb-6">
            Organize your <br /> bookings through button <br /> bellow!
          </p>
          <Link
            to={RoutePath.BookOrderForm}
            className="bg-white p-2 rounded-primeryBRedius font-semibold my-2 "
          >
            +Add Booking
          </Link>
        </div> */}
      </div>
    </div>
  );
};

export default Sidebar;
