import React from "react";
import { useSelector } from "react-redux";
import { RoutePath } from "../../router/RoutePath";
import VisibilityIcon from "@mui/icons-material/Visibility";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { useState, useEffect } from "react";
import { Statusbar } from "../../component/StatusBar";
import customer from "../../img/customer.svg";
import { DynamicTable } from "../../tables/DynamicTable";
import PageName from "../../component/PageName";

const ManageCustomer = () => {

  const {customerDeatils} = useSelector((state) => state.user);

  const tableHead = ["S. No", "Customer ID", "Name", "Contact", "Action"];

  const [dataLoading, setDataLoading] = useState(false);
  const [customerCount, setCustomerCount] = useState(0);
  const [allCustomerRaw, setAllCustomerRaw] = useState([]);

  const [allCustomer, setAllCustomer] = useState([]);

  useEffect(() => {
    if (customerDeatils !== null && customerDeatils.data) {
      setCustomerCount(customerDeatils.count);
      setAllCustomerRaw(customerDeatils.data);

    }
  }, [customerDeatils]);
  useEffect(() => {
    if (allCustomerRaw.length <= 0) {
      setDataLoading(true);
    } else {
      setDataLoading(false);
    }
  }, [allCustomerRaw]);

  useEffect(() => {
    const modifiedData = allCustomerRaw.map((customer) => {
      return {
        item01: customer.username,
        item02: customer.firstName
          ? `${customer.firstName} ${customer.middleName} ${customer.lastName}`
          : `${customer.firstName} ${customer.lastName}`,
        item03: customer.mobile,
        img01: <VisibilityIcon />,
        img02: <EditIcon />,
        img03: <DeleteIcon />,
        unqiueKey: customer.username,
      };
    });
    // console.log("address",customer.address1);
    setAllCustomer(modifiedData);
  }, [allCustomerRaw]);

  return (
    <div>
      <div>
        <PageName pageName="Manage Customer" href={RoutePath.AddCustomerForm} />
        <div className="p-4">
          <Statusbar
            isActive={true}
            src={customer}
            count={customerCount || 0}
            title="Total Customer"
            rating="4% (30 days)"
          />
        </div>
        <div>
          {/* <div className="flex justify-center gap-2 sm:justify-end items-center px-4">
          <input type="search" className=" rounded-inputBRedius bg-inputBgColor p-2 " />
            <AddFilterButton href={RoutePath.AddCustomerForm} />
            <TitleFilter />
          </div> */}
          <DynamicTable
            tableHeadRow={tableHead}
            data={allCustomer}
            DeleteUrl={"customer/delete"}
            eyeButton={RoutePath.EditCustomerDetailLink}
            editButton={RoutePath.EditCustomerDetailLink}
            isDataLoading={dataLoading}
          />
        </div>
      </div>
    </div>
  );
};

export default ManageCustomer;
