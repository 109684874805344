import React, { useEffect, useState, useRef } from "react";
import { useParams } from "react-router-dom";
import { PrimeButton, BackButton } from "../../../component/PrimeButton";
import * as Yup from "yup";
import { useFormik } from "formik";
import { getAxiosWithToken } from "../../../axios/AxiosObj";
import {
  alertResponseMsgError,
  alertResponseMsgSuccess,
  formateDate,
  formatDateToISO,
  valuesEmpty,
  valuesCheck,
} from "../../../function/utilies";
import InputError from "../../../component/InputError";

const initialValues = {
  registrationNo: "",
  registrationDate: "",
  regExpiryDate: "",
  vehicleModel: "",
  yearofManufacture: "",
  ownedBy: "",
  currentLocation: "",
  dateofPurchase: "",
  purchasedFrom: "",
  PurchasedUnder: "",
  purchasePrice: " ",
  depreciation: "",
  currentValue: "",
  currentInsuranceProvider: "",
  policyNumber: "",
  policyType: "",
  policyStartDate: "",
  policyEndDate: "",
  policyPremium: "",
  lastFitnessRenewalDate: "",
  currentFitnessValidUpto: "",
  firstRegValidUpto: "",
  renewalDate: "",
  renewalValidUpto: "",
  addcomment: "",
};

const EditVehicleDetail = () => {
  const params = useParams();
  const [buttonLoader, setButtonLoader] = useState(false);
  const [allRawData, setallRawData] = useState();

  const userCreateSchema = Yup.object({
    registrationDate: Yup.string().required(
      "Registration Date should not be empty"
    ),
    regExpiryDate: Yup.string().required(
      "Reg. Expiry Date Station should not be empty"
    ),
    vehicleModel: Yup.string().required("Make/Model should not be empty"),
    registrationNo: Yup.number().required(
      "Registration Number should not be empty"
    ),
    yearofManufacture: Yup.string().required(
      "Year of Manufacture should not be empty"
    ),
    ownedBy: Yup.string().required("Owned By should not be empty"),
    currentLocation: Yup.string().required(
      "Current Location should not be empty"
    ),
    dateofPurchase: Yup.string().required(
      "Date of Purchase should not be empty"
    ),
    purchasedFrom: Yup.string().required("Sender Name should not be empty"),
    PurchasedUnder: Yup.string().required(
      "Purchased under should not be empty"
    ),
    purchasePrice: Yup.number().required("Purchase Price should not be empty"),
    depreciation: Yup.string().required("Depreciation should not be empty"),
    currentValue: Yup.number().required("Current Value should not be empty"),
    currentInsuranceProvider: Yup.string().required(
      "Current Insurance Provider should not be empty"
    ),
    policyNumber: Yup.number().required("Policy No.*"),
    policyType: Yup.string().required("Policy Type*"),
    policyStartDate: Yup.string().required("Policy Start Date No.*"),
    policyEndDate: Yup.string().required("Policy End Date No.*"),
    policyPremium: Yup.string().required("Policy Premium*"),
    lastFitnessRenewalDate: Yup.string().required("Last Fitness Renewal Date*"),
    currentFitnessValidUpto: Yup.string().required(
      "Current Fitness Valid Upto*"
    ),
    firstRegValidUpto: Yup.string().required("First Reg. Valid Upto*"),
    renewalDate: Yup.string().required("Renewal Date*"),
    renewalValidUpto: Yup.string().required("Renewal Valid Upto*"),
  });

  const { values, errors, touched, handleChange, handleSubmit } = useFormik({
    initialValues: initialValues,
    validationSchema: userCreateSchema,

    onSubmit: async (values, action) => {
      // setButtonLoader(true);
      let data = await valuesCheck(values, allRawData);

      // let data = {};
      // if (values.registrationNo !== allRawData.registrationNo) {
      //   data.registrationNo = values.registrationNo;
      // }
      // if (values.registrationDate !== allRawData.registrationVaildDate) {
      //   data.registrationVaildDate = values.registrationDate;
      // }
      // if (values.vehicleModel !== allRawData.vehicleModelId) {
      //   data.vehicleModelId = values.vehicleModel;
      // }
      // if (values.regExpiryDate !== allRawData.registrationExpDate) {
      //   data.registrationExpDate = values.regExpiryDate;
      // }
      // if (values.yearofManufacture !== allRawData.manufactureYear) {
      //   data.manufactureYear = values.yearofManufacture;
      // }
      // if (values.ownedBy !== allRawData.ownerName) {
      //   data.ownerName = values.ownedBy;
      // }
      // if (values.currentLocation !== allRawData.location) {
      //   data.location = values.currentLocation;
      // }
      // if (values.dateofPurchase !== allRawData.purchaseDate) {
      //   data.purchaseDate = values.dateofPurchase;
      // }
      // if (values.purchasedFrom !== allRawData.purchaseFrom) {
      //   data.purchaseFrom = values.purchasedFrom;
      // }
      // if (values.PurchasedUnder !== allRawData.purchaseUnder) {
      //   data.purchaseUnder = values.PurchasedUnder;
      // }
      // if (values.purchasePrice !== allRawData.purchasePrice) {
      //   data.purchasePrice = values.purchasePrice;
      // }
      // if (values.depreciation !== allRawData.depreciation) {
      //   data.depreciation = values.depreciation;
      // }
      // if (values.currentValue !== allRawData.currentValue) {
      //   data.currentValue = values.currentValue;
      // }
      // if (values.currentInsuranceProvider !== allRawData.insuranceProvider) {
      //   data.insuranceProvider = values.currentInsuranceProvider;
      // }
      // if (values.policyNumber !== allRawData.policyNumber) {
      //   data.policyNumber = values.policyNumber;
      // }
      // if (values.policyType !== allRawData.policyType) {
      //   data.policyType = values.policyType;
      // }
      // if (values.currentInsuranceProvider !== allRawData.registrationNo) {
      //   data.registrationNo = values.currentInsuranceProvider;
      // }
      // if (values.policyStartDate !== allRawData.policyStartDate) {
      //   data.policyStartDate = values.policyStartDate;
      // }
      // if (values.policyEndDate !== allRawData.policyEndDate) {
      //   data.policyEndDate = values.policyEndDate;
      // }
      // if (values.policyPremium !== allRawData.policypremium) {
      //   data.policypremium = values.policyPremium;
      // }
      // if (values.lastFitnessRenewalDate !== allRawData.lastFitnessDate) {
      //   data.lastFitnessDate = values.lastFitnessRenewalDate;
      // }
      // if (values.currentFitnessValidUpto !== allRawData.currentFitnessDate) {
      //   data.currentFitnessDate = values.currentFitnessValidUpto;
      // }
      // if (values.firstRegValidUpto !== allRawData.firstRegVaildUpto) {
      //   data.firstRegVaildUpto = values.firstRegValidUpto;
      // }
      // if (values.renewalDate !== allRawData.registrationNo) {
      //   data.renewalDate = values.renewalDate;
      // }
      // if (values.renewalValidUpto !== allRawData.renewalVaildUpto) {
      //   data.renewalVaildUpto = values.renewalValidUpto;
      // }
      // if (values.addcomment !== allRawData.additionalCmt) {
      //   data.additionalCmt = values.addcomment;
      // }
      // console.log("datasdsd",data);
      // return false

      let vehicleId;
      if (params && params.vehicleId && params.vehicleId !== " ") {
        vehicleId = params.vehicleId;
      }
      try {
        const response = await getAxiosWithToken({
          method: "Patch",
          url: `vehicle/update/${vehicleId}`, // Adjust the API endpoint as needed
          data: data,
        });
        // API response 200 201
        // console.log(response);
        if (response.success) {
          alertResponseMsgSuccess(response.msg);
          setButtonLoader(false);
        } else {
          alertResponseMsgError(response.msg);
          setButtonLoader(false);
        }
      } catch (error) {
        console.error(error);
        alertResponseMsgError(error.response.data.msg);
        setButtonLoader(false);
      }
    },
  });
  const getDeatils = async (vehicleId) => {
    try {
      const response = await getAxiosWithToken({
        method: "get",
        url: `vehicle/details/${vehicleId}`, // Adjust the API endpoint as needed
      });
      console.log(response);
      if (response && response.data && response.data.data) {
        // values.fullName = response.data.data.firstName;
        setallRawData(response.data.data);
      }
    } catch (e) {
      console.log(e);
    }
  };
  useEffect(() => {
    if (allRawData) {
      // console.log(allRawData);
      values.registrationNo = allRawData.registrationNo;
      values.registrationDate = formatDateToISO(
        formateDate(allRawData.registrationVaildDate)
      );
      values.vehicleModel = allRawData.vehicleModel;
      values.regExpiryDate = formatDateToISO(
        formateDate(allRawData.registrationExpDate)
      );
      values.yearofManufacture = formatDateToISO(
        formateDate(allRawData.manufactureYear)
      );
      values.ownedBy = allRawData.ownerName;
      values.currentLocation = allRawData.location;
      values.dateofPurchase = formatDateToISO(
        formateDate(allRawData.purchaseDate)
      );
      values.purchasedFrom = allRawData.purchaseFrom;
      values.PurchasedUnder = allRawData.purchaseUnder;
      values.purchasePrice = allRawData.purchasePrice;
      values.depreciation = allRawData.depreciation;
      values.currentValue = allRawData.currentValue;
      values.currentInsuranceProvider = allRawData.insuranceProvider;
      values.policyNumber = allRawData.policyNumber;
      values.policyType = allRawData.policyType;
      values.policyStartDate = formatDateToISO(
        formateDate(allRawData.policyStartDate)
      );
      values.policyEndDate = formatDateToISO(
        formateDate(allRawData.policyEndDate)
      );
      values.policyPremium = allRawData.policypremium;
      values.lastFitnessRenewalDate = formatDateToISO(
        formateDate(allRawData.lastFitnessDate)
      );
      values.currentFitnessValidUpto = formatDateToISO(
        formateDate(allRawData.currentFitnessDate)
      );
      values.firstRegValidUpto = formatDateToISO(
        formateDate(allRawData.firstRegVaildUpto)
      );
      values.renewalDate = formatDateToISO(formateDate(allRawData.renewalDate));
      values.renewalValidUpto = formatDateToISO(
        formateDate(allRawData.renewalVaildUpto)
      );
      values.addcomment = allRawData.additionalCmt;
    }
  }, [allRawData]);

  //to clean priveous data from cach
  const valueRef = useRef(values);
  useEffect(() => {
    if (params && params.vehicleId && params.vehicleId !== " ") {
      valueRef.current = valuesEmpty(values, "");
      getDeatils(params.vehicleId);
    } else {
      window.location.href = "/vehicle";
    }
  }, [params]);

  return (
    <div>
      <div className="bg-backgroundColor rounded-xl p-8">
        <BackButton btnName="Edit Vehicle Details" />
        <form onSubmit={handleSubmit}>
          <div className="md:grid grid-cols-6 gap-4 text-left text-mainTxtColor">
            <div className="div col-span-6 text-lg font-semibold  ">
              Vehicle Details
            </div>
            <div className="col-span-6 gap-6 mb-5 sm:mb-3  lg:flex  items-center">
              <label className="md:flex-none text-sm font-medium ">
                Registration Number
              </label>
              <div className="w-full">
                <input
                  type="text"
                  name="registrationNo"
                  value={"" || values.registrationNo}
                  onChange={handleChange}
                  className="w-full rounded-inputBRedius bg-inputBgColor p-2"
                  placeholder="Registration Number"
                />
                <InputError
                  error={errors.registrationNo}
                  touched={touched.registrationNo}
                />
              </div>
            </div>
            <div className="col-span-3 gap-6 mb-5 sm:mb-3  lg:flex  items-center">
              <label className=" w-32 md:flex-none text-sm font-medium ">
                Registration Date
              </label>
              <div className="w-full">
                <input
                  type="date"
                  name="registrationDate"
                  value={"" || values.registrationDate}
                  onChange={handleChange}
                  className="w-full rounded-inputBRedius bg-inputBgColor p-2"
                  placeholder="Reg. Expiry Date"
                />
                <InputError
                  error={errors.registrationDate}
                  touched={touched.registrationDate}
                />
              </div>
            </div>
            <div className="col-span-3 gap-6 mb-5 sm:mb-3  lg:flex  items-center">
              <label className=" w-32 md:flex-none text-sm font-medium ">
                Reg. Expiry Date
              </label>
              <div className="w-full">
                <input
                  type="date"
                  name="regExpiryDate"
                  value={"" || values.regExpiryDate}
                  onChange={handleChange}
                  className="w-full rounded-inputBRedius bg-inputBgColor p-2"
                  placeholder="Reg. Expiry Date"
                />
                <InputError
                  error={errors.regExpiryDate}
                  touched={touched.regExpiryDate}
                />
              </div>
            </div>
            <div className="col-span-3 gap-6 mb-5 sm:mb-3  lg:flex  items-center">
              <label className=" w-32 md:flex-none text-sm font-medium ">
                Make/Model
              </label>
              <div className="w-full">
                <input
                  type="text"
                  name="vehicleModel"
                  value={"" || values.vehicleModel}
                  onChange={handleChange}
                  className="w-full rounded-inputBRedius bg-inputBgColor p-2"
                  placeholder="Make/Model"
                />
                <InputError
                  error={errors.vehicleModel}
                  touched={touched.vehicleModel}
                />
              </div>
            </div>
            <div className="col-span-3 gap-6 mb-5 sm:mb-3  lg:flex  items-center">
              <label className=" w-32 md:flex-none text-sm font-medium ">
                Year of Manufacture
              </label>
              <div className="w-full">
                <input
                  type="date"
                  name="yearofManufacture"
                  value={"" || values.yearofManufacture}
                  onChange={handleChange}
                  className="w-full rounded-inputBRedius bg-inputBgColor p-2"
                  placeholder="Year of Manufacture"
                />
                <InputError
                  error={errors.yearofManufacture}
                  touched={touched.yearofManufacture}
                />
              </div>
            </div>
            <div className="col-span-3 gap-6 mb-5 sm:mb-3  lg:flex  items-center">
              <label className=" w-32 md:flex-none text-sm font-medium ">
                Owned By
              </label>
              <div className="w-full">
                <input
                  type="text"
                  name="ownedBy"
                  value={"" || values.ownedBy}
                  onChange={handleChange}
                  className="w-full rounded-inputBRedius bg-inputBgColor p-2"
                  placeholder="Owned By"
                />
                <InputError error={errors.ownedBy} touched={touched.ownedBy} />
              </div>
            </div>
            <div className="col-span-3 gap-6 mb-5 sm:mb-3  lg:flex  items-center">
              <label className=" w-32 md:flex-none text-sm font-medium ">
                Current Location
              </label>
              <div className="w-full">
                <input
                  type="text"
                  name="currentLocation"
                  value={"" || values.currentLocation}
                  onChange={handleChange}
                  className="w-full rounded-inputBRedius bg-inputBgColor p-2"
                  placeholder="Current Location"
                />
                <InputError
                  error={errors.currentLocation}
                  touched={touched.currentLocation}
                />
              </div>
            </div>
            <div className="col-span-2 gap-6 mb-5 sm:mb-3  lg:flex  items-center">
              <label className=" w-32 md:flex-none text-sm font-medium ">
                Date of Purchase
              </label>
              <div className="w-full">
                <input
                  type="date"
                  name="dateofPurchase"
                  value={"" || values.dateofPurchase}
                  onChange={handleChange}
                  className="w-full rounded-inputBRedius bg-inputBgColor p-2"
                  placeholder="Date of Purchase"
                />
                <InputError
                  error={errors.dateofPurchase}
                  touched={touched.dateofPurchase}
                />
              </div>
            </div>
            <div className="col-span-2 gap-6 mb-5 sm:mb-3  lg:flex  items-center">
              <label className=" w-32 md:flex-none text-sm font-medium ">
                Purchased from
              </label>
              <div className="w-full">
                <input
                  type="text"
                  name="purchasedFrom"
                  value={"" || values.purchasedFrom}
                  onChange={handleChange}
                  className="w-full rounded-inputBRedius bg-inputBgColor p-2"
                  placeholder="Purchased from"
                />
                <InputError
                  error={errors.purchasedFrom}
                  touched={touched.purchasedFrom}
                />
              </div>
            </div>
            <div className="col-span-2 gap-6 mb-5 sm:mb-3  lg:flex  items-center">
              <label className=" w-32 md:flex-none text-sm font-medium ">
                Purchased under
              </label>
              <div className="w-full">
                <input
                  type="text"
                  name="PurchasedUnder"
                  value={"" || values.PurchasedUnder}
                  onChange={handleChange}
                  className="w-full rounded-inputBRedius bg-inputBgColor p-2"
                  placeholder="Purchased under"
                />
                <InputError
                  error={errors.PurchasedUnder}
                  touched={touched.PurchasedUnder}
                />
              </div>
            </div>
            <div className="col-span-2 gap-6 mb-5 sm:mb-3  lg:flex  items-center">
              <label className=" w-32 md:flex-none text-sm font-medium ">
                Purchase Price (in INR)
              </label>
              <div className="w-full">
                <input
                  type="text"
                  name="purchasePrice"
                  value={"" || values.purchasePrice}
                  onChange={handleChange}
                  className="w-full rounded-inputBRedius bg-inputBgColor p-2"
                  placeholder="Purchase Price"
                />
                <InputError
                  error={errors.purchasePrice}
                  touched={touched.purchasePrice}
                />
              </div>
            </div>
            <div className="col-span-2 gap-6 mb-5 sm:mb-3  lg:flex  items-center">
              <label className=" w-32 md:flex-none text-sm font-medium ">
                % of Depreciation
              </label>
              <div className="w-full">
                <input
                  type="text"
                  name="depreciation"
                  value={"" || values.depreciation}
                  onChange={handleChange}
                  className="w-full rounded-inputBRedius bg-inputBgColor p-2"
                  placeholder="Depreciation"
                />
                <InputError
                  error={errors.depreciation}
                  touched={touched.depreciation}
                />
              </div>
            </div>
            <div className="col-span-2 gap-6 mb-5 sm:mb-3  lg:flex  items-center">
              <label className=" w-32 md:flex-none text-sm font-medium ">
                Current Value
              </label>
              <div className="w-full">
                <input
                  type="text"
                  name="currentValue"
                  value={"" || values.currentValue}
                  onChange={handleChange}
                  className="w-full rounded-inputBRedius bg-inputBgColor p-2"
                  placeholder="Current Value"
                />
                <InputError
                  error={errors.currentValue}
                  touched={touched.currentValue}
                />
              </div>
            </div>
          </div>
          <div className="md:grid grid-cols-6 gap-4 mt-6 text-left ">
            <div className="div col-span-6 text-lg font-semibold ">
              Insurance Details
            </div>
            <div className="col-span-2 gap-6 mb-5 sm:mb-3  lg:flex  items-center">
              <label className=" w-32 md:flex-none text-sm font-medium ">
                Current Insurance Provider
              </label>
              <div className="w-full">
                <input
                  type="text"
                  name="currentInsuranceProvider"
                  value={"" || values.currentInsuranceProvider}
                  onChange={handleChange}
                  className="w-full rounded-inputBRedius bg-inputBgColor p-2"
                  placeholder="Current Insurance Provider"
                />
                <InputError
                  error={errors.currentInsuranceProvider}
                  touched={touched.currentInsuranceProvider}
                />
              </div>
            </div>
            <div className="col-span-2 gap-6 mb-5 sm:mb-3  lg:flex  items-center">
              <label className=" w-32 md:flex-none text-sm font-medium ">
                Policy Number
              </label>
              <div className="w-full">
                <input
                  type="text"
                  name="policyNumber"
                  value={"" || values.policyNumber}
                  onChange={handleChange}
                  className="w-full rounded-inputBRedius bg-inputBgColor p-2"
                  placeholder="Policy Number"
                />
                <InputError
                  error={errors.policyNumber}
                  touched={touched.policyNumber}
                />
              </div>
            </div>
            <div className="col-span-2 gap-6 mb-5 sm:mb-3  lg:flex  items-center">
              <label className=" w-32 md:flex-none text-sm font-medium ">
                Policy Type
              </label>
              <div className="w-full">
                <input
                  type="text"
                  name="policyType"
                  value={"" || values.policyType}
                  onChange={handleChange}
                  className="w-full rounded-inputBRedius bg-inputBgColor p-2"
                  placeholder="Policy Type"
                />
                <InputError
                  error={errors.policyType}
                  touched={touched.policyType}
                />
              </div>
            </div>
            <div className="col-span-2 gap-6 mb-5 sm:mb-3  lg:flex  items-center">
              <label className=" w-32 md:flex-none text-sm font-medium ">
                Policy Start Date
              </label>
              <div className="w-full">
                <input
                  type="date"
                  name="policyStartDate"
                  value={"" || values.policyStartDate}
                  onChange={handleChange}
                  className="w-full rounded-inputBRedius bg-inputBgColor p-2"
                  placeholder="Policy Start Date"
                />
                <InputError
                  error={errors.policyStartDate}
                  touched={touched.policyStartDate}
                />
              </div>
            </div>
            <div className="col-span-2 gap-6 mb-5 sm:mb-3  lg:flex  items-center">
              <label className=" w-32 md:flex-none text-sm font-medium ">
                Policy End Date
              </label>
              <div className="w-full">
                <input
                  type="date"
                  name="policyEndDate"
                  value={"" || values.policyEndDate}
                  onChange={handleChange}
                  className="w-full rounded-inputBRedius bg-inputBgColor p-2"
                  placeholder="Policy End Date"
                />
                <InputError
                  error={errors.policyEndDate}
                  touched={touched.policyEndDate}
                />
              </div>
            </div>
            <div className="col-span-2 gap-6 mb-5 sm:mb-3  lg:flex  items-center">
              <label className=" w-32 md:flex-none text-sm font-medium ">
                Policy Premium
              </label>
              <div className="w-full">
                <input
                  type="text"
                  name="policyPremium"
                  value={"" || values.policyPremium}
                  onChange={handleChange}
                  className="w-full rounded-inputBRedius bg-inputBgColor p-2"
                  placeholder="Policy Premium"
                />
                <InputError
                  error={errors.policyPremium}
                  touched={touched.policyPremium}
                />
              </div>
            </div>
          </div>
          <div className="md:grid grid-cols-6 gap-4 mt-6 text-left ">
            <div className="div col-span-6 text-lg font-semibold ">
              Fitness Details
            </div>
            <div className="col-span-3 gap-6 mb-5 sm:mb-3  lg:flex  items-center">
              <label className=" w-34 md:flex-none text-sm font-medium ">
                Last Fitness Renewal Date
              </label>
              <div className="w-full">
                <input
                  type="date"
                  name="lastFitnessRenewalDate"
                  value={"" || values.lastFitnessRenewalDate}
                  onChange={handleChange}
                  className="w-full rounded-inputBRedius bg-inputBgColor p-2"
                  placeholder="Last Fitness Renewal Date"
                />
                <InputError
                  error={errors.lastFitnessRenewalDate}
                  touched={touched.lastFitnessRenewalDate}
                />
              </div>
            </div>
            <div className="col-span-3 gap-6 mb-5 sm:mb-3  lg:flex  items-center">
              <label className=" w-34 md:flex-none text-sm font-medium ">
                Current Fitness Valid Upto
              </label>
              <div className="w-full">
                <input
                  type="date"
                  name="currentFitnessValidUpto"
                  value={"" || values.currentFitnessValidUpto}
                  onChange={handleChange}
                  className="w-full rounded-inputBRedius bg-inputBgColor p-2"
                  placeholder="Current Fitness Valid Upto"
                />
                <InputError
                  error={errors.currentFitnessValidUpto}
                  touched={touched.currentFitnessValidUpto}
                />
              </div>
            </div>
          </div>
          <div className="md:grid grid-cols-6 gap-4 mt-6 text-left ">
            <div className="div col-span-6 text-lg font-semibold ">
              Registration Renewal Details
            </div>
            <div className="col-span-2 gap-6 mb-5 sm:mb-3  lg:flex  items-center">
              <label className=" w-34 md:flex-none text-sm font-medium ">
                First Reg. Valid Upto
              </label>
              <div className="w-full">
                <input
                  type="date"
                  name="firstRegValidUpto"
                  value={"" || values.firstRegValidUpto}
                  onChange={handleChange}
                  className="w-full rounded-inputBRedius bg-inputBgColor p-2"
                  placeholder="First Reg. Valid Upto"
                />
                <InputError
                  error={errors.firstRegValidUpto}
                  touched={touched.firstRegValidUpto}
                />
              </div>
            </div>
            <div className="col-span-2 gap-6 mb-5 sm:mb-3  lg:flex  items-center">
              <label className=" w-34 md:flex-none text-sm font-medium ">
                Renewal Date
              </label>
              <div className="w-full">
                <input
                  type="date"
                  name="renewalDate"
                  value={"" || values.renewalDate}
                  onChange={handleChange}
                  className="w-full rounded-inputBRedius bg-inputBgColor p-2"
                  placeholder="Renewal Date"
                />
                <InputError
                  error={errors.renewalDate}
                  touched={touched.renewalDate}
                />
              </div>
            </div>
            <div className="col-span-2 gap-6 mb-5 sm:mb-3  lg:flex  items-center">
              <label className=" w-34 md:flex-none text-sm font-medium ">
                Renewal Valid Upto
              </label>
              <div className="w-full">
                <input
                  type="date"
                  name="renewalValidUpto"
                  value={"" || values.renewalValidUpto}
                  onChange={handleChange}
                  className="w-full rounded-inputBRedius bg-inputBgColor p-2"
                  placeholder="Renewal Valid Upto"
                />
                <InputError
                  error={errors.renewalValidUpto}
                  touched={touched.renewalValidUpto}
                />
              </div>
            </div>
            <div className="col-span-6 mt-4 items-center">
              <label className=" w-34 md:flex-none text-sm font-medium ">
                Additional Comments
              </label>
              <textarea
                name="addcomment"
                id=""
                cols="30"
                rows="5"
                placeholder="Additional Comments"
                value={"" || values.addcomment}
                onChange={handleChange}
                className="w-full rounded-inputBRedius bg-inputBgColor p-2"
              ></textarea>
            </div>
          </div>
          <div className="mt-12">
            <PrimeButton btnName="Update" buttonLoader={buttonLoader} />
          </div>
        </form>
      </div>
    </div>
  );
};

export default EditVehicleDetail;
