import { Statusbar } from "../../../component/StatusBar";
import { useSelector } from "react-redux";
import Icon_Order from "../../../img/Icon_Order.svg";
import Delivered from "../../../img/Delivered.svg";
import Order from "../../../img/Order.svg";
import Group from "../../../img/Group.svg";
import { RoutePath } from "../../../router/RoutePath";
import VisibilityIcon from "@mui/icons-material/Visibility";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import PinDropOutlinedIcon from "@mui/icons-material/PinDropOutlined";
import DoNotDisturbOffOutlinedIcon from "@mui/icons-material/DoNotDisturbOffOutlined";
import { DynamicTable } from "../../../tables/DynamicTable";
import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import PageName from "../../../component/PageName";
import {formateDate,formatDateToISO} from "../../../function/utilies";


const BookingRequest = () => {
  const { bookingDeatils } = useSelector((state) => state.user);

  const Head = [
    "S.No.",
    "Booking ID",
    "Date",
    "Name",
    "Pick up",
    "",
    "Name",
    "Drop",
    "Contact",
    "Action",
  ];
  const [bookingCount, setBookingCount] = useState(0);
  const [allBookingRaw, setAllBookingRaw] = useState([]);

  const [allBooking, setAllBooking] = useState([]);
  // console.log("allBooking",allBookingRaw);

  useEffect(() => {
    if (bookingDeatils !== null && bookingDeatils.data) {
      setBookingCount(bookingDeatils.count);
      setAllBookingRaw(bookingDeatils.data);
    }
  }, [bookingDeatils]);

  useEffect(() => {
    const modifiedData = allBookingRaw.map((booking) => {
      let senderDetails = booking.senderDetails[0];
      let receiverDtails = booking.receiverDetails[0];
      const formattedDate = formatDateToISO(
        formateDate(booking.bookingDate)
      );
      return {
        item01: booking.bookingOrderId,
        item02: formattedDate,
        item03: senderDetails.personName,
        item04: senderDetails.address,
        itemto: "to",
        item05: receiverDtails.personName,
        item06: receiverDtails.address,
        item07: booking.mobile,
        item08: booking.toCustomerName,
        img01: <VisibilityIcon />,
        img02: <EditIcon />,
        img03: <DeleteIcon />,
        cancel: <DoNotDisturbOffOutlinedIcon />,
        itemLocation: <PinDropOutlinedIcon />,
        unqiueKey: booking.supplierId,
      };
    });
    setAllBooking(modifiedData);
  }, [allBookingRaw]);

  return (
    <div className="w-full">
      <div className="flex items-center justify-between">
      <PageName pageName="Manage Booking" href={RoutePath.BookOrderForm} />
      {/* <AddFilterButton href={RoutePath.BookOrderForm} /> */}
      </div>
      <div className="grid sm:grid-cols-2 lg:grid-cols-4 justify-items-stretch gap-6 p-4 cursor-pointer">
        <Link to={RoutePath.BookingRequest}>
          <Statusbar
            src={Icon_Order}
            count={bookingCount}
            isActive={true}
            title="Booking Requests"
            rating="4% (30 days)"
          />
        </Link>
        <Link to={RoutePath.BookingRequest}>
          <Statusbar
            src={Delivered}
            count="357"
            title="Active Deliveries"
            rating="4% (30 days)"
          />
        </Link>
        <Link to={RoutePath.BookingRequest}>
          <Statusbar
            src={Order}
            count="65"
            title="Total Cancelled"
            rating="25% (30 days)"
          />
        </Link>
        <Link to={RoutePath.BookingRequest}>
          <Statusbar
            src={Group}
            count="Rs 12800"
            title="Total Revenue"
            rating="25% (30 days)"
          />
        </Link>
      </div>
      <DynamicTable
        tableHeadRow={Head}
        data={allBooking}
        DeleteUrl={"booking/delete"}
        eyeButton={RoutePath.EditBookOrderLink}
        editButton={RoutePath.EditBookOrderLink}
      />
    </div>
  );
};

export default BookingRequest;
