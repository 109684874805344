import { AvailablityCard } from "./StatusBar";
import { useSelector } from "react-redux";
import truck from "../img/truck.svg";
import driver from "../img/driver.svg";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { RoutePath } from "../router/RoutePath";

const AvailablityCards = () => {
  const [vehicleCount, setVehicleCount] = useState([]);

  const { vehicleDeatils,driverDeatils } = useSelector((state) => state.user);
  useEffect(() => {
    if (vehicleDeatils !== null && vehicleDeatils.data) {
      setVehicleCount(vehicleDeatils.count);
    }
  }, [vehicleDeatils]);
  //-------------------------------------------Driver Detail-------------------------------------//
  const [driverCount, setDriverCount] = useState([]);

  useEffect(() => {
    if (driverDeatils !== null && driverDeatils.data) {
      setDriverCount(driverDeatils.count);
    }
  }, [driverDeatils]);

  return (
    <div className="w-full">
      <div>
        <div className="grid lg:grid-cols-3 justify-items-stretch gap-6 p-4">
        <Link to={RoutePath.Vehicle}>
            <AvailablityCard
              src={driver}
              count={0}
              title="Staff Available"
              rating={`Total : ${ 0}`}
            />
          </Link>
          <Link to={RoutePath.Vehicle}>
            <AvailablityCard
              src={truck}
              count={vehicleCount || 0}
              title="Vehicles Available"
              rating={`Total : ${vehicleCount || 0}`}
            />
          </Link>
          <Link to={RoutePath.Driver}>
            <AvailablityCard
              src={driver}
              count={driverCount || 0}
              title="Drivers Available"
              rating={`Total : ${driverCount || 0}`}
            />
          </Link>
         
        </div>
      </div>
    </div>
  );
};

export default AvailablityCards;
