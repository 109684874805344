import React, { useState, useEffect, useRef } from "react";
import InputError from "../../../component/InputError";
import profile from "../../../img/profile.jpg";
import Dropdown from "../../../component/Dropdown";
import { useParams } from "react-router-dom";
import { BackButton, PrimeButton } from "../../../component/PrimeButton";
import * as Yup from "yup";
import { useFormik } from "formik";
import { getAxiosWithToken } from "../../../axios/AxiosObj";
import {
  alertResponseMsgError,
  alertResponseMsgSuccess,
  valuesCheck,
  valuesEmpty,
} from "../../../function/utilies";
import StateName from "../../../component/dropdown/StateName";
import CityName from "../../../component/dropdown/CityName";

const initialValues = {
  firstName: "",
  middleName: "",
  lastName: "",
  contact: "",
  email: "",
  locality: "",
  city: "",
  state: "",
  district: "",
  pincode: "",
  IDNumber: "",
  document: "",
  photo: "",
};

const EditSuperAdminDetail = () => {
  const states = ["State1", "State2", "State3"]; // Add your state names here
  const cities = {
    State1: ["City1A", "City1B", "City1C"],
    State2: ["City2A", "City2B", "City2C"],
    State3: ["City3A", "City3B", "City3C"],
  }; // Add your city names here

  const params = useParams();
  const [tableRowData, setTableRowData] = useState();

  const ConstomerCreateSchema = Yup.object({
    firstName: Yup.string().required("First Name should not be empty"),
    lastName: Yup.string().required("Last Name should not be empty"),
    contact: Yup.number().required("Contact should not be empty"),
    email: Yup.string().email().required("Email should not be empty"),
    locality: Yup.string().required("locality should not be empty"),
    // city: Yup.string().required("city should not be empty"),
    // state: Yup.string().required("state should not be empty"),
    district: Yup.string().required("District should not be empty"),
    pincode: Yup.number().required("Pin Code should not be empty"),
    // IDNumber: Yup.number().required("ID Number should not be empty"),
    // document: Yup.string().required("Upload Document"),
    // photo: Yup.string().required("Upload Photo"),
  });
  const { errors, touched, values, handleChange, handleSubmit } = useFormik({
    initialValues: initialValues,
    validationSchema: ConstomerCreateSchema,
    onSubmit: async (values, action) => {
      let data = await valuesCheck(values, tableRowData);

      let username;
      if (params && params.username && params.username !== " ") {
        username = params.username;
      }

      try {
        const response = await getAxiosWithToken({
          method: "Patch",
          url: `admin/update/${username}`, // Adjust the API endpoint as needed
          data: data,
        });
        // API response 200 201
        if (response.data.success) {
          alertResponseMsgSuccess(response.data.msg);
        } else {
          alertResponseMsgError(response.data.msg);
        }
      } catch (error) {
        console.error(error);
        alertResponseMsgError(error.response.data.msg);
        // alertResponseMsgError(error.response.msg);
      }
    },
  });

  const getDeatils = async (username) => {
    try {
      const response = await getAxiosWithToken({
        method: "get",
        url: `admin/details/${username}`, // Adjust the API endpoint as needed
      });
      // console.log(response);
      if (response && response.data && response.data.data) {
        // values.fullName = response.data.data.firstName;
        setTableRowData(response.data.data);
      }
    } catch (e) {
      window.location.href = "/admin";
      if (!e.response.data.success) {
        window.location.href = "/admin";
      }
    }
  };
  useEffect(() => {
    if (tableRowData) {
      values.firstName = tableRowData.firstName;
      values.middleName = tableRowData.middleName;
      values.lastName = tableRowData.lastName;
      values.contact = tableRowData.mobile;
      values.email = tableRowData.email;
      values.locality = tableRowData.address1;
      values.district = tableRowData.district;
      values.pincode = tableRowData.pincode;
      values.state = tableRowData.state;
      values.city = tableRowData.city;
      values.IDNumber = tableRowData.IDNumber;
      values.document = tableRowData.document;
      values.photo = tableRowData.photo;
    }
  }, [tableRowData]);

  const valueRef = useRef(values);

  useEffect(() => {
    if (params && params.username && params.username !== " ") {
      //valuesEmpty fuction use to emapty previous data
      valueRef.current = valuesEmpty(values, "");
      getDeatils(params.username);
    } else {
      window.location.href = "/admin";
    }
  }, [params]);

  return (
    <div>
      <div className="bg-backgroundColor rounded-xl p-8">
        <BackButton btnName="Edit Super Admin Details" />
        <form onSubmit={handleSubmit}>
          <div className="md:grid grid-cols-6 gap-4 text-left text-mainTxtColor">
            <div className="col-span-6 gap-6 ">
              <div className="flex items-center text-mainTxtColor gap-3 px-3">
                <img
                  src={profile}
                  alt="user.name"
                  className="w-28 h-28 rounded-full bg-slate-100 ring-2 ring-primeryBgColor"
                  loading="lazy"
                />
                <div className="w-full">
                  <input
                    type="text"
                    name="firstName"
                    value={"" || values.firstName}
                    onChange={handleChange}
                    className="w-full rounded-inputBRedius bg-inputBgColor p-2"
                    placeholder="First Name"
                  />
                  <InputError
                    error={errors.firstName}
                    touched={touched.firstName}
                  />
                </div>
                <div className="w-full">
                  <input
                    type="text"
                    name="middleName"
                    value={"" || values.middleName}
                    onChange={handleChange}
                    className="w-full rounded-inputBRedius bg-inputBgColor p-2 "
                    placeholder="Middle Name"
                  />
                  <InputError
                    error={errors.middleName}
                    touched={touched.middleName}
                  />
                </div>
                <div className="w-full">
                  <input
                    type="text"
                    name="lastName"
                    value={"" || values.lastName}
                    onChange={handleChange}
                    className="w-full rounded-inputBRedius bg-inputBgColor p-2 "
                    placeholder="Last Name"
                  />
                  <InputError
                    error={errors.lastName}
                    touched={touched.lastName}
                  />
                </div>
              </div>
            </div>

            <div className="col-span-3 gap-6  lg:flex py-2  items-center">
              <label className=" w-36 md:flex-none text-sm font-medium ">
                Contact Number
              </label>
              <div className="w-full">
                <input
                  type="text"
                  name="contact"
                  value={"" || values.contact}
                  onChange={handleChange}
                  className="w-full rounded-inputBRedius bg-inputBgColor p-2"
                  placeholder="Contact"
                />
                <InputError error={errors.contact} touched={touched.contact} />
              </div>
            </div>
            <div className="col-span-3 gap-6 lg:flex py-2 items-center">
              <label className=" w-36 md:flex-none text-sm font-medium ">
                Email ID
              </label>
              <div className="w-full">
                <input
                  type="email"
                  name="email"
                  value={"" || values.email}
                  onChange={handleChange}
                  className="w-full rounded-inputBRedius bg-inputBgColor p-2"
                  placeholder="Email"
                />
                <InputError error={errors.email} touched={touched.email} />
              </div>
            </div>
            <div className="div col-span-6 font-semibold text-left text-lg">
              Addresss
            </div>
            <div className="col-span-6 gap-6  lg:flex py-2  items-center">
              <label className="w-36 md:flex-none text-sm font-medium ">
                Locality / Street
              </label>
              <div className="w-full">
                <input
                  type="text"
                  name="locality"
                  value={"" || values.locality}
                  onChange={handleChange}
                  className="w-full rounded-inputBRedius bg-inputBgColor p-2"
                  placeholder="Locality / Street"
                />
                <InputError
                  error={errors.locality}
                  touched={touched.locality}
                />
              </div>
            </div>
            <div className="col-span-3 gap-6  lg:flex py-2 items-center">
            <label className=" w-36 md:flex-none text-sm font-medium ">
              State
            </label>
            <div className="w-full">
            <StateName handleChange={handleChange}name={"state"} value={values.state}/>
            </div>
          </div>
          <div className="col-span-3 gap-6  lg:flex py-2 items-center">
            <label className=" w-36 md:flex-none text-sm font-medium ">
              City
            </label>
            <div className="w-full">
            <CityName handleChange={handleChange} name={"city"} value={values.city} stateId={values.state}/> 
            </div>
          </div>
            <div className="col-span-3 gap-6  lg:flex py-2  items-center">
              <label className=" w-36 md:flex-none text-sm font-medium ">
                District
              </label>
              <div className="w-full">
                <input
                  type="text"
                  name="district"
                  value={"" || values.district}
                  onChange={handleChange}
                  className="w-full rounded-inputBRedius bg-inputBgColor p-2"
                  placeholder="District"
                />
                <InputError
                  error={errors.district}
                  touched={touched.district}
                />
              </div>
            </div>
            <div className="col-span-3 gap-6  lg:flex py-2  items-center">
              <label className=" w-36 md:flex-none text-sm font-medium ">
                Pin Code
              </label>
              <div className="w-full">
                <input
                  type="text"
                  name="pincode"
                  value={"" || values.pincode}
                  onChange={handleChange}
                  className="w-full rounded-inputBRedius bg-inputBgColor p-2"
                  placeholder="Pin Code"
                />
                <InputError error={errors.pincode} touched={touched.pincode} />
              </div>
            </div>
            <div className=" col-span-6 font-semibold text-left text-lg">
              Documents
            </div>
            <div className="col-span-3 gap-6  lg:flex  items-center">
              <label
                htmlFor="first_name"
                className=" w-36 md:flex-none text-sm font-medium "
              >
                ID Proof
              </label>
              <div className="w-full">
                <input
                  type="text"
                  name="IDNumber"
                  value={"" || values.IDNumber}
                  onChange={handleChange}
                  className="w-full rounded-inputBRedius bg-inputBgColor p-2"
                  placeholder="ID Number"
                />
                <InputError
                  error={errors.IDNumber}
                  touched={touched.IDNumber}
                />
              </div>
            </div>
          </div>
          <div className=" text-white mt-4 ">
            <div className="mt-12">
              <PrimeButton btnName="Update" />
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default EditSuperAdminDetail;
