// Example
// /customers ----------------------------- customer list
// /customer/[username] ----------------------------- customer view details page
// /customer/update/[username] ----------------------------- customer update details

export const RoutePath = {
  // Customer
  ManageCustomer: "/customer",
  AddCustomerForm: "/customer/add",
  EditCustomerDetail: "/customer/update/:username",
  EditCustomerDetailLink: "/customer/update",

  // Driver
  Driver: "/driver",
  AddDriverForm: "/driver/add",
  EditDriverDetail: "/driver/update/:username",
  EditDriverDetailLink: "/driver/update",
  //TotalDriver
  TotalDriver: "/totaldriver",

  //Quotation
  CustomerQuotation: "/quotation",
  CustomerQuotationForm: "/quotation/add",
  EditCustomerQuotationDetail: "/quotation/update/:quotationOrderId",
  EditCustomerQuotationDetailLink: "/quotation/update",
  quotationActiveDelivery: "/quotation/activedelivery",
  cancelledQuotation:"/quotation/cancelled",

  //Expenses
  ManageExpense: "/expense",
  ExpensesForm: "/expense/add",
  EditExpensesDetail: "/expense/update/:expenseId",
  EditExpensesDetailLink: "/expense/update",
  InvoiceRecipt: "/expense/invoicerecipt/:expenseId",
  InvoiceReciptLink: "/expense/invoicerecipt",


  //customerledger
  CustomerLedger: "/ledger",
  LedgerViewDetail: "/ledgerviewdetail/:username",

  // ledger History
    CustomerInvoice: "/customerinvoice",

  //GST Invoice
  GstInvoiceBill: "/gstinvoicebill/:invoiceId",
  GstInvoiceBillLink: "/gstinvoicebill",

  //staff
  Staff: "/staff",
  AddStaff: "/staff/add",
  EditStaffDetail: "staff/update/:username",
  EditStaffDetailLink: "staff/update",

  //Admin
  Admin: "/sub/admin",
  AddAdmin: "/subadmin/add",
  EditAdminDetail: "/subadmin/update/:username",
  EditAdminDetailLink: "/subadmin/update",

  //SuperAdmin
  SuperAdmin: "/admin",
  AddSuperAdmin: "/admin/add",
  EditSuperAdminDetail: "/admin/update/:username",
  EditSuperAdminDetailLink: "/admin/update",

  //Vehicle
  Vehicle: "/vehicle",
  AddVehicle: "/vehicle/add",
  EditVehicleDetail: "/vehicle/update/:vehicleId",
  EditVehicleDetailLink: "/vehicle/update",
  //Fuel
  FuelRefill: "/fuel",
  //Maintenance
  Maintenance: "/maintenance",
  //TotalVehicle
  TotalVehicle: "/totalvehicle",

  //BookingRequest
  BookingRequest: "/booking",
  BookOrderForm: "/booking/add",
  EditBookOrder: "/booking/update/:bookingOrderId",
  EditBookOrderLink: "/booking/update",

  //ActiveDelivery
  ActiveDelivery: "booking/activedelivery",
  DeliveryDetail: "/activedelivery/update",

  //Canceled
  TotalCanceled: "/cancel",

  //Revenue
  TotalRevenue: "/revenue",

  //Delivery
  AssignDelivery: "/delivery",
  FinalDelivery: "/finaldelivery",
  //Contact
  ContactList: "/contact",
  EditContactList: "/contact/update/:contactId",
  EditContactListLink: "/contact/update",

  //Station
  Station: "/station",
  AddStation: "/station/add",
  EditStation: "/station/update/:stationId",
  EditStationLink: "/station/update",

  //ActiveUserViewDetail
  ActiveUserDetail: "/yourProfile",

  Report: "/report",
  InvoiceBill: "/invoicebill",
  TrackerMap: "/tracker",
  Mapper: "/mapper",
};
