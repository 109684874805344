// Dropdown.js
import React from "react";

const Dropdown = ({ label, options, name, handleChange, value = "" }) => {
  return (
    <div>
      <select
        value={value}
        name={name}
        onChange={handleChange}
        className=" w-full rounded-inputBRedius bg-inputBgColor p-2"
      >
        <option value="" >
          Select {label}
        </option>
        {/* <option value="">None</option> */}
        {options.map((option, index) => (
          <option
            key={index}
            value={option ? option.stationId : option}
          >
            {option.stationName ? null : option}
            {option.stationName ? option.stationName : null}
          </option>
        ))}
      </select>
    </div>
  );
};

export default Dropdown;
