import React, { useState, useEffect, useRef } from "react";
import InputError from "../../../component/InputError";
import profile from "../../../img/profile.jpg";
import { useParams } from "react-router-dom";
import { BackButton, PrimeButton } from "../../../component/PrimeButton";
import * as Yup from "yup";
import { useFormik } from "formik";
import { getAxiosWithToken } from "../../../axios/AxiosObj";
import {
  alertResponseMsgError,
  alertResponseMsgSuccess,
  valuesCheck,
  valuesEmpty,
} from "../../../function/utilies";
import StateName from "../../../component/dropdown/StateName";
import CityName from "../../../component/dropdown/CityName";

const initialValues = {
  firstName: "",
  middleName: "",
  lastName: "",
  contact: "",
  email: "",
  address1: "",
  city: "",
  state: "",
  district: "",
  pincode: "",
  idProofNo: "",
  dlNumber: "",
  document: "",
  photo: "",
};

const EditDriverDetail = () => {
  const [photo, setphoto] = useState(null);
  const params = useParams();
  const [buttonLoader, setButtonLoader] = useState(false);

  const [allDriverRaw, setAllDriverRaw] = useState();

  const DriverCreateSchema = Yup.object({
    firstName: Yup.string().required("First Name should not be empty"),
    lastName: Yup.string().required("Last Name should not be empty"),
    contact: Yup.number().required("Contact should not be empty"),
    email: Yup.string().email().required("Email should not be empty"),
    address1: Yup.string().required("locality should not be empty"),
    city: Yup.string().required("city should not be empty"),
    state: Yup.string().required("state should not be empty"),
    district: Yup.string().required("District should not be empty"),
    pincode: Yup.number().required("Pin Code should not be empty"),
    idProofNo: Yup.number().required("ID Number should not be empty"),
    DLNumber: Yup.number().required("DL Number should not be empty"),
  });
  const { errors, touched, values, handleChange, handleSubmit } = useFormik({
    initialValues: initialValues,
    validationSchema: DriverCreateSchema,
    onSubmit: async (values, action) => {
      // setButtonLoader(true);

      let updatedvalue = await valuesCheck(values, allDriverRaw);
      let data = new FormData();
      console.log("updatedvalue:", updatedvalue);

      for (const key in updatedvalue) {
        if (updatedvalue.hasOwnProperty(key)) {
          const value = updatedvalue[key];
          console.log(`Appending ${key}: ${value}`);
          data.append(key, value);
        }
      }
      if (photo !== null && photo !== undefined) {
        data.append("photo", photo);
      }
    
      console.log("formData", data);
      // return false;

      let username;
      if (params && params.username && params.username !== " ") {
        username = params.username;
      }

      try {
        const response = await getAxiosWithToken({
          method: "Patch",
          url: `driver/update/${username}`, // Adjust the API endpoint as needed
          data: data,
          reqType: "formData",
        });
        // API response 200 201
        if (response.data.success) {
          alertResponseMsgSuccess(response.data.msg);
          setButtonLoader(false);
        } else {
          alertResponseMsgError(response.data.msg);
        }
      } catch (error) {
        console.error(error);
        alertResponseMsgError(error.response.data.msg);
        setButtonLoader(false);

        // alertResponseMsgError(error.response.msg);
      }
    },
  });

  const getDeatils = async (username) => {
    try {
      const response = await getAxiosWithToken({
        method: "get",
        url: `driver/details/${username}`, // Adjust the API endpoint as needed
      });
      // console.log(response);
      if (response && response.data && response.data.data) {
        // values.fullName = response.data.data.firstName;
        setAllDriverRaw(response.data.data);
      }
    } catch (e) {
      window.location.href = "/customers";
      if (!e.response.data.success) {
        window.location.href = "/customers";
      }
    }
  };

  
  //----------------------------------------------- upload Document -------------------------------------------//

  // const [userPhotoErr, setuserPhotoErr] = useState(null);
  const [photoPerview, setPhotoPerview] = useState(null);
  const [IdDoc, setIdDoc] = useState(null);
  // const [userPhotoErr, setuserPhotoErr] = useState(null);
  const [idDocPerview, setIdDocPerview] = useState(null);

  // values.profilepic= photo
  const handleChangeFile = (e) => {
    const { name, files } = e.target;
    if (name === "userPhoto") {
      let driverImage = files[0];
      if (driverImage.size > 2000000) {
        alertResponseMsgError("Image Should be less then 2 mb");
        return false;
      }
      if (
        driverImage.type.includes("image/jpeg") ||
        driverImage.type.includes("image/jpg") ||
        driverImage.type.includes("image/png")
      ) {
        const imageUrl = URL.createObjectURL(driverImage);
        setPhotoPerview(imageUrl);
        setphoto(driverImage);
      } else {
        alertResponseMsgError("Image formate Should be jpeg, jpg, png");
      }
    }
    if (name === "idProofDoc") {
      let IDProof = files[0];
      if (IDProof.size > 2000000) {
        console.log("Error Size");
        alertResponseMsgError("Image Should be less then 2 mb");
        return false;
      }
      if (
        IDProof.type.includes("image/jpeg") ||
        IDProof.type.includes("image/jpg") ||
        IDProof.type.includes("image/png")
      ) {
        const imageUrl = URL.createObjectURL(IDProof);
        setIdDocPerview(imageUrl);
        setIdDoc(IDProof);
      } else {
        alertResponseMsgError("Image formate Should be jpeg, jpg, png");
      }
    }
  };

  useEffect(() => {
    if (allDriverRaw) {
      values.firstName = allDriverRaw.firstName;
      values.middleName = allDriverRaw.middleName;
      values.lastName = allDriverRaw.lastName;
      values.contact = allDriverRaw.mobile;
      values.email = allDriverRaw.email;
      values.address1 = allDriverRaw.address1;
      values.district = allDriverRaw.district;
      values.city = allDriverRaw.city;
      values.state = allDriverRaw.state;
      values.pincode = allDriverRaw.pincode;
      values.DLNumber = allDriverRaw.DLNumber;
      values.idProofNo = allDriverRaw.idProofNo;
      values.document = allDriverRaw.idProofsrc;
      values.profilepic = allDriverRaw.profilepic; 
    }
  }, [allDriverRaw]);

  const valueRef = useRef(values);
  useEffect(() => {
    if (params && params.username && params.username !== " ") {
      valueRef.current = valuesEmpty(values, "");
      getDeatils(params.username);
    }
  }, [params]);

  return (
    <div>
      <div className="bg-backgroundColor rounded-xl p-8">
        <BackButton btnName="Edit Driver Details" backtoPage="/managedriver" />
        <form onSubmit={handleSubmit}>
          <div className="md:grid grid-cols-6 gap-4 text-left text-mainTxtColor">
            <div className="col-span-6 gap-6 ">
              <div className="flex items-center text-mainTxtColor gap-3 px-3">
                <img
                  src={`https://api.bharatparcel.org/${values.profilepic}` || profile }
                  alt="Driver"
                  className="w-28 h-28 rounded-full bg-slate-100 ring-2 ring-primeryBgColor"
                  loading="lazy"
                />
                <div className="w-full">
                  <input
                    type="text"
                    name="firstName"
                    value={"" || values.firstName}
                    onChange={handleChange}
                    className="w-full rounded-inputBRedius bg-inputBgColor p-2"
                    placeholder="First Name"
                  />
                  <InputError
                    error={errors.firstName}
                    touched={touched.firstName}
                  />
                </div>
                <div className="w-full">
                  <input
                    type="text"
                    name="middleName"
                    value={"" || values.middleName}
                    onChange={handleChange}
                    className="w-full rounded-inputBRedius bg-inputBgColor p-2 "
                    placeholder="Middle Name"
                  />
                  <InputError
                    error={errors.middleName}
                    touched={touched.middleName}
                  />
                </div>
                <div className="w-full">
                  <input
                    type="text"
                    name="lastName"
                    value={"" || values.lastName}
                    onChange={handleChange}
                    className="w-full rounded-inputBRedius bg-inputBgColor p-2 "
                    placeholder="Last Name"
                  />
                  <InputError
                    error={errors.lastName}
                    touched={touched.lastName}
                  />
                </div>
              </div>
            </div>

            <div className="col-span-3 gap-6  lg:flex py-2  items-center">
              <label className=" w-36 md:flex-none text-sm font-medium ">
                Contact Number
              </label>
              <div className="w-full">
                <input
                  type="text"
                  name="contact"
                  value={"" || values.contact}
                  onChange={handleChange}
                  className="w-full rounded-inputBRedius bg-inputBgColor p-2"
                  placeholder="Contact"
                />
                <InputError error={errors.contact} touched={touched.contact} />
              </div>
            </div>
            <div className="col-span-3 gap-6 lg:flex py-2 items-center">
              <label className=" w-36 md:flex-none text-sm font-medium ">
                Email ID
              </label>
              <div className="w-full">
                <input
                  type="email"
                  name="email"
                  value={"" || values.email}
                  onChange={handleChange}
                  className="w-full rounded-inputBRedius bg-inputBgColor p-2"
                  placeholder="Email"
                />
                <InputError error={errors.email} touched={touched.email} />
              </div>
            </div>
            <div className="div col-span-6 font-semibold text-left text-lg">
              Addresss
            </div>
            <div className="col-span-6 gap-6  lg:flex py-2  items-center">
              <label className="w-36 md:flex-none text-sm font-medium ">
                address1 / Street
              </label>
              <div className="w-full">
                <input
                  type="text"
                  name="address1"
                  value={"" || values.address1}
                  onChange={handleChange}
                  className="w-full rounded-inputBRedius bg-inputBgColor p-2"
                  placeholder="address1 / Street"
                />
                <InputError
                  error={errors.address1}
                  touched={touched.address1}
                />
              </div>
            </div>
            <div className="col-span-3 gap-6  lg:flex py-2 items-center">
              <label className=" w-36 md:flex-none text-sm font-medium ">
                State
              </label>
              <div className="w-full">
                <StateName
                  handleChange={handleChange}
                  name={"state"}
                  value={values.state}
                />
                <InputError error={errors.state} touched={touched.state} />
              </div>
            </div>
            <div className="col-span-3 gap-6  lg:flex py-2 items-center">
              <label className=" w-36 md:flex-none text-sm font-medium ">
                City
              </label>
              <div className="w-full">
                <CityName
                  handleChange={handleChange}
                  name={"city"}
                  value={values.city}
                  stateId={values.state}
                />
                <InputError error={errors.city} touched={touched.city} />
              </div>
            </div>
            <div className="col-span-3 gap-6  lg:flex py-2  items-center">
              <label className=" w-36 md:flex-none text-sm font-medium ">
                District
              </label>
              <div className="w-full">
                <input
                  type="text"
                  name="district"
                  value={"" || values.district}
                  onChange={handleChange}
                  className="w-full rounded-inputBRedius bg-inputBgColor p-2"
                  placeholder="District"
                />
                <InputError
                  error={errors.district}
                  touched={touched.district}
                />
              </div>
            </div>
            <div className="col-span-3 gap-6  lg:flex py-2  items-center">
              <label className=" w-36 md:flex-none text-sm font-medium ">
                Pin Code
              </label>
              <div className="w-full">
                <input
                  type="text"
                  name="pincode"
                  value={"" || values.pincode}
                  onChange={handleChange}
                  className="w-full rounded-inputBRedius bg-inputBgColor p-2"
                  placeholder="Pin Code"
                />
                <InputError error={errors.pincode} touched={touched.pincode} />
              </div>
            </div>
            <div className=" col-span-6 font-semibold text-left text-lg">
              Documents
            </div>
            <div className="col-span-3 gap-6  lg:flex  items-center">
              <label className=" w-36 md:flex-none text-sm font-medium ">
                DL Number
              </label>
              <div className="w-full">
                <input
                  type="text"
                  name="DLNumber"
                  value={"" || values.DLNumber}
                  onChange={handleChange}
                  className="w-full rounded-inputBRedius bg-inputBgColor p-2"
                  placeholder="DL Number"
                />
                <InputError
                  error={errors.DLNumber}
                  touched={touched.DLNumber}
                />
              </div>
            </div>
            <div className="col-span-3 gap-6  lg:flex py-2  items-center">
              <label className=" w-36 md:flex-none text-sm font-medium ">
                ID Proof
              </label>
              <div className="w-full">
                <input
                  type="text"
                  name="idProofNo"
                  value={"" || values.idProofNo}
                  onChange={handleChange}
                  className="w-full rounded-inputBRedius bg-inputBgColor p-2"
                  placeholder="ID Number"
                />
                <InputError
                  error={errors.idProofNo}
                  touched={touched.idProofNo}
                />
              </div>
              <div className="w-full p-2 bg-white rounded-inputBRedius hover:bg-blue-100">
                <input
                  type="file"
                  name="idProofDoc"
                  onChange={handleChangeFile}
                  className="w-full file:bg-white
                file:rounded file:border-0
                 file:font-semibold
                file:text-primeryBgColor"
                  placeholder="Id Proof"
                  accept="image/png, image/jpeg, image/jpg"
                />
                {idDocPerview ? (
                  <img src={idDocPerview} alt="Id Proof" />
                ) : null}
              </div>
            </div>
            <div className="col-span-3 gap-6  lg:flex py-2  items-center">
              <label className=" w-36 md:flex-none text-sm font-medium ">
                Upload Driver's Photo
              </label>
              <div className="w-full p-2 bg-white rounded-inputBRedius hover:bg-blue-100">
                <input
                  type="file"
                  name="userPhoto"
                  onChange={handleChangeFile}
                  className="w-full file:bg-white
                file:rounded file:border-0
                 file:font-semibold
                file:text-primeryBgColor"
                  placeholder="Driver Photo"
                  accept="image/png, image/jpeg, image/jpg"
                />
                {photoPerview ? <img src={photoPerview} alt="dd" /> : null}
              </div>
            </div>
            <div className="col-span-3 gap-6  lg:flex py-2  items-center">
            <img
              src={`https://api.bharatparcel.org/${values.document}`}
              alt="user"
              className=" p-1 rounded-inputBRedius bg-slate-100 ring-1 ring-primeryBgColor"
              loading="lazy"
            />
          </div>
          </div>
          <div className=" text-white mt-4 ">
            <div className="mt-12">
              <PrimeButton btnName="Update" buttonLoader={buttonLoader} />
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default EditDriverDetail;
