import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { RoutePath } from "../../router/RoutePath";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { DynamicTable } from "../../tables/DynamicTable";
import { PrimeButton } from "../../component/PrimeButton";
import * as Yup from "yup";
import { useFormik } from "formik";
import { getAxiosWithToken } from "../../axios/AxiosObj";
import {
  alertResponseMsgError,
  alertResponseMsgSuccess,
} from "../../function/utilies";
import InputError from "../../component/InputError";
import PageName from "../../component/PageName";

const initialValues = {
  name: "",
  contactNumber: "",
  email: "",
  address: "",
};

const ContactList = () => {
  const { deleteDeatils } = useSelector((state) => state.user);

  const ConstomerCreateSchema = Yup.object({
    name: Yup.string().required("Name should not be empty"),
    contactNumber: Yup.number().required("Contact should not be empty"),
    email: Yup.string().required("Email should not be empty"),
    address: Yup.string().required("Address should not be empty"),
  });

  const { values, errors, touched, handleChange, handleSubmit } = useFormik({
    initialValues: initialValues,
    validationSchema: ConstomerCreateSchema,

    onSubmit: async (values, action) => {
      const data = {
        name: values.name,
        contactNumber: values.contactNumber,
        email: values.email,
        address: values.address,
      };

      // console.log("data", data);
      // return false
      try {
        const response = await getAxiosWithToken({
          method: "POST",
          url: "contact/list/add", // Adjust the API endpoint as needed
          data: data,
        });
        // API response 200 201
        if (response.data.success) {
          alertResponseMsgSuccess(response.data.msg);
          action.resetForm();
          contactData();
        } else {
          alertResponseMsgError(response.data.msg);
        }
      } catch (error) {
        console.error(error);
        alertResponseMsgError(error.response.data.msg);
      }
    },
  });
  //-------------------------get contact data -----------------------------------//

  const [allContact, setAllContact] = useState([]);
  const [numberTable, setNumberTable] = useState([]);

  const tableHead = ["S.No.", "Name", "Number", "Email", "Address", "Action"];

  const contactData = async () => {
    // setDataLoading(true);
    const response = await getAxiosWithToken({
      method: "get",
      url: "contact/list/all",
    });
    if (response && response.data && response.data.data) {
      setAllContact(response.data.data);
    }
  };
  useEffect(() => {
    contactData();
  }, [deleteDeatils]);

  useEffect(() => {
    const modifiedData = allContact.map((contactlist) => {
      return {
        item01: contactlist.name,
        item02: contactlist.contactNumber,
        item03: contactlist.email,
        item04: contactlist.address,
        img02: <EditIcon />,
        img03: <DeleteIcon />,
        unqiueKey: contactlist.contactId,
      };
    });
    setNumberTable(modifiedData);
  }, [allContact]);

  return (
    <div>
      <div className="bg-backgroundColor rounded-xl ">
        <PageName pageName="Contact List" />
        <form onSubmit={handleSubmit}>
          <div className="md:grid grid-cols-5 gap-6 w-full justify-items-center items-end p-4 text-mainTxtColor">
            <div className="w-full py-2 text-left  ">
              <label className="w-36 text-sm font-medium text-gray-900">
                Name
              </label>
              <div className="w-full">
                <input
                  type="text"
                  name="name"
                  value={"" || values.name}
                  onChange={handleChange}
                  className="w-full rounded-inputBRedius bg-inputBgColor p-2  "
                  placeholder=" Name"
                />
                <InputError error={errors.name} touched={touched.name} />
              </div>{" "}
            </div>
            <div className="w-full py-2 text-left  ">
              <label className="w-36 text-sm font-medium text-gray-900">
                Contact Number
              </label>
              <div className="w-full">
                <input
                  type="text"
                  name="contactNumber"
                  value={"" || values.contactNumber}
                  onChange={handleChange}
                  className="w-full rounded-inputBRedius bg-inputBgColor p-2 "
                  placeholder="Number"
                />
                <InputError
                  error={errors.contactNumber}
                  touched={touched.contactNumber}
                />
              </div>
            </div>
            <div className="w-full py-2 text-left  ">
              <label className="w-36 text-sm font-medium text-gray-900">
                Email
              </label>
              <div className="w-full">
                <input
                  type="email"
                  name="email"
                  value={"" || values.email}
                  onChange={handleChange}
                  className="w-full rounded-inputBRedius bg-inputBgColor p-2 "
                  placeholder="Email "
                />
                <InputError error={errors.email} touched={touched.email} />
              </div>{" "}
            </div>
            <div className="w-full py-2 text-left  ">
              <label className="w-36 text-sm font-medium text-gray-900">
                Address
              </label>
              <div className="w-full">
                <input
                  type="text"
                  name="address"
                  value={"" || values.address}
                  onChange={handleChange}
                  className="w-full rounded-inputBRedius bg-inputBgColor p-2 "
                  placeholder="Address"
                />
                <InputError error={errors.address} touched={touched.address} />
              </div>
            </div>
            <div className=" w-full py-2">
              <PrimeButton btnName="+ Contact" className="sm:w-full" />
            </div>
          </div>
        </form>
        {/* <div className="flex justify-center gap-2 sm:justify-end items-center px-4">
            <TitleFilter />
          </div> */}
        <DynamicTable
          tableHeadRow={tableHead}
          data={numberTable}
          DeleteUrl={"contact/list/delete"}
          editButton={RoutePath.EditContactListLink}
        />
      </div>
    </div>
  );
};

export default ContactList;
