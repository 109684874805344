import React from "react";
import { RoutePath } from "../../router/RoutePath";
import { AddFilterButton, TitleFilter } from "../../component/TitileFilter";
import VisibilityIcon from "@mui/icons-material/Visibility";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { useState, useEffect } from "react";
import { Statusbar } from "../../component/StatusBar";
import TrackVehical from "../../img/TrackVehical.svg";
import { DynamicTable } from "../../tables/DynamicTable";
import PageName from "../../component/PageName";
import { useSelector } from "react-redux";

const Station = () => {
  const { stationDetails } = useSelector((state) => state.user);
  const tableHead = [
    "S. No",
    "Station ID",
    "Station Name",
    "Contact",
    "Action",
  ];
  const [stationCount, setStationCount] = useState(0);
  const [allstationRaw, setAllStationRaw] = useState([]);
  const [allstation, setAllstation] = useState([]);
  useEffect(() => {
    if (stationDetails !== null && stationDetails) {
      setAllStationRaw(stationDetails.data);
      setStationCount(stationDetails.count);
    }
  }, [stationDetails]);
  useEffect(() => {
    const modifiedData = allstationRaw.map((station) => {
      return {
        item01: station.stationId,
        item02: station.stationName,
        item03: station.number,
        img01: <VisibilityIcon />,
        img02: <EditIcon />,
        img03: <DeleteIcon />,
        unqiueKey: station.stationId,
      };
    });
    // console.log("address",station.address1);
    setAllstation(modifiedData);
  }, [allstationRaw]);

  return (
    <div>
      <div>
        <PageName pageName="Manage Station" href={RoutePath.AddStation}/>
        <div className="p-4">
          <Statusbar
            isActive={true}
            src={TrackVehical}
            count={stationCount || 0}
            title="Total station"
            rating="4% (30 days)"
          />
        </div>
        <div>
          {/* <div className="flex justify-center gap-2 sm:justify-end items-center px-4">
            <AddFilterButton href={RoutePath.AddStation} />
            <TitleFilter />
          </div> */}
          <DynamicTable
            tableHeadRow={tableHead}
            data={allstation}
            DeleteUrl={"station/delete"}
            eyeButton={RoutePath.EditStationLink}
            editButton={RoutePath.EditStationLink}
          />
        </div>
      </div>
    </div>
  );
};

export default Station;
