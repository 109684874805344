import React, { useEffect, useState } from "react";
// import { RoutePath } from "../../router/RoutePath";
import { TitleFilter } from "../../../component/TitileFilter";
import CheckCircleOutlineOutlinedIcon from "@mui/icons-material/CheckCircleOutlineOutlined";
// import { useState, useEffect } from "react";
import { Statusbar } from "../../../component/StatusBar";
import Delivered from "../../../img/Delivered.svg";
import Icon_Order from "../../../img/Icon_Order.svg";
import { DynamicTable } from "../../../tables/DynamicTable";
// import { getAxiosWithToken } from "../../axios/AxiosObj";
import PageName from "../../../component/PageName";
import { Link } from "react-router-dom";
import { RoutePath } from "../../../router/RoutePath";
import { PrimeButton } from "../../../component/PrimeButton";

const FinalDelivery = () => {
  const tableHead = [
    "S. No",
    "Order Id",
    "Name",
    "Start Station",
    "",
    "Name",
    "Destination Station",
    "Driver",
    "Vehicle",
    // "Select",
  ];

  const Data = [
    {
      item01: "Order Id",
      item02: "from Name",
      item03: "From Address",
      itemto:"to",
      item05: "To Name",
      item06: "To Address",
      item07: "Driver",
      item08: "Vehicle",
      // checkBox: <CheckCircleOutlineOutlinedIcon />,
    },
  ];
  // console.log("address",customer.address1);

  

  return (
    <div>
      <div>
        <PageName pageName="Final Delivery" />
        <div className="grid sm:grid-cols-2  justify-items-stretch gap-6 p-4 cursor-pointer">
          <Link to={RoutePath.AssignDelivery}>
          <Statusbar
            src={Icon_Order}
            count={0}
            title="Assign Delivery"
            rating="4% (30 days)"
          />
          </Link>
          <Link to={RoutePath.FinalDelivery}>
          <Statusbar
            isActive={true}
            src={Delivered}
            count={0}
            title="Final Delivery"
            rating="4% (30 days)"
          />
          </Link>
        </div>
        <div>
          <div className="flex justify-center gap-2 sm:justify-end items-center px-4">
            {/* <AddFilterButton href={RoutePath.AddCustomerForm} /> */}
            <TitleFilter />
          </div>
          <DynamicTable
            tableHeadRow={tableHead}
            data={Data}
            DeleteUrl={"customer/delete"}
            // eyeButton={RoutePath.EditCustomerDetailLink}
            // editButton={RoutePath.EditCustomerDetailLink}
          />
        </div>
      </div>
    </div>
  );
};

export default FinalDelivery;
