import React from "react";
import { RoutePath } from "../../../router/RoutePath";
import VisibilityIcon from "@mui/icons-material/Visibility";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import PinDropOutlinedIcon from "@mui/icons-material/PinDropOutlined";
import DoNotDisturbOffOutlinedIcon from '@mui/icons-material/DoNotDisturbOffOutlined';
import { useState, useEffect } from "react";
import { Statusbar } from "../../../component/StatusBar";
import customer from "../../../img/customer.svg";
import Delivered from "../../../img/Delivered.svg";
import Order from "../../../img/Order.svg";
import Group from "../../../img/Group.svg";
import { DynamicTable } from "../../../tables/DynamicTable";
import PageName from "../../../component/PageName";
import { getAxiosWithToken } from "../../../axios/AxiosObj";
import {
  alertResponseMsgError,
  // alertResponseMsgSuccess,
} from "../../../function/utilies";
import {formateDate,formatDateToISO} from "../../../function/utilies";
import { Link } from "react-router-dom";

const CustomerQuotation = () => {
  const tableHead = [
    "S.No.",
    "Booking ID",
    "Date",
    "Name",
    "Pick up",
    "",
    "Name",
    "Drop",
    "Contact",
    "Action",
  ];
  
  const [rawData, setRawdata] =useState([]);
  const [dataCount, setDataCount] = useState([]);
  const [allQuotation, setAllQuotation] = useState([]);


  const manageData =async()=>{
    const response = await getAxiosWithToken({
      method :"get",
      url: "quotation/all",
    });
    if(response !== null && response.data !== null && response.data.data !== null){
      // alertResponseMsgSuccess(response.data.msg);
      setRawdata(response.data.data);
      setDataCount(response.data.count || 0)
    }else {
      alertResponseMsgError(response.data.msg);
      
    }
  };

  useEffect(()=>{
    manageData();
  },[]);
  
  useEffect(() => {
    const modifiedData = rawData.map((booking) => {
      let senderDetails = booking.senderDetails[0];
      let receiverDtails = booking.receiverDetails[0];
      const formattedDate = formatDateToISO(
        formateDate(booking.quotationDate)
      );
      return {
        item01: booking.quotationOrderId,
        item02: formattedDate,
        item03: senderDetails.personName,
        item04: senderDetails.address,
        itemto: "to",
        item05: receiverDtails.personName,
        item06: receiverDtails.address,
        item07: booking.mobile,
        item08: booking.toCustomerName,
        img01: <VisibilityIcon />,
        img02: <EditIcon />,
        img03: <DeleteIcon />,
        cancel: <DoNotDisturbOffOutlinedIcon />,
        itemLocation: <PinDropOutlinedIcon />,
        unqiueKey: booking.quotationOrderId,
      };
    });
    setAllQuotation(modifiedData);
  }, [rawData]);

  const [addform, setAddForm] = useState(false);
  const [activeTableTab, setActiveTableTab] = useState("");

  useEffect(() => {
    setActiveTableTab("availableVehicles");
    setAddForm(true);
  }, []);

  return (
    <div>
      <div>
      <PageName pageName="Customer Quotation" href={RoutePath.CustomerQuotationForm} />
        <div className="grid sm:grid-cols-2 lg:grid-cols-4 justify-items-stretch gap-6 p-4 cursor-pointer">
        <Link to={RoutePath.CustomerQuotation}>
          <Statusbar
            src={customer}
            count={dataCount}
            isActive={true}
            title="Booking Requests"
            rating="4% (30 days)"
          />
        </Link>
        <Link to={RoutePath.CustomerQuotation}>
          <Statusbar
            src={Delivered}
            count="357"
            title="Active Deliveries"
            rating="4% (30 days)"
          />
        </Link>
        <Link to={RoutePath.CustomerQuotation}>
          <Statusbar
            src={Order}
            count="65"
            title="Total Cancelled"
            rating="25% (30 days)"
          />
        </Link>
        <Link to={RoutePath.CustomerQuotation}>
          <Statusbar
            src={Group}
            count="Rs 12800"
            title="Total Revenue"
            rating="25% (30 days)"
          />
        </Link>
      </div>
        <div>
        {/* <div className="flex justify-center gap-2 sm:justify-end items-center px-4">
            <AddFilterButton href={RoutePath.CustomerQuotationForm} />
            <TitleFilter />
          </div> */}
          <DynamicTable
            tableHeadRow={tableHead}
            data={allQuotation}
            addform={addform}
            DeleteUrl={"quotation/delete"}
            eyeButton={RoutePath.EditCustomerQuotationDetailLink}
            editButton={RoutePath.EditCustomerQuotationDetailLink}
          />
        </div>
      </div>
    </div>
  );
};

export default CustomerQuotation;
