import React, { useState, useEffect, useRef } from "react";
import InputError from "../../../component/InputError";
import profile from "../../../img/profile.jpg";
import { useParams } from "react-router-dom";
import { BackButton, PrimeButton } from "../../../component/PrimeButton";
import Dropdown from "../../../component/Dropdown";
import * as Yup from "yup";
import { useFormik } from "formik";
import { getAxiosWithToken } from "../../../axios/AxiosObj";
import {
  alertResponseMsgError,
  alertResponseMsgSuccess,
  valuesCheck,
  valuesEmpty,
} from "../../../function/utilies";
import StateName from "../../../component/dropdown/StateName";
import CityName from "../../../component/dropdown/CityName";
import { useSelector } from "react-redux";


const initialValues = {
  firstName: "",
  middleName: "",
  lastName: "",
  startStation: "",
  contact: "",
  email: "",
  locality: "",
  city: "",
  state: "",
  district: "",
  pincode: "",
  IDNumber: "",
  document: "",
  photo: "",
};

const EditAdminDetail = () => {
  const { apiUrl } = useSelector((state) => state.user);

  const [buttonLoader, setButtonLoader] = useState(false);
  const params = useParams();
  const [tableRowData, setTableRowData] = useState();

  const ConstomerCreateSchema = Yup.object({
    firstName: Yup.string().required("First Name should not be empty"),
    lastName: Yup.string().required("Last Name should not be empty"),
    startStation: Yup.string().required("Last Name should not be empty"),
    contact: Yup.number().required("Contact should not be empty"),
    email: Yup.string().required("Email should not be empty"),
    locality: Yup.string().required("locality should not be empty"),
    city: Yup.string().required("city should not be empty"),
    state: Yup.string().required("state should not be empty"),
    district: Yup.string().required("District should not be empty"),
    pincode: Yup.number().required("Pin Code should not be empty"),
    // IDNumber: Yup.number().required("ID Number should not be empty"),
    // document: Yup.string().required("Upload Document"),
    // photo: Yup.string().required("Upload Photo"),
  });
  const { errors, touched, values, handleChange, handleSubmit } = useFormik({
    initialValues: initialValues,
    validationSchema: ConstomerCreateSchema,
    onSubmit: async (values, action) => {
      setButtonLoader(true);
      //valuesChkeck function for  compare the valuse ot update data
      let data = await valuesCheck(values, tableRowData);      
      let username;
      if (params && params.username && params.username !== " ") {
        username = params.username;
      }

      try {
        const response = await getAxiosWithToken({
          method: "Patch",
          url: `sub/admin/update/${username}`, // Adjust the API endpoint as needed
          data: data,
        });
        // API response 200 201
        if (response.data.success) {
          alertResponseMsgSuccess(response.data.msg);
          setButtonLoader(false);
        } else {
          alertResponseMsgError(response.data.msg);
        }
      } catch (error) {
        console.error(error);
        alertResponseMsgError(error.response.data.msg);
      }
    },
  });

  const getDeatils = async (username) => {
    try {
      const response = await getAxiosWithToken({
        method: "get",
        url: `sub/admin/details/${username}`, // Adjust the API endpoint as needed
      });
      if (response && response.data && response.data.data) {
        setTableRowData(response.data.data);
      }
    } catch (e) {
      // window.location.href = "/sub/admin";
      // if (!e.response.data.success) {
      //   window.location.href = "/sub/admin";
      // }
    }
  };

  useEffect(() => {
    if (tableRowData) {
      values.firstName = tableRowData.firstName;
      values.middleName = tableRowData.middleName;
      values.lastName = tableRowData.lastName;
      values.startStation = tableRowData.stationId;
      values.contact = tableRowData.mobile;
      values.email = tableRowData.email;
      values.locality = tableRowData.address1;
      values.district = tableRowData.district;
      values.pincode = tableRowData.pincode;
      values.state = tableRowData.state;
      values.city = tableRowData.city;
      values.idProofNo = tableRowData.idProofNo;
      values.document = tableRowData.document;
      values.profilepic = tableRowData.profilepic;
    }
  }, [tableRowData]);
  // console.log("values.profilepic", values.profilepic);
  const valueRef = useRef(values);

  useEffect(() => {
    if (params && params.username && params.username !== " ") {
      valueRef.current = valuesEmpty(values, "");
      getDeatils(params.username);
    } else {
      window.location.href = "/sub/admin";
    }
  }, [params]);

  //-------------------------------------------------- Station Detail --------------------------------------------------//
  const [allStationRaw, setAllStationRaw] = useState([]);
  const stationData = async () => {
    const response = await getAxiosWithToken({
      method: "get",
      url: "station/all",
    });
    if (response && response.data && response.data.data) {
      setAllStationRaw(response.data.data);
    }
    // console.log(response);
  };
  useEffect(() => {
    stationData();
  }, []);

  //----------------------------------------------- upload Document -------------------------------------------//
  const [photo, setPhoto] = useState(null);
  // const [PhotoErr, setPhotoErr] = useState(null);
  const [photoPerview, setPhotoPerview] = useState(null);
  const [IdDoc, setIdDoc] = useState(null);
  // const [PhotoErr, setPhotoErr] = useState(null);
  const [idDocPerview, setIdDocPerview] = useState(null);

  const handleChangeFile = (e) => {
    const { name, files } = e.target;
    if (name === "Photo") {
      let driverImage = files[0];
      if (driverImage.size > 2000000) {
        console.log("Error Size");
        alertResponseMsgError("Image Should be less then 2 mb");
        return false;
      }
      if (
        driverImage.type.includes("image/jpeg") ||
        driverImage.type.includes("image/jpg") ||
        driverImage.type.includes("image/png")
      ) {
        const imageUrl = URL.createObjectURL(driverImage);
        setPhotoPerview(imageUrl);
        setPhoto(driverImage);
      } else {
        alertResponseMsgError("Image formate Should be jpeg, jpg, png");
      }
    }
    if (name === "idProofDoc") {
      let IDProof = files[0];
      if (IDProof.size > 2000000) {
        console.log("Error Size");
        alertResponseMsgError("Image Should be less then 2 mb");
        return false;
      }
      if (
        IDProof.type.includes("image/jpeg") ||
        IDProof.type.includes("image/jpg") ||
        IDProof.type.includes("image/png")
      ) {
        const imageUrl = URL.createObjectURL(IDProof);
        setIdDocPerview(imageUrl);
        setIdDoc(IDProof);
      } else {
        alertResponseMsgError("Image formate Should be jpeg, jpg, png");
      }
    }
  };

  //------------------------------Profile Picture--------------------------------
  const imageUrl = values.profilepic ? `${apiUrl}/${values.profilepic}` : profile;

  return (
    <div>
      <div className="bg-backgroundColor rounded-xl p-8">
        <BackButton
          btnName="Edit Sub Admin Details"
          backtoPage="/managedriver"
        />
        <form onSubmit={handleSubmit}>
          <div className="md:grid grid-cols-6 gap-4 text-left text-mainTxtColor">
            <div className="col-span-6 gap-6 ">
              <div className="flex items-center text-mainTxtColor gap-3 px-3">
                <img
                  src={imageUrl}
                  alt="user.name"
                  className="w-28 h-28 rounded-full bg-slate-100 ring-2 ring-primeryBgColor"
                  loading="lazy"
                />
                <div className="w-full">
                  <input
                    type="text"
                    name="firstName"
                    value={"" || values.firstName}
                    onChange={handleChange}
                    className="w-full rounded-inputBRedius bg-inputBgColor p-2"
                    placeholder="First Name"
                  />
                  <InputError
                    error={errors.firstName}
                    touched={touched.firstName}
                  />
                </div>
                <div className="w-full">
                  <input
                    type="text"
                    name="middleName"
                    value={"" || values.middleName}
                    onChange={handleChange}
                    className="w-full rounded-inputBRedius bg-inputBgColor p-2 "
                    placeholder="Middle Name"
                  />
                  <InputError
                    error={errors.middleName}
                    touched={touched.middleName}
                  />
                </div>
                <div className="w-full">
                  <input
                    type="text"
                    name="lastName"
                    value={"" || values.lastName}
                    onChange={handleChange}
                    className="w-full rounded-inputBRedius bg-inputBgColor p-2 "
                    placeholder="Last Name"
                  />
                  <InputError
                    error={errors.lastName}
                    touched={touched.lastName}
                  />
                </div>
              </div>
            </div>
            <div className="col-span-3 gap-6 lg:flex py-2 items-center">
              <label className=" w-36 md:flex-none text-sm font-medium">
                Station
              </label>
              <div className="w-full ">
                <Dropdown
                  label={"Station"}
                  options={allStationRaw}
                  handleChange={handleChange}
                  name={"startStation"}
                  value={"" || values.startStation}
                />
                <InputError
                  error={errors.startStation}
                  touched={touched.startStation}
                />
              </div>
            </div>

            <div className="col-span-3 gap-6  lg:flex py-2  items-center">
              <label className=" w-36 md:flex-none text-sm font-medium ">
                Contact Number
              </label>
              <div className="w-full">
                <input
                  type="text"
                  name="contact"
                  value={"" || values.contact}
                  onChange={handleChange}
                  className="w-full rounded-inputBRedius bg-inputBgColor p-2"
                  placeholder="Contact"
                />
                <InputError error={errors.contact} touched={touched.contact} />
              </div>
            </div>

            <div className="col-span-3 gap-6 lg:flex py-2 items-center">
              <label
                for="Email"
                className=" w-36 md:flex-none text-sm font-medium "
              >
                Email ID
              </label>
              <div className="w-full">
                <input
                  type="email"
                  name="email"
                  value={"" || values.email}
                  onChange={handleChange}
                  className="w-full rounded-inputBRedius bg-inputBgColor p-2"
                  placeholder="Email"
                />
                <InputError error={errors.email} touched={touched.email} />
              </div>
            </div>
            <div className="div col-span-6 font-semibold text-left text-lg">
              Addresss
            </div>
            <div className="col-span-6 gap-6  lg:flex py-2  items-center">
              <label className="w-36 md:flex-none text-sm font-medium ">
                Locality / Street
              </label>
              <div className="w-full">
                <input
                  type="text"
                  name="locality"
                  value={"" || values.locality}
                  onChange={handleChange}
                  className="w-full rounded-inputBRedius bg-inputBgColor p-2"
                  placeholder="Locality / Street"
                />
                <InputError
                  error={errors.locality}
                  touched={touched.locality}
                />
              </div>
            </div>
            <div className="col-span-3 gap-6  lg:flex py-2  items-center">
              <label className=" w-36 md:flex-none text-sm font-medium ">
                District
              </label>
              <div className="w-full">
                <input
                  type="text"
                  name="district"
                  value={"" || values.district}
                  onChange={handleChange}
                  className="w-full rounded-inputBRedius bg-inputBgColor p-2"
                  placeholder="District"
                />
                <InputError
                  error={errors.district}
                  touched={touched.district}
                />
              </div>
            </div>
            <div className="col-span-3 gap-6  lg:flex py-2 items-center">
              <label className=" w-36 md:flex-none text-sm font-medium ">
                State
              </label>
              <div className="w-full">
                <StateName
                  handleChange={handleChange}
                  name={"state"}
                  value={values.state}
                />
              </div>
            </div>
            <div className="col-span-3 gap-6  lg:flex py-2 items-center">
              <label className=" w-36 md:flex-none text-sm font-medium ">
                City
              </label>
              <div className="w-full">
                <CityName
                  handleChange={handleChange}
                  name={"city"}
                  value={values.city}
                  stateId={values.state}
                />
              </div>
            </div>
            <div className="col-span-3 gap-6  lg:flex py-2  items-center">
              <label className=" w-36 md:flex-none text-sm font-medium ">
                Pin Code
              </label>
              <div className="w-full">
                <input
                  type="text"
                  name="pincode"
                  value={"" || values.pincode}
                  onChange={handleChange}
                  className="w-full rounded-inputBRedius bg-inputBgColor p-2"
                  placeholder="Pin Code"
                />
                <InputError error={errors.pincode} touched={touched.pincode} />
              </div>
            </div>
            <div className=" col-span-6 font-semibold text-left text-lg">
              Documents
            </div>
            <div className="col-span-3 gap-6  lg:flex py-2  items-center">
              <label className=" w-36 md:flex-none text-sm font-medium ">
                ID Proof
              </label>
              <div className="w-full">
                <input
                  type="text"
                  name="idProofNo"
                  value={"" || values.idProofNo}
                  onChange={handleChange}
                  className="w-full rounded-inputBRedius bg-inputBgColor p-2"
                  placeholder="Id Proof No."
                />
                <InputError
                  error={errors.idProofNo}
                  touched={touched.idProofNo}
                />
              </div>
              <div className="w-full p-2 bg-white rounded-inputBRedius hover:bg-blue-100">
                <input
                  type="file"
                  name="idProofDoc"
                  onChange={handleChangeFile}
                  className="w-full file:bg-white
                file:rounded file:border-0
                 file:font-semibold
                file:text-primeryBgColor"
                  placeholder="Id Proof"
                  accept="image/png, image/jpeg, image/jpg"
                />
                {idDocPerview ? (
                  <img src={idDocPerview} alt="Id Proof" />
                ) : null}
              </div>
            </div>
            <div className="col-span-3 gap-6  lg:flex py-2  items-center">
            <label className=" w-36 md:flex-none text-sm font-medium ">
              Upload Admin's Photo
            </label>
            <div className="w-full p-2 bg-white rounded-inputBRedius hover:bg-blue-100">
              <input
                type="file"
                name="Photo"
                onChange={handleChangeFile}
                className="w-full file:bg-white
                file:rounded file:border-0
                 file:font-semibold
                file:text-primeryBgColor"
                placeholder="Sub Admin's Photo"
                accept="image/png, image/jpeg, image/jpg"
              />
              {photoPerview ? (
              <img src={photoPerview} alt="dd"/>
            ):(null)}
            </div>
          </div>
          </div>
          <div className=" text-white mt-4 ">
            <div className="mt-12">
              <PrimeButton btnName="Update" buttonLoader={buttonLoader} />
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default EditAdminDetail;
