import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import InputError from "../../component/InputError";
import profile from "../../img/profile.jpg";
import { BackButton } from "../../component/PrimeButton";
import * as Yup from "yup";
import { useFormik } from "formik";
import { getAxiosWithToken } from "../../axios/AxiosObj";
import {
  alertResponseMsgError,
  alertResponseMsgSuccess,
  valuesCheck,
} from "../../function/utilies";
import StateName from "../../component/dropdown/StateName";
import CityName from "../../component/dropdown/CityName";

const initialValues = {
  firstName: "",
  middleName: "",
  lastName: "",
  startStation: "",
  contact: "",
  email: "",
  locality: "",
  city: "",
  state: "",
  district: "",
  pincode: "",
  idProofNo: "",
  document: "",
  photo: "",
};

const ActiveUserDetail = () => {
  const [UserDetail, setUserDetail] = useState([]);
  const [stationDetail, setStationDetail] = useState([]);

  const { userDetails } = useSelector((state) => state.user);
  console.log("userDetails",userDetails)

  useEffect(() => {
    if (userDetails !== null && userDetails.data) {
      setUserDetail(userDetails.data);
      setStationDetail(userDetails.stationDetails);
    }
  }, [userDetails]);

  const [tableRowData, setTableRowData] = useState();

  const ConstomerCreateSchema = Yup.object({
    firstName: Yup.string().required("First Name should not be empty"),
    lastName: Yup.string().required("Last Name should not be empty"),
    // startStation: Yup.string().required("Last Name should not be empty"),
    contact: Yup.number().required("Contact should not be empty"),
    email: Yup.string().required("Email should not be empty"),
    locality: Yup.string().required("locality should not be empty"),
    city: Yup.string().required("city should not be empty"),
    state: Yup.string().required("state should not be empty"),
    district: Yup.string().required("District should not be empty"),
    pincode: Yup.number().required("Pin Code should not be empty"),
    // idProofNo: Yup.number().required("ID Number should not be empty"),
    // document: Yup.string().required("Upload Document"),
    // photo: Yup.string().required("Upload Photo"),
  });
  const { errors, touched, values, handleChange, handleSubmit } = useFormik({
    initialValues: initialValues,
    validationSchema: ConstomerCreateSchema,
    onSubmit: async (values, action) => {
      let data = await valuesCheck(values, tableRowData);
      let username;
      

      try {
        const response = await getAxiosWithToken({
          method: "Patch",
          url: `sub/admin/update/${username}`, // Adjust the API endpoint as needed
          data: data,
        });
        // API response 200 201
        if (response.data.success) {
          alertResponseMsgSuccess(response.data.msg);

          // action.resetForm();
        } else {
          alertResponseMsgError(response.data.msg);
        }
      } catch (error) {
        console.error(error);
        alertResponseMsgError(error.response.data.msg);
        // alertResponseMsgError(error.response.msg);
      }
    },
  });
//  console.log("run run", UserDetail, stationDetail);

useEffect(() => {
  if (userDetails && UserDetail && stationDetail) {
    values.username = UserDetail.username;
    values.firstName = UserDetail.firstName;
    values.middleName = UserDetail.middleName;
    values.lastName = UserDetail.lastName;
    values.startStation = stationDetail.stationName;
    values.contact = UserDetail.mobile;
    values.email = UserDetail.email;
    values.locality = UserDetail.address1;
    values.district = UserDetail.district;
    values.pincode = UserDetail.pincode;
    values.state = UserDetail.state;
    values.city = UserDetail.city;
    values.idProofNo = UserDetail.idProofNo;
    values.document = UserDetail.document;
    values.photo = UserDetail.photo;
  }
}, [userDetails, UserDetail, stationDetail]);


  return (
    <div>
      <div className="bg-backgroundColor rounded-xl p-8">
        <BackButton btnName={`Your Profile -${values.username}`} />
        <form onSubmit={handleSubmit}>
          <div className="md:grid grid-cols-6 gap-4 text-left text-mainTxtColor">
            <div className="col-span-6 gap-6 ">
              <div className="flex items-center text-mainTxtColor gap-3 px-3">
                <img
                  src={profile}
                  alt="user.name"
                  className="w-28 h-28 rounded-full bg-slate-100 ring-2 ring-primeryBgColor"
                  loading="lazy"
                />
                <div className="w-full">
                  <input
                    type="text"
                    name="firstName"
                    value={"" || values.firstName}
                    onChange={handleChange}
                    className="w-full rounded-inputBRedius bg-inputBgColor p-2"
                    placeholder="First Name"
                  />
                  <InputError
                    error={errors.firstName}
                    touched={touched.firstName}
                  />
                </div>
                <div className="w-full">
                  <input
                    type="text"
                    name="middleName"
                    value={"" || values.middleName}
                    onChange={handleChange}
                    className="w-full rounded-inputBRedius bg-inputBgColor p-2 "
                    placeholder="Middle Name"
                  />
                  <InputError
                    error={errors.middleName}
                    touched={touched.middleName}
                  />
                </div>
                <div className="w-full">
                  <input
                    type="text"
                    name="lastName"
                    value={"" || values.lastName}
                    onChange={handleChange}
                    className="w-full rounded-inputBRedius bg-inputBgColor p-2 "
                    placeholder="Last Name"
                  />
                  <InputError
                    error={errors.lastName}
                    touched={touched.lastName}
                  />
                </div>
              </div>
            </div>
            <div className="col-span-3 gap-6 lg:flex py-2 items-center">
              <label className=" w-36 md:flex-none text-sm font-medium">
                Station
              </label>
              <div className="w-full ">
                <input
                  type="text"
                  name="station"
                  value={"" || values.startStation}
                  onChange={handleChange}
                  className="w-full rounded-inputBRedius bg-inputBgColor p-2"
                  placeholder="Station"
                />
                <InputError
                  error={errors.startStation}
                  touched={touched.startStation}
                />
              </div>
            </div>

            <div className="col-span-3 gap-6  lg:flex py-2  items-center">
              <label className=" w-36 md:flex-none text-sm font-medium ">
                Contact Number
              </label>
              <div className="w-full">
                <input
                  type="text"
                  name="contact"
                  value={"" || values.contact}
                  onChange={handleChange}
                  className="w-full rounded-inputBRedius bg-inputBgColor p-2"
                  placeholder="Contact"
                />
                <InputError error={errors.contact} touched={touched.contact} />
              </div>
            </div>

            <div className="col-span-3 gap-6 lg:flex py-2 items-center">
              <label
               
                className=" w-36 md:flex-none text-sm font-medium "
              >
                Email ID
              </label>
              <div className="w-full">
                <input
                  type="email"
                  name="email"
                  value={"" || values.email}
                  onChange={handleChange}
                  className="w-full rounded-inputBRedius bg-inputBgColor p-2"
                  placeholder="Email"
                />
                <InputError error={errors.email} touched={touched.email} />
              </div>
            </div>
            <div className="div col-span-6 font-semibold text-left text-lg">
              Addresss
            </div>
            <div className="col-span-6 gap-6  lg:flex py-2  items-center">
              <label className="w-36 md:flex-none text-sm font-medium ">
                Locality / Street
              </label>
              <div className="w-full">
                <input
                  type="text"
                  name="locality"
                  value={"" || values.locality}
                  onChange={handleChange}
                  className="w-full rounded-inputBRedius bg-inputBgColor p-2"
                  placeholder="Locality / Street"
                />
                <InputError
                  error={errors.locality}
                  touched={touched.locality}
                />
              </div>
            </div>
            <div className="col-span-3 gap-6  lg:flex py-2  items-center">
              <label className=" w-36 md:flex-none text-sm font-medium ">
                District
              </label>
              <div className="w-full">
                <input
                  type="text"
                  name="district"
                  value={"" || values.district}
                  onChange={handleChange}
                  className="w-full rounded-inputBRedius bg-inputBgColor p-2"
                  placeholder="District"
                />
                <InputError
                  error={errors.district}
                  touched={touched.district}
                />
              </div>
            </div>
            <div className="col-span-3 gap-6  lg:flex py-2 items-center">
              <label className=" w-36 md:flex-none text-sm font-medium ">
                State
              </label>
              <div className="w-full">
                <StateName
                  handleChange={handleChange}
                  name={"state"}
                  value={values.state}
                />
              </div>
            </div>
            <div className="col-span-3 gap-6  lg:flex py-2 items-center">
              <label className=" w-36 md:flex-none text-sm font-medium ">
                City
              </label>
              <div className="w-full">
                <CityName
                  handleChange={handleChange}
                  name={"city"}
                  value={values.city}
                  stateId={values.state}
                />
              </div>
            </div>
            <div className="col-span-3 gap-6  lg:flex py-2  items-center">
              <label className=" w-36 md:flex-none text-sm font-medium ">
                Pin Code
              </label>
              <div className="w-full">
                <input
                  type="text"
                  name="pincode"
                  value={"" || values.pincode}
                  onChange={handleChange}
                  className="w-full rounded-inputBRedius bg-inputBgColor p-2"
                  placeholder="Pin Code"
                />
                <InputError error={errors.pincode} touched={touched.pincode} />
              </div>
            </div>
            <div className=" col-span-6 font-semibold text-left text-lg">
              Documents
            </div>
            <div className="col-span-3 gap-6  lg:flex  items-center">
              <label className=" w-36 md:flex-none text-sm font-medium ">
                ID Proof
              </label>
              <div className="w-full">
                <input
                  type="text"
                  name="idProofNo"
                  value={"" || values.idProofNo}
                  onChange={handleChange}
                  className="w-full rounded-inputBRedius bg-inputBgColor p-2"
                  placeholder="ID Number"
                />
                <InputError
                  error={errors.idProofNo}
                  touched={touched.idProofNo}
                />
              </div>
            </div>
          </div>
          <div className=" text-white mt-4 ">
            {/* <div className="mt-12">
              <PrimeButton btnName="Update" buttonLoader={buttonLoader} />
            </div> */}
          </div>
        </form>
      </div>
    </div>
  );
};

export default ActiveUserDetail;
