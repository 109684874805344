import download from "../img/download.svg";
import { LineChart } from "@mui/x-charts/LineChart";

const dayOrder = [4000];
const xLabels = [
  "Sunday",
  "Monday",
  "Thuesday",
  "Wednesday",
  "thursday",
  "Friday",
  "Saturday",
];

export default function TotalRevenueChart({ orderCount }) {
  const currentDate = new Date();
  const day = currentDate.getDay();
  return (
    <div className="text-left">
      <div className="flex justify-between">
        <h1>Chart Order</h1>
        <div className="flex gap-2 border-2 p-2 rounded-xl border-blue-400">
          {/* <img src={download} alt="" /> */}
          {` ${xLabels[day]}`}
        </div>
      </div>
      <LineChart
        //   width={500}
        height={200}
        series={[{ data: [orderCount], area: true }]}
        xAxis={[{ scaleType: "point", data: xLabels }]}
        sx={{
          ".MuiLineElement-root": {
            display: "",
          },
        }}
      />
    </div>
  );
}
