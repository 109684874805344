import React, { useEffect, useState } from "react";
import { TitleFilter } from "../../../component/TitileFilter";
import { Statusbar } from "../../../component/StatusBar";
import Delivered from "../../../img/Delivered.svg";
import Icon_Order from "../../../img/Icon_Order.svg";
import { DynamicTable } from "../../../tables/DynamicTable";
import PageName from "../../../component/PageName";
import { Link } from "react-router-dom";
import { RoutePath } from "../../../router/RoutePath";
import { PrimeButton } from "../../../component/PrimeButton";
import { getAxiosWithToken } from "../../../axios/AxiosObj";
import { alertResponseMsgError } from "../../../function/utilies";
import { useSelector } from "react-redux";

const AssignDelivery = () => {
  
  const { stationDetails,bookingDeatils } = useSelector((state) => state.user);
  const tableHead = [
    "S. No",
    "Order Id",
    "Name",
    "Start Station",
    "",
    "Name",
    "Destination Station",
    "Select",
  ];
  
  //------------------------------------------order -----------------------------------///
  const [startStation, setStartStation] = useState([]);

  const [bookingCount, setBookingCount] = useState(0);
  const [allBookingRaw, setAllBookingRaw] = useState([]);

  const [allBooking, setAllBooking] = useState([]);

  useEffect(() => {
    if (bookingDeatils !== null && bookingDeatils.data) {
      setBookingCount(bookingDeatils.count);
      setAllBookingRaw(bookingDeatils.data);

    }
  }, [bookingDeatils]);
  
  useEffect(() => {
    const modifiedData = allBookingRaw.map((booking) => {
      let senderDetails = booking.senderDetails[0];
      let receiverDtails = booking.receiverDetails[0];
      return {
        item01: booking.bookingOrderId,
        item02: senderDetails.personName,
        item03: startStation,
        item04: senderDetails.address,
        itemto: "to",
        item05: receiverDtails.personName,
        item06: receiverDtails.address,
        item07: booking.mobile,
        item08: booking.toCustomerName,
        // img01: <VisibilityIcon />,
        // img02: <EditIcon />,
        // img03: <DeleteIcon />,
        // cancel: <DoNotDisturbOffOutlinedIcon />,
        // itemLocation: <PinDropOutlinedIcon />,
        unqiueKey: booking.bookingOrderId,
      };
    });
    setAllBooking(modifiedData);
  }, [allBookingRaw]);

  //-------------------------------------------------- get Driver Details -----------------------------------------------//
  const [dataLoading, setDataLoading] = useState(false);
  const [allDriverRaw, setallDriverRaw] = useState([]);
  const driverManageData = async () => {
    // setDataLoading(true)
    const response = await getAxiosWithToken({
      method: "get",
      url: "driver/all",
    });
    if (response && response.data && response.data.data) {
      setallDriverRaw(response.data.data);
      // setDriverCount(response.data.count || 0);
    } else {
      alertResponseMsgError(response.data.msg);
    }
  };
  useEffect(() => {
    driverManageData();
  }, [dataLoading]);
  //-------------------------------------------------- get Vehicle Details -----------------------------------------------//
  const [allVehicleData, setallVehicleData] = useState([]);

  const vehicleManageData = async () => {
    // setDataLoading(true)
    const response = await getAxiosWithToken({
      method: "get",
      url: "vehicle/all",
    });
    if (response && response.data && response.data.data) {
      setallVehicleData(response.data.data);
    }
  };

  useEffect(() => {
    vehicleManageData();
  }, [dataLoading]);

  //-------------------------------------------------- get Station Details -----------------------------------------------//
  const [stationDetail, setStationDetail] = useState([]);

  // console.log("stationDetaiasdasdasd",stationDetail)
  useEffect(() => {
    if (stationDetails !== null && stationDetails) {
      setStationDetail(stationDetails.data);
    }
  }, [stationDetails]);
  useEffect(() => {
    const startStation = stationDetail.find((station) => station.stationId === allBookingRaw.startStation);
    setStartStation(startStation);
  }, [allBookingRaw, stationDetail]);

  console.log("startStation", allBookingRaw);
  return (
    <div>
      <div>
        <PageName pageName="Manage Delivery" />
        <div className="grid sm:grid-cols-2  justify-items-stretch gap-6 p-4 cursor-pointer">
          <Link to={RoutePath.AssignDelivery}>
            <Statusbar
              isActive={true}
              src={Icon_Order}
              count={0}
              title="Assign Delivery"
              rating="4% (30 days)"
            />
          </Link>
          <Link to={RoutePath.FinalDelivery}>
            <Statusbar
              src={Delivered}
              count={0}
              title="Final Delivery"
              rating="4% (30 days)"
            />
          </Link>
        </div>
        <div>
          <div className="flex flex-wrap justify-evenly gap-2 sm:justify-between items-center px-4">
            <div className="flex items-center gap-2">
              <label className=" md:flex-none text-sm font-medium ">
                Driver
              </label>
              <select
                id="driver"
                name="driver"
                // value={values.manufactureYear}
                // onChange={handleChange}
                className="sm:w-32 w-64 rounded-inputBRedius bg-inputBgColor p-2"
              >
                <option value="">None</option>
                {allDriverRaw.map((driver, index) => (
                  <option className="p-4" kay={index} value={driver.username}>
                    {driver.firstName +
                      " " +
                      driver.middleName +
                      " " +
                      driver.lastName +
                      " (" +
                      driver.username +
                      ")"}
                  </option>
                ))}
              </select>
            </div>
            <div className="flex items-center gap-2">
              <label className=" text-sm font-medium ">
                Vehicle
              </label>
              <select
                id="vehicle"
                name="vehicle"
                // value={values.manufactureYear}
                // onChange={handleChange}
                className="sm:w-32 w-64 rounded-inputBRedius bg-inputBgColor p-2"
              >
                <option value="">None</option>
                {allVehicleData.map((driver, index) => (
                  <option className="p-4" kay={index} value={driver.vehicleId}>
                    {driver.vehicleModel + " (" + driver.ownerName + ")"}
                  </option>
                ))}
              </select>
            </div>
            <div className="flex items-center gap-2">
              <label className=" md:flex-none text-sm font-medium ">
                Device
              </label>
              <select
                id="vehicle"
                name="vehicle"
                // value={values.manufactureYear}
                // onChange={handleChange}
                className="sm:w-32 w-64 rounded-inputBRedius bg-inputBgColor p-2"
              >
                <option value="">None</option>
                {allVehicleData.map((driver, index) => (
                  <option className="p-4" kay={index} value={driver.vehicleId}>
                    {driver.vehicleModel + " (" + driver.ownerName + ")"}
                  </option>
                ))}
              </select>
            </div>
            <div className="flex items-center gap-2">
              <label className=" md:flex-none text-sm font-medium ">
                Station
              </label>
              <select
                id="station"
                name="station"
                // value={values.manufactureYear}
                // onChange={handleChange}
                className="sm:w-32 w-64 rounded-inputBRedius bg-inputBgColor p-2"
              >
                <option value="">None</option>
                {stationDetail.map((station, index) => (
                  <option className="p-4" kay={index} value={station.stionId}>
                    {station.stationName}
                  </option>
                ))}
              </select>
            </div>
            <div className="flex  gap-4">
            <PrimeButton btnName="Add" />
            <TitleFilter />
            </div>
            
          </div>
          <DynamicTable
            tableHeadRow={tableHead}
            data={allBooking}
            DeleteUrl={"customer/delete"}
            // eyeButton={RoutePath.EditCustomerDetailLink}
            // editButton={RoutePath.EditCustomerDetailLink}
          />
        </div>
      </div>
    </div>
  );
};

export default AssignDelivery;
