import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { RoutePath } from "./RoutePath";

// Pages ---------------
// Customer
import ManageCustomer from "../page/customer/ManageCustomer";
import AddCustomerForm from "../page/customer/AddCustomerForm";
import EditCustomerDetail from "../page/customer/EditCustomerDetail";

//Driver
import AddDriverForm from "../page/driver/driver/AddDriverForm";
import EditDriverDetail from "../page/driver/driver/EditDriverDetail";
import Driver from "../page/driver/driver/Driver";

//Total Driver
import TotalDriver from "../page/driver/totaldriver/TotalDriver";

//Quotation
import CustomerQuotation from "../page/quotationBooking/quotation/CustomerQuotation";
import CustomerQuotationForm from "../page/quotationBooking/quotation/CustomerQuotationForm";
import EditCustomerQuotationDetail from "../page/quotationBooking/quotation/EditCustomerQuotationDetail";

//Expenses
import ManageExpense from "../page/expenses/ManageExpense";
import ExpensesForm from "../page/expenses/ExpensesForm";
import EditExpensesDetail from "../page/expenses/EditExpensesDetail";
import InvoiceRecipt from "../page/expenses/InvoiceReceipt";

//customerledger
import CustomerLedger from "../page/ledger/CustomerLedger";
import LedgerViewDetail from "../page/ledger/LedgerViewDetail";

//Staff
import Staff from "../page/user/staff/Staff";
import AddStaff from "../page/user/staff/AddStaff";
import EditStaffDetail from "../page/user/staff/EditStaffDetail";

//Admin
import SuperAdmin from "../page/user/superadmin/SuperAdmin";
import AddSuperAdmin from "../page/user/superadmin/AddSuperAdmin";
import EditSuperAdminDetail from "../page/user/superadmin/EditSuperAdminDetail";

//subAdmin
import AddAdmin from "../page/user/admin/AddAdmin";
import EditAdminDetail from "../page/user/admin/EditAdminDetail";
import Admin from "../page/user/admin/Admin";

//Vehicle
import Vehicle from "../page/vehicle/vehicle/Vehicle";
import AddVehicle from "../page/vehicle/vehicle/AddVehicle";
import EditVehicleDetail from "../page/vehicle/vehicle/EditVehicleDetail";
//fuel
import FuelRefill from "../page/vehicle/fuel/FuelRefill";
//Maintenace
import Maintenance from "../page/vehicle/maintenance/Maintenance";
//TotalVehicle
import TotalVehicle from "../page/vehicle/totalvehicles/TotalVehicle";

//BookingRequest
import BookingRequest from "../page/managebooking/bookingRequest/BookingRequest";
import BookOrderForm from "../page/managebooking/bookingRequest/BookOrderForm";

//ActiveDelivery
import ActiveDelivery from "../page/managebooking/activedelivery/ActiveDelivery";

//TotalCanceled
import TotalCanceled from "../page/managebooking/totalcanceled/TotalCanceled";

//TotalRevenue
import TotalRevenue from "../page/managebooking/totalrevenue/TotalRevenue";

//Delivery
import DeliveryDetail from "../page/managebooking/activedelivery/DeliveryDetail";
import AssignDelivery from "../page/delivery/managedelivery/AssignDelivery";
import FinalDelivery from "../page/delivery/finaldelivery/FinalDelivery";

//Contact
import ContactList from "../page/contact/ContactList";
import EditContactList from "../page/contact/EditContactList";

//station
import Station from "../page/station/Station";
import AddStation from "../page/station/AddStation";
import EditStation from "../page/station/EditStation";

import EditBookOrder from "../page/managebooking/bookingRequest/EditBookOrder";
import Sidebar from "../component/Sidebar";
import Navbar from "../component/Navbar";
import Dashboard from "../page/Dashboard";
import GstInvoiceBill from "../form/GstInvoiceBill";
import LoginPage from "../authentication/LoginPage";
import Report from "../page/report/Report";
import Tracker from "../component/Tracker";
import InvoiceBill from "../form/InvoiceBill";
import CustomerInvoice from "../page/ledgerHistory/CustomerInvoice";
import { useSelector } from "react-redux";
import TrackerMap from "../page/map/TrackerMap";
import Mapper from "../component/Mapper";
import ActiveUserDetail from "../page/LoginUser/ActiveUserDetail";
import MapComponent from "../component/map/Map";

export default function AppRoute() {
  const { isLogged, role } = useSelector((state) => state.user);

  return (
    <>
      {isLogged ? (
        <>
          <Router>
            <Sidebar />
            <div className="right-0 bg-backgroundColor lg:ml-72 ">
              <Navbar />
              <div className="mt-28 lg:mt-16">
                <Routes>
                  <Route path="/" element={<Dashboard />}></Route>
                  <Route path="/maps" element={<MapComponent />}></Route>

                  {/* Customer */}
                  <Route
                    path={RoutePath.ManageCustomer}
                    element={<ManageCustomer />}
                  ></Route>
                  <Route
                    path={RoutePath.AddCustomerForm}
                    element={<AddCustomerForm />}
                  ></Route>
                  <Route
                    path={RoutePath.EditCustomerDetail}
                    element={<EditCustomerDetail />}
                  ></Route>
                  {/* Driver */}
                  {role === 1 ? (
                    <>
                      <Route
                        path={RoutePath.Driver}
                        element={<Driver />}
                      ></Route>
                      <Route
                        path={RoutePath.AddDriverForm}
                        element={<AddDriverForm />}
                      ></Route>
                      <Route
                        path={RoutePath.EditDriverDetail}
                        element={<EditDriverDetail />}
                      ></Route>
                      {/* total Driver */}
                      <Route
                        path={RoutePath.Driver}
                        element={<Driver />}
                      ></Route>
                      <Route
                        path={RoutePath.TotalDriver}
                        element={<TotalDriver />}
                      ></Route>
                    </>
                  ) : null}

                  {/* Quotation ----------------------------------------------------------------*/}
                  <Route
                    path={RoutePath.CustomerQuotation}
                    element={<CustomerQuotation />}
                  ></Route>
                  <Route
                    path={RoutePath.CustomerQuotationForm}
                    element={<CustomerQuotationForm />}
                  ></Route>
                  <Route
                    path={RoutePath.EditCustomerQuotationDetail}
                    element={<EditCustomerQuotationDetail />}
                  ></Route>

              

                  {/* Expenses */}
                  <Route
                    path={RoutePath.ManageExpense}
                    element={<ManageExpense />}
                  ></Route>
                  <Route
                    path={RoutePath.ExpensesForm}
                    element={<ExpensesForm />}
                  ></Route>
                  <Route
                    path={RoutePath.EditExpensesDetail}
                    element={<EditExpensesDetail />}
                  ></Route>
                  <Route
                    path={RoutePath.InvoiceRecipt}
                    element={<InvoiceRecipt />}
                  ></Route>

                  {/* customerledger */}
                  <Route
                    path={RoutePath.CustomerLedger}
                    element={<CustomerLedger />}
                  ></Route>
                  {role === 1 ? (
                    <>
                      {/* Staff */}
                      <Route path={RoutePath.Staff} element={<Staff />}></Route>
                      <Route
                        path={RoutePath.AddStaff}
                        element={<AddStaff />}
                      ></Route>
                      <Route
                        path={RoutePath.EditStaffDetail}
                        element={<EditStaffDetail />}
                      ></Route>
                      {/* Admin */}
                      <Route path={RoutePath.Admin} element={<Admin />}></Route>
                      <Route
                        path={RoutePath.AddAdmin}
                        element={<AddAdmin />}
                      ></Route>
                      <Route
                        path={RoutePath.EditAdminDetail}
                        element={<EditAdminDetail />}
                      ></Route>
                      {/* SuperAdmin */}
                      <Route
                        path={RoutePath.SuperAdmin}
                        element={<SuperAdmin />}
                      ></Route>
                      <Route
                        path={RoutePath.AddSuperAdmin}
                        element={<AddSuperAdmin />}
                      ></Route>
                      <Route
                        path={RoutePath.EditSuperAdminDetail}
                        element={<EditSuperAdminDetail />}
                      ></Route>
                    </>
                  ) : null}

                  {role === 1 ? (
                    <>
                      {/* Vehicle */}
                      <Route
                        path={RoutePath.Vehicle}
                        element={<Vehicle />}
                      ></Route>
                      <Route
                        path={RoutePath.AddVehicle}
                        element={<AddVehicle />}
                      ></Route>
                      <Route
                        path={RoutePath.EditVehicleDetail}
                        element={<EditVehicleDetail />}
                      ></Route>
                      {/* Fuel */}
                      <Route
                        path={RoutePath.FuelRefill}
                        element={<FuelRefill />}
                      ></Route>
                      {/* Maintenance */}
                      <Route
                        path={RoutePath.Maintenance}
                        element={<Maintenance />}
                      ></Route>
                      {/* Total Vehicle */}
                      <Route
                        path={RoutePath.TotalVehicle}
                        element={<TotalVehicle />}
                      ></Route>
                    </>
                  ) : null}

                  {/* BookingRequest */}
                  <Route
                    path={RoutePath.BookingRequest}
                    element={<BookingRequest />}
                  ></Route>
                  <Route
                    path={RoutePath.BookOrderForm}
                    element={<BookOrderForm />}
                  ></Route>
                  <Route
                    path={RoutePath.EditBookOrder}
                    element={<EditBookOrder />}
                  ></Route>

                  {/* ActiveDelivery */}
                  <Route
                    path={RoutePath.ActiveDelivery}
                    element={<ActiveDelivery />}
                  ></Route>
                  <Route
                    path={RoutePath.DeliveryDetail}
                    element={<DeliveryDetail />}
                  ></Route>

                  {/* TotalCanceled */}
                  <Route
                    path={RoutePath.TotalCanceled}
                    element={<TotalCanceled />}
                  ></Route>
                  {/* TotalRevenue */}
                  <Route
                    path={RoutePath.TotalRevenue}
                    element={<TotalRevenue />}
                  ></Route>

                  {/* Delivery */}
                  <Route
                    path={RoutePath.AssignDelivery}
                    element={<AssignDelivery />}
                  ></Route>
                  <Route
                    path={RoutePath.FinalDelivery}
                    element={<FinalDelivery />}
                  ></Route>

                  {/* Contact */}
                  <Route
                    path={RoutePath.ContactList}
                    element={<ContactList />}
                  ></Route>
                  <Route
                    path={RoutePath.EditContactList}
                    element={<EditContactList />}
                  ></Route>

                  {role === 1 ? (
                    <>
                      {/* Station */}
                      <Route
                        path={RoutePath.Station}
                        element={<Station />}
                      ></Route>
                      <Route
                        path={RoutePath.AddStation}
                        element={<AddStation />}
                      ></Route>
                      <Route
                        path={RoutePath.EditStation}
                        element={<EditStation />}
                      ></Route>
                    </>
                  ) : null}

                  {/* GST invoice Bill */}
                  <Route
                    path={RoutePath.GstInvoiceBill}
                    element={<GstInvoiceBill />}
                  ></Route>
                  {/* current Login user */}
                  <Route
                    path={RoutePath.ActiveUserDetail}
                    element={<ActiveUserDetail />}
                  ></Route>

                  <Route path={RoutePath.Report} element={<Report />}></Route>

                  <Route
                    path={RoutePath.InvoiceBill}
                    element={<InvoiceBill />}
                  ></Route>

                  <Route
                    path={RoutePath.CustomerInvoice}
                    element={<CustomerInvoice />}
                  ></Route>

                  <Route
                    path={RoutePath.LedgerViewDetail}
                    element={<LedgerViewDetail />}
                  ></Route>

                  <Route
                    path={RoutePath.TrackerMap}
                    element={<TrackerMap />}
                  ></Route>
                  <Route path={RoutePath.Mapper} element={<Mapper />}></Route>
                  <Route path="/tracker" element={<Tracker />}></Route>
                </Routes>
              </div>
            </div>
          </Router>
        </>
      ) : (
        <Router>
          <Routes>
            <Route path="/" element={<LoginPage />}></Route>
          </Routes>
        </Router>
      )}
    </>
  );
}
