import { useSelector } from "react-redux";
import PieCharts from "../charts/PieChart";
import Icon_Order from "../img/Icon_Order.svg";
import Delivered from "../img/Delivered.svg";
import Order from "../img/Order.svg";
import Group from "../img/Group.svg";
import TotalRevenueChart from "../charts/TotalRevenueChart";
import OrderChart from "../charts/OrderChart";
import CustomerMapchart from "../charts/CustomerMapChart";
import AvailablityCards from "../component/AvailablityCards";
import { Statusbar } from "../component/StatusBar";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { RoutePath } from "../router/RoutePath";
import PageName from "../component/PageName";

const Dashboard = () => {
  const [orderCount, setOrderCount] = useState();
  const [CustomerCount, setCustomerCount] = useState();
  const { bookingDeatils, customerDeatils } = useSelector((state) => state.user);
 
  // console.log("vehicleDeatils", vehicleDeatils);
  useEffect(() => {
    if (bookingDeatils !== null && bookingDeatils.data) {
      setOrderCount(bookingDeatils.count);
    }
  }, [bookingDeatils]);
//--------------------------------------customer Details ------------------------------------------------//
  useEffect(() => {
    if (customerDeatils !== null && customerDeatils.data) {
      setCustomerCount(customerDeatils.count);
    }
  }, [customerDeatils]);
  return (
    <div>
      <div className="text-left text-xl text-mainTxtColor font-semibold px-4 pt-4">
      Dashboard
    </div>
      {/* <PageName pageName="Dashboard" /> */}
      <div className="grid sm:grid-cols-2 lg:grid-cols-4 justify-items-stretch gap-6 p-4">
      <Link to={RoutePath.BookingRequest}>
        <Statusbar
          src={Icon_Order}
          count={orderCount || 0}
          title="Booking Requests"
          rating="4% (30 days)"
        />
        </Link>
        <Link to={RoutePath.ActiveDelivery}>
        <Statusbar
          src={Delivered}
          count={orderCount || 0}
          title="Active Deliveries"
          rating="4% (30 days)"
        />
        </Link>
        <Link to={RoutePath.TotalCanceled}>
        <Statusbar
          src={Order}
          count={orderCount || 0}
          title="Total Cancelled "
          rating="25% (30 days)"
        />
        </Link>
        <Link to={RoutePath.TotalRevenue}>
        <Statusbar
          src={Group}
          count={`Rs.${orderCount || 0}`}
          title="Total Revenue"
          rating="25% (30 days)"
        />
        </Link>
      </div>
      <AvailablityCards />
      <div className="lg:grid grid-cols-6 gap-6 sm:py-4 lg:mx-4">
        <div className="col-span-3 p-5 bg-white rounded-primeryBRedius shadow-lg">
          <PieCharts orderCount={orderCount} CustomerCount={CustomerCount} />
        </div>
        <div className="col-span-3 p-5 bg-white rounded-primeryBRedius shadow-lg">
          <OrderChart orderCount={orderCount} />
        </div>
        <div className="col-span-4 p-5 bg-white rounded-primeryBRedius shadow-lg">
          <TotalRevenueChart />
        </div>
        <div className="col-span-2 p-5 bg-white rounded-primeryBRedius shadow-lg">
          <CustomerMapchart />
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
