import React from "react";
import { RoutePath } from "../../../router/RoutePath";
import { AddFilterButton, TitleFilter } from "../../../component/TitileFilter";
import VisibilityIcon from "@mui/icons-material/Visibility";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { useState, useEffect } from "react";
import { Statusbar } from "../../../component/StatusBar";
import customer from "../../../img/customer.svg";
import totaldriver from "../../../img/totaldriver.svg";
import { DynamicTable } from "../../../tables/DynamicTable";
import { getAxiosWithToken } from "../../../axios/AxiosObj";
import {
  alertResponseMsgError,
  alertResponseMsgSuccess,
} from "../../../function/utilies";
import { Link } from "react-router-dom";
import PageName from "../../../component/PageName";

const Staff = () => {
  const tableHead = [
    "S. No",
    "Staff ID",
    "Name",
    "Address",
    "Contact",
    "Action",
  ];

  const [dataLoading, setDataLoading] = useState(false);
  const [customerCount, setCustomerCount] = useState(0);
  const [allCustomerRaw, setAllCustomerRaw] = useState([]);

  const [allCustomer, setAllCustomer] = useState([]);

  const userManageData = async () => {
    // setDataLoading(true)
    const response = await getAxiosWithToken({
      method: "get",
      url: "customer/all",
    });
    if (response && response.data && response.data.data) {
      alertResponseMsgSuccess(response.data.msg);
      setAllCustomerRaw(response.data.data);
      setCustomerCount(response.data.count || 0);
    } else {
      alertResponseMsgError(response.data.msg);
    }

    // console.log(response);
  };

  // useEffect(() => {
  //   userManageData();
  // }, [dataLoading]);

  useEffect(() => {
    const modifiedData = allCustomerRaw.map((customer) => {
      return {
        item01: customer.username,
        item02: customer.firstName,
        item03: customer.middleName
          ? `${customer.firstName} ${customer.middleName} ${customer.lastName}`
          : `${customer.firstName} ${customer.lastName}`,
        item04: customer.mobile,
        img01: <VisibilityIcon />,
        img02: <EditIcon />,
        img03: <DeleteIcon />,
        unqiueKey: customer.username,
      };
    });
    setAllCustomer(modifiedData);
  }, [allCustomerRaw]);

  return (
    <div>
      <div>
        <PageName pageName="Manage Staff" href={RoutePath.AddStaff} />
        <div className="grid sm:grid-cols-3  justify-items-stretch gap-6 p-4 cursor-pointer">
          <div>
            <Link to={RoutePath.Staff}>
              <Statusbar
                isActive={true}
                src={customer}
                count={customerCount || 0}
                title="Total Staff"
                rating="4% (30 days)"
              />
            </Link>
          </div>
          <div>
            <Link to={RoutePath.Admin}>
              <Statusbar
                src={totaldriver}
                count={customerCount || 0}
                title="Admin"
                rating="4% (30 days)"
              />
            </Link>
          </div>
          <div>
            <Link to={RoutePath.SuperAdmin}>
              <Statusbar
                src={customer}
                count={customerCount || 0}
                title="Super Admin"
                rating="4% (30 days)"
              />
            </Link>
          </div>
        </div>
        <div>
          {/* <div className="flex justify-center gap-2 sm:justify-end items-center px-4">
            <AddFilterButton href={RoutePath.AddStaff} />
            <TitleFilter />
          </div> */}
          <DynamicTable
            tableHeadRow={tableHead}
            data={allCustomer}
            DeleteUrl={"customer/delete"}
            eyeButton={RoutePath.EditStaffDetailLink}
            editButton={RoutePath.EditStaffDetailLink}
            isDataLoading={dataLoading}
          />
        </div>
      </div>
    </div>
  );
};

export default Staff;
