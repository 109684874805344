import * as React from 'react';
import { PieChart } from '@mui/x-charts/PieChart';
import { useDrawingArea } from '@mui/x-charts/hooks';
import { styled } from '@mui/material/styles';

const size = {
  width: 200,
  height: 100,
};

const StyledText = styled('text')(({ theme }) => ({
  fill: theme.palette.text.primary,
  textAnchor: 'middle',
  dominantBaseline: 'central',
  fontSize: 20,
}));

function PieCenterLabel({ children }) {
  const { width, height, left, top } = useDrawingArea();
  return (
    <StyledText x={left + width / 2} y={top + height / 2}>
      {children}
    </StyledText>
  );
}

export default function PieCharts({orderCount, CustomerCount}) {
  const bookinPer = orderCount*100 /100;
  const totalBookings = [
    { value: orderCount, color: 'blue' },
    { value: 50 },
  ];

  const customerGrowth = [
    { value: CustomerCount, color: 'green' },
    { value: 70 },
  ];

  const totalRevenue = [
    { value: 100, color: 'red' },
    { value: 100 },
  ];

  return (
    <div>
        <div className='flex flex-wrap justify-around '>
        <h1>Pie Chart</h1>
        <div className="col-span-2"></div>
        <div className='col-span-1 flex justify-center gap-2'>
        <input type="checkbox" className="default:ring-2 ..." />
        <label>Chart</label>
        </div>
        <div className='col-span-2 flex justify-center gap-2'>
        <input type="checkbox" className="default:ring-2 ..." />
        <label>Show Value</label>
        </div>
        </div>
    <div className='grid sm:grid-cols-3 place-items-start bg-white mt-4 ml-20 sm:ml-10 '>
        <div className='col-span-1 text-blue-500 text-left'>
      <PieChart series={[{ data: totalBookings, innerRadius: 25 }]} {...size}>
        <PieCenterLabel>50%</PieCenterLabel>
      </PieChart>
      <h1 className='py-2 px-4'>Total Bookings</h1>
      </div>
      <div className='text-blue-500 text-left'>
      <PieChart series={[{ data: customerGrowth, innerRadius: 25 }]}  {...size}>
        <PieCenterLabel> {bookinPer} <p>%</p></PieCenterLabel>
      </PieChart>
      <h1 className='py-2 px-4'>Customer Growth</h1>
      </div>
      <div className='text-blue-500 text-left'>
      <PieChart series={[{ data: totalRevenue, innerRadius: 25 }]} {...size}>
        <PieCenterLabel>70%</PieCenterLabel>
      </PieChart>
      <h1 className='p-2'>Total Revenue</h1>
      </div>
    </div>
    </div>
  );
}
