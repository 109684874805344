import * as React from "react";
import { BarChart } from "@mui/x-charts/BarChart";

const pData = [20, 40, 60, 80, 40, 620, 40];

const xLabels = [
  "Sunday",
  "Monday",
  "Thuesday",
  "Wednesday",
  "thursday",
  "Friday",
  "Saturday",
];

export default function CustomerMapchart() {
  return (
    <div className="text-left">
      <div className="flex justify-between">
        <h1>Chart Order</h1>
        <div>
          <select id="countries"
            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
          >
            <option >Weekly</option>
            <option value="US">option States</option>
            <option value="CA">option</option>
            <option value="FR">option</option>
            <option value="DE">option</option>
          </select>
        </div>
      </div>
      <BarChart
        //   width={500}
        height={300}
        series={[
          {
            data: pData,
          },
        ]}
        xAxis={[
          {
            data: xLabels,
            scaleType: "band",
          },
        ]}
        yAxis={[{ max: 100 }]}
      />
    </div>
  );
}
