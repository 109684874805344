import React, { useEffect, useState } from "react";
import { DynamicTable } from "../../tables/DynamicTable";
import { PrimeButton, PrintButton } from "../../component/PrimeButton";
import PageName from "../../component/PageName";
import { Link } from "react-router-dom";
import { Statusbar } from "../../component/StatusBar";
import { RoutePath } from "../../router/RoutePath";
import Icon_Order from "../../img/Icon_Order.svg";
import Order from "../../img/Order.svg";

const Report = () => {
  const customerHead = [
    "S.No.",
    "Order ID",
    "Date",
    "Amount",
    "CGST%",
    "SGST%",
    "IGST%",
    "Total",
  ];
  const customerData = [
    {
      item01: "#70178KT",
      item02: "23/08/2023",
      item03: "0.000000",
      item04: "0.0000 ",
      item05: "0.00000",
      item06: "0.00000",
      item07: "0.00000",
    },
    {
      item01: "#70178KT",
      item02: "23/08/2023",
      item03: "0.000000",
      item04: "0.0000 ",
      item05: "0.00000",
      item06: "0.00000",
      item07: "0.00000",
    },
    {
      item01: "#70178KT",
      item02: "23/08/2023",
      item03: "0.000000",
      item04: "0.0000 ",
      item05: "0.00000",
      item06: "0.00000",
      item07: "0.00000",
    },
    {
      item01: "#70178KT",
      item02: "23/08/2023",
      item03: "0.000000",
      item04: "0.0000 ",
      item05: "0.00000",
      item06: "0.00000",
      item07: "0.00000",
    },
    {
      item01: "#70178KT",
      item02: "23/08/2023",
      item03: "0.000000",
      item04: "0.0000 ",
      item05: "0.00000",
      item06: "0.00000",
      item07: "0.00000",
    },
    {
      item01: "#70178KT",
      item02: "23/08/2023",
      item03: "0.000000",
      item04: "0.0000 ",
      item05: "0.00000",
      item06: "0.00000",
      item07: "0.00000",
    },
  ];

  const [activeTableHead, setActiveTableHead] = useState([]);
  const [activeTableData, setActiveTableData] = useState([]);

  useEffect(() => {
    setActiveTableHead(customerHead);
    setActiveTableData(customerData);
  }, []);

  return (
    <div>
      <div className="flex justify-center items-end gap-4 pt-4 pr-4">
        <PageName pageName="Manage Vehicle" />
        <PrimeButton btnName="Export" />
        <PrintButton id="contentToPrint" />
      </div>

      <div className="grid sm:grid-cols-2  justify-items-stretch gap-6 p-4 cursor-pointer">
        <div>
          <Link to={RoutePath.Vehicle}>
            <Statusbar
              count={0}
              isActive={true}
              src={Icon_Order}
              title="Booking Report"
              rating="4% (30 days)"
            />
          </Link>
        </div>
        <div>
          <Link to={RoutePath.TotalVehicle}>
            <Statusbar
              src={Order}
              count="862"
              title="Final Report"
              rating="25% (30 days)"
            />
          </Link>
        </div>
      </div>
      <div className="bg-backgroundColor rounded-xl  ">
        <div id="contentToPrint">
          <DynamicTable
            tableHeadRow={activeTableHead}
            data={activeTableData}
            eyeButton="/ledgerviewdetail"
          />
          <form className="mx-4 p-3 bg-white items-center">
            <div className=" grid  sm:grid-flow-col gap-3 items-center pb-2">
              <div className="lg:flex gap-6 items-center  ">
                <label className="w-36 text-sm font-medium text-gray-900">
                  Total GST
                </label>
                <input
                  type="text"
                  value={"0.000"}
                  className=" w-full rounded-inputBRedius bg-inputBgColor p-1"
                  placeholder=""
                  disabled
                />
              </div>
              <div className="lg:flex gap-6 items-center  ">
                <label className="w-52 text-sm font-medium text-gray-900">
                  Total Amount
                </label>
                <input
                  type="text"
                  value={"0.000"}
                  className=" w-full rounded-inputBRedius bg-inputBgColor p-1"
                  placeholder=""
                  disabled
                />
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Report;
