import React from "react";
import { RoutePath } from "../../router/RoutePath";
import { AddFilterButton, TitleFilter } from "../../component/TitileFilter";
import Icon_Order from "../../img/Icon_Order.svg";
import VisibilityIcon from "@mui/icons-material/Visibility";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import UploadFileOutlinedIcon from "@mui/icons-material/UploadFileOutlined";
import { useState, useEffect } from "react";
import { Statusbar } from "../../component/StatusBar";
import { DynamicTable } from "../../tables/DynamicTable";
import PageName from "../../component/PageName";
import { getAxiosWithToken } from "../../axios/AxiosObj";
import { useSelector } from "react-redux";

const ManageExpense = () => {
  const { deleteDeatils } = useSelector((state) => state.user);

  const [allExpensesData, setAllExpensesdata] = useState([]);
  const [allExpensesCount, setAllExpensesCount] = useState();
  const [dataLoading, setDataLoading] = useState(false);
  const [expensesTableData, setExpensesTableData] = useState([]);

  const ManageExpenseHead = [
    "S.No.",
    "Invoice",
    "Date",
    "Name",
    "Taxable Amount",
    "Total",
    "Receiving",
    "Action",
  ];

  const expensesData = async () => {
    const response = await getAxiosWithToken({
      method: "get",
      url: "expense/all",
    });
    if (response && response.data && response.data.data) {
      setAllExpensesdata(response.data.data);
      setAllExpensesCount(response.data.count || 0);
    }
  };
  useEffect(() => {
    expensesData();
  }, [dataLoading,deleteDeatils]);
  useEffect(() => {
    if (allExpensesData.length <= 0) {
      setDataLoading(true);
    } else {
      setDataLoading(false);
    }
  }, [allExpensesData]);

  useEffect(() => {
    const modifiedData = allExpensesData.map((expense) => {
      const allDate = new Date(expense.date); // Convert to Date object if necessary
      const year = allDate.getFullYear();
      const month = (allDate.getMonth() + 1).toString().padStart(2, "0"); // Adding 1 to month because it's zero-based
      const day = allDate.getDate().toString().padStart(2, "0");

      const formattedDate = `${day}/${month}/${year}`;
      return {
        item01:  expense.invoiceNo,
        item02: formattedDate,
        item03: expense.title,
        item04: expense.taxAmount,
        item05: expense.totalAmount,
        imgBtn: <UploadFileOutlinedIcon />,
        img01: <VisibilityIcon />,
        img02: <EditIcon />,
        img03: <DeleteIcon />,
        unqiueKey: expense.expenseId,
        uploadFile: expense.uploadFile,
      };
    });
    setExpensesTableData(modifiedData);
  }, [allExpensesData]);

  return (
    <div>
      <div>
        <PageName pageName="Manage Expenses" href={RoutePath.ExpensesForm} />
        <div className="p-4">
          <Statusbar
            isActive={true}
            src={Icon_Order}
            count={allExpensesCount || 0}
            title="Manage Expenses"
            rating="4% (30 days)"
          />
        </div>
        <div>
          <DynamicTable
            tableHeadRow={ManageExpenseHead}
            data={expensesTableData}
            DeleteUrl={"expense/delete"}
            eyeButton={RoutePath.EditExpensesDetailLink}
            editButton={RoutePath.EditExpensesDetailLink}
            invoceRecipt={RoutePath.InvoiceReciptLink}
            isDataLoading={dataLoading}
          />
        </div>
      </div>
    </div>
  );
};

export default ManageExpense;
