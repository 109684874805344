import "./App.css";
import AppRoute from "./router/appRoute";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { getAxiosWithToken } from "./axios/AxiosObj";
import secureLocalStorage from "react-secure-storage";
import { useEffect, useRef, useState } from "react";
import {
  logoutUser,
  setUserdetails,
  setStationDetails,
  setBookingDeatils,
  setVehicleDeatils,
  setDriverDeatils,
  setCustomerDeatils,
} from "./store/features/userSlice";
import { useDispatch, useSelector } from "react-redux";

function App() {
  const {
    isLogged,
    userDetails,
    bookingDeatils,
    stationDetails,
    vehicleDeatils,
    driverDeatils,
    customerDeatils,
    deleteDeatils,
  } = useSelector((state) => state.user);

  const dispatch = useDispatch();
  const timer = useRef();

  const [flag, setFlag] = useState(false);
  const upDateToken = async () => {
    const response = await getAxiosWithToken({
      method: "get",
      url: "token/update",
      // url: "user/all",
    });
    setFlag(true);
    if (response.data.success) {
      secureLocalStorage.setItem("access", response.data.token);
    } else {
      dispatch(logoutUser());
      window.location.reload();
    }
    console.log(response);
    console.log(isLogged);
  };
  useEffect(() => {
    if (isLogged) {
      // Set up a single interval for both update and check
      timer.current = setInterval(async () => {
        if (!flag) {
          await upDateToken();
        }
        setFlag(false);
      }, 600000); // 10 min in milliseconds (6 * 1000)

      timer.current = setInterval(() => {
        checkLoign();
      }, 5000); // 5 sec in milliseconds (5 * 1000)
    }

    // Clear the interval when the component unmounts or when isLoggedIn becomes false
    return () => {
      clearInterval(timer.current);
    };
  }, [isLogged, flag]);
  const checkLoign = async () => {
    if (!secureLocalStorage.getItem("access")) {
      dispatch(logoutUser());
      window.location.reload();
    }
  };

  //------------------------------------login user Details----------------------------//
  const userFetchDeatils = async () => {
    try {
      const response = await getAxiosWithToken({
        method: "get",
        url: "profile/details",
      });
      if (response.data.success) {
        dispatch(setUserdetails(response.data));
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (
      isLogged &&
      userDetails == null &&
      !secureLocalStorage.getItem("userDetails")
    ) {
      userFetchDeatils();
    }
  }, [isLogged]);
  //------------------------------------All station Details----------------------------//
  const stationFetchDeatils = async () => {
    try {
      const response = await getAxiosWithToken({
        method: "get",
        url: "station/all",
      });
      if (response.data.success) {
        dispatch(setStationDetails(response.data));
      }
    } catch (error) {
      console.error(error);
    }
  };
  useEffect(() => {
    if (
      isLogged &&
      stationDetails == null &&
      !secureLocalStorage.getItem("stationDetails")
    ) {
      stationFetchDeatils();
    }
  }, [isLogged, deleteDeatils]);

  //------------------------------------All Booking Details----------------------------//
  const bookingFetchDeatils = async () => {
    try {
      const response = await getAxiosWithToken({
        method: "get",
        url: "booking/all",
      });
      if (response.data.success) {
        dispatch(setBookingDeatils(response.data));
      }
    } catch (error) {
      console.error(error);
    }
  };
  useEffect(() => {
    if (
      isLogged &&
      bookingDeatils == null &&
      !secureLocalStorage.getItem("bookingDeatils")
    ) {
      bookingFetchDeatils();
    }
  }, [isLogged, deleteDeatils]);

  //------------------------------------All vehicle Details----------------------------//
  const vehicleFetchDeatils = async () => {
    try {
      const response = await getAxiosWithToken({
        method: "get",
        url: "vehicle/all",
      });
      if (response.data.success) {
        dispatch(setVehicleDeatils(response.data));
      }
    } catch (error) {
      console.error(error);
    }
  };
  useEffect(() => {
    if (
      isLogged &&
      vehicleDeatils == null &&
      !secureLocalStorage.getItem("vehicleDeatils")
    ) {
      vehicleFetchDeatils();
    }
  }, [isLogged]);
  //------------------------------------All Driver Details----------------------------//
  const driverFetchDeatils = async () => {
    try {
      const response = await getAxiosWithToken({
        method: "get",
        url: "driver/all",
      });
      if (response.data.success) {
        dispatch(setDriverDeatils(response.data));
      }
    } catch (error) {
      console.error(error);
    }
  };
  useEffect(() => {
    const fetchData = async () => {
      if (
        isLogged &&
        driverDeatils === null &&
        !secureLocalStorage.getItem("driverDeatils")
      ) {
        await driverFetchDeatils();
      }
    };

    fetchData();
  }, [isLogged, driverDeatils, dispatch]);

  //------------------------------------All Customer Details----------------------------//
  const customerFetchDeatils = async () => {
    try {
      const response = await getAxiosWithToken({
        method: "get",
        url: "customer/all",
      });
      if (response.data.success) {
        dispatch(setCustomerDeatils(response.data));
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      if (
        isLogged &&
        customerDeatils === null &&
        !secureLocalStorage.getItem("customerDeatils")
      ) {
        await customerFetchDeatils();
      }
    };

    fetchData();
  }, [isLogged, customerDeatils, deleteDeatils]);

  return (
    <div className="App bg-backgroundColor min-h-screen scroll-smooth ">
      <ToastContainer
        style={{ fontSize: "14px", zIndex: "10000" }}
        position="top-right"
        autoClose={2500}
        hideProgressBar={false}
        newestOnTop={true}
        limit={5}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="dark"
      />
      <AppRoute />
    </div>
  );
}

export default App;
