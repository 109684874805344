import React from "react";
import { RoutePath } from "../../../router/RoutePath";
// import { AddFilterButton, TitleFilter } from "../../../component/TitileFilter";
import VisibilityIcon from "@mui/icons-material/Visibility";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { useState, useEffect } from "react";
import { Statusbar } from "../../../component/StatusBar";
import customer from "../../../img/customer.svg";
import totaldriver from "../../../img/totaldriver.svg";
import { DynamicTable } from "../../../tables/DynamicTable";
import { getAxiosWithToken } from "../../../axios/AxiosObj";
import { Link } from "react-router-dom";
import PageName from "../../../component/PageName";

const SuperAdmin = () => {
  const tableHead = [
    "S. No",
    "Super Admin ID",
    "Name",
    "Contact",
    "Action",
  ];

  const [dataLoading, setDataLoading] = useState(false);
  const [superAdminCount, setSuperAdminCount] = useState(0);
  const [allAdminRaw, setAllAdminRaw] = useState([]);

  const [allAdmin, setAllAdmin] = useState([]);

  const userManageData = async () => {
    // setDataLoading(true)
    const response = await getAxiosWithToken({
      method: "get",
      url: "admin/all",
    });
    if (response && response.data && response.data.data) {
      setAllAdminRaw(response.data.data);
      setSuperAdminCount(response.data.count || 0)
    }
    // console.log(response);
  };

  useEffect(() => {
    userManageData();
  }, [dataLoading]);

  useEffect(() => {
    const modifiedData = allAdminRaw.map((admin) => {
      return {
        item01: admin.username,
        item02: admin.firstName? `${admin.firstName} ${admin.middleName} ${admin.lastName}`
        : `${admin.firstName} ${admin.lastName}`,
        item03: admin.mobile,
        img01: <VisibilityIcon />,
        img02: <EditIcon />,
        img03: <DeleteIcon/>,
        unqiueKey: admin.username,
      };
    });
    setAllAdmin(modifiedData);
  }, [allAdminRaw]);

  return (
    <div>
      <div>
      <PageName pageName="Manage Super Admin" href={RoutePath.AddSuperAdmin} />
        <div className="grid sm:grid-cols-3  justify-items-stretch gap-6 p-4 cursor-pointer">
        <div>
        <Link to={RoutePath.Staff} >
          <Statusbar
            src={customer}
            count={"Super" || 0 }
            title="Total Staff"
            rating="4% (30 days)"
          />
          </Link>
        </div>
        <div >
        <Link to={RoutePath.Admin} >
            <Statusbar
              src={totaldriver}
              ccount={"Super" || 0 }
              title="Admin"
              rating="4% (30 days)"
            />
            </Link>
          </div>
          <div >
          <Link to={RoutePath.SuperAdmin} >
            <Statusbar
             isActive={true}
              src={customer}
              count={superAdminCount || 0 }
              title="Super Admin"
              rating="4% (30 days)"
            />
            </Link>
          </div>
          </div>
        <div>
          {/* <div className="flex justify-center gap-2 sm:justify-end items-center px-4">
            <AddFilterButton href={RoutePath.AddSuperAdmin} />
            <TitleFilter />
          </div> */}
          <DynamicTable
            tableHeadRow={tableHead}
            data={allAdmin}
            DeleteUrl={"admin/delete"}
            eyeButton={RoutePath.EditSuperAdminDetailLink}
            editButton={RoutePath.EditSuperAdminDetailLink}
          />
        </div>
      </div>
    </div>
  );
};

export default SuperAdmin;
